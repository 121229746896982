<template>
  <v-menu offset-y :close-on-content-click="false" v-model="open">
    <template v-slot:activator="{ on, attrs }">
      <v-chip v-bind="attrs" v-on="on" small color="light" outlined :class="'px-0'+(typeof logClass==='undefined' ? '' : ' '+logClass)">
        <v-icon small class="ml-2">mdi-history</v-icon>
        <v-icon small class="mr-2">mdi-chevron-down</v-icon>
      </v-chip>
    </template>
    <v-sheet max-width="400" max-height="406">
      <v-sheet v-if="state.isLoading" class="my-4 text-center" width="200">
        <v-progress-circular color="primary" indeterminate size="24" width="3" class="mx-1"/>
      </v-sheet>
      <div v-if="state.isSuccess">
        <v-sheet v-if="logs.length===0" width="200">
          <alert>Не найдено</alert>
        </v-sheet>
        <v-expansion-panels accordion :multiple="false">
          <v-expansion-panel v-for="(log,key) in logs" :key="key">
            <v-expansion-panel-header class="px-4 py-2">
              <div class="mr-2">
                <v-row no-gutters class="subtitle-2 full-width">
                  <v-col class="mr-2">{{log.createdAgo}}</v-col>
                  <v-col cols="auto" class="font-weight-regular caption text-right">{{log.created}}</v-col>
                </v-row>
                <div v-if="log.user==null" class="caption mr-2">Анон.</div>
                <div v-else class="caption mr-2">
                  {{log.user.lastName}}
                  {{log.user.firstName}}
                  {{log.user.secondName}}
                  ({{log.user.type ==='admin' ? 'Администратор' : log.user.type==='spec' ? 'Специалист' : 'Клиент'}})
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="ml-n2">
              <div class="caption d-inline-block" v-html="log.text
            .replaceAll(/(?:\r\n|\r|\n)/g, '<br>')
            .replaceAll('<name>', '<div class=\'subtitle-2 font-weight-regular\'>').replaceAll('</name>', ':</div>')
            .replaceAll('<value>', '<div class=\'caption\'>').replaceAll('</value>', '</div>')
          "/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-sheet>
  </v-menu>
</template>

<script>
import State from "@/plugins/state";
import moment from "moment";
import {Entity_Log, Entity_User} from "../../../EntityStoreCacheService";
import Alert from "@/componentsV2/base/Alert.vue";

export default {
  components: {Alert},
  props: [
    'entityName',
    'entityId',
    'parentEntityName',
    'parentEntityId',
    'small',
    'logClass',
  ],
  data: () => ({
    state: new State(),
    logs: [],
    open: false,
  }),
  watch: {
    open() {

      if (this.open){
        let rb = new Entity_Log()
            .selects(Entity_Log.id)
            .selects(Entity_Log.created)
            .selects(Entity_Log.text)
            .selects([Entity_Log.user, Entity_User.firstName].join('.'))
            .selects([Entity_Log.user, Entity_User.lastName].join('.'))
            .selects([Entity_Log.user, Entity_User.secondName].join('.'))
            .selects([Entity_Log.user, Entity_User.type].join('.'))
            .page(-1)
            .onPage(30)
            .order(Entity_Log.id, false)
            .filterAnd([Entity_Log.entity, Entity_Log.entityName].join('.')+' =?', this.entityName)
            .filterAnd([Entity_Log.entity, Entity_Log.entityId].join('.')+'=?', this.entityId)

            if (typeof this.parentEntityName !=='undefined') {
              rb.filterOr([Entity_Log.entity, Entity_Log.entityName].join('.')+' =?', this.parentEntityName)
              .filterAnd([Entity_Log.entity, Entity_Log.entityId].join('.')+'=?', this.parentEntityId)
            }

            rb.addStateCallback((v)=> this.state.setRequestBuilderState(v))
            .addStateCallback((v)=>{
              if(v.error)
                this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку');
              if(v.success) {
                this.logs = v.items;
                this.logs.forEach(v=>{
                  v.createdAgo = moment(v.created, 'YYYY-MM-DD HH:mm:ss').fromNow();
                })
              }
            })
            .request()
      }
    }
  }
}
</script>

<style scoped>
.v-expansion-panel-header{
  min-height: initial;
}
</style>
