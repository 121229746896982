<template>
  <layout-view navTitle="Фильтры">
    <template v-slot:nav>
      <div class="px-4 mt-1">
        <date-interval name="Интервал" :valueFrom.sync="dateFrom" :valueTo.sync="dateTo" class="mb-2" />
        <v-expansion-panels accordion v-model="panelOpens" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-0 py-3 caption" style="min-height: initial">
              <operation-panel-header name="Тип платежа" :panel-open="panelOpens.includes(0)" :select.sync="payTypesSelect"/>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <operation-pay-type-multiple class="mx-n8" :select.sync="payTypesSelect" @update:select="tableLoad()"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-0 py-3 caption" style="min-height: initial">
              <operation-panel-header name="Способ расчета" :panel-open="panelOpens.includes(1)" :select.sync="sourceTypesSelect"/>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <operation-source-type-multiple class="mx-n8" :select.sync="sourceTypesSelect" @update:select="tableLoad()"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-0 py-3 caption" style="min-height: initial">
              <operation-panel-header name="Категории" :panel-open="panelOpens.includes(2)" :select.sync="operationGroupTypesSelects"/>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <operation-group-cat-multiple class="mx-n8" :select.sync="operationGroupTypesSelects" @update:select="tableLoad()" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <checkboxes name="" :items="[{id:'fixedShow',name:'Показать сторнированные'}]" :selects.sync="filterAdds"/>

        <com-branch-autocomplete-multiple :select.sync="comBranchs" global-state/>
        <user-autocomplete-multiple name="Клиент"/>
        <user-autocomplete-multiple name="Оператор" _type="admin"/>

        <finance-cash-box-autocomplete-single/>
        <finance-organization-autocomplete-single/>

        <v-row class="rowSmall mb-2">
          <v-col>
            <text-field class="mb-0" name="Сумма" :value.sync="amount" after-icon="mdi-currency-rub" format="^[0-9]+\.?[0-9]?[0-9]?$" formatExample="1000 или 1000.10"/>
          </v-col>
          <v-col>
            <text-field class="mb-0" name="ID операции" :value.sync="operationGroupId" format="^[0-9]+$" formatExample="1000"/>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:content="{height}">

      <finance-operations-table-admin
          v-if="viewType===0"
          ref="tableAdmin"
          :height="height"
          :genRequestBuilderParams="rbAdmin"
      >
        <template v-slot:barActions>
          <v-sheet color="grey lighten-4" rounded>
            <div class="d-inline-block body-2 mx-2 font-weight-medium">Вид:</div>
            <v-btn-toggle dense v-model="viewType">
              <v-btn small>Платежи</v-btn>
              <v-btn small>Фин.операции</v-btn>
              <v-btn small>Хоз.операции</v-btn>
            </v-btn-toggle>
          </v-sheet>
        </template>
      </finance-operations-table-admin>

      <finance-operations-table-operation
          v-if="viewType===1"
          ref="tableOperation"
          :height="height"
          :genRequestBuilderParams="rbOperation"
      >
        <template v-slot:barActions>
          <v-sheet color="grey lighten-4" rounded>
            <div class="d-inline-block body-2 mx-2 font-weight-medium">Вид:</div>
            <v-btn-toggle dense v-model="viewType">
              <v-btn small>Платежи</v-btn>
              <v-btn small>Фин.операции</v-btn>
              <v-btn small>Хоз.операции</v-btn>
            </v-btn-toggle>
          </v-sheet>
        </template>
      </finance-operations-table-operation>

      <finance-operations-table-operation-group
          v-if="viewType===2"
          ref="tableOperationGroup"
          :height="height"
          :genRequestBuilderParams="rbOperationGroup"
      >
        <template v-slot:barActions>
          <v-sheet color="grey lighten-4" rounded>
            <div class="d-inline-block body-2 mx-2 font-weight-medium">Вид:</div>
            <v-btn-toggle dense v-model="viewType">
              <v-btn small>Платежи</v-btn>
              <v-btn small>Фин.операции</v-btn>
              <v-btn small>Хоз.операции</v-btn>
            </v-btn-toggle>
          </v-sheet>
        </template>
      </finance-operations-table-operation-group>
      <finance-operation-group :operation-group.sync="operationGroup"/>
    </template>
  </layout-view>
</template>

<script>
import LayoutView from "@/components/LayoutView";
import DateInterval from "@/componentsV2/base/DateInterval.vue";
import TextField from "@/componentsV2/base/TextField.vue";
import UserAutocompleteMultiple from "@/componentsV2/custom/UserAutocompleteMultiple.vue";
import ComBranchAutocompleteMultiple from "@/componentsV2/custom/ComBranchAutocompleteMultiple.vue";
import FinanceCashBoxAutocompleteSingle from "@/componentsV2/custom/FinanceCashBoxAutocompleteSingle.vue";
import FinanceOrganizationAutocompleteSingle from "@/componentsV2/custom/FinanceOrganizationAutocompleteSingle.vue";
import moment from "moment/moment";
import {
  Entity_Operation,
  Entity_OperationGroup, Entity_OperationGroupType, Entity_OperationType,
} from "../../../EntityStoreCacheService";
import OperationGroupCatMultiple from "@/componentsV2/custom/OperationGroupCatMultiple.vue";
import Checkboxes from "@/componentsV2/base/Checkboxes.vue";
import OperationSourceTypeMultiple from "@/componentsV2/custom/OperationSourceTypeMultiple.vue";
import OperationPayTypeMultiple from "@/componentsV2/custom/OperationPayTypeMultiple.vue";
import FinanceOperationsTableOperationGroup from "@/views/Finance/FinanceOperationsTableOperationGroup.vue";
import OperationPanelHeader from "@/componentsV2/custom/OperationPanelHeader.vue";
import FinanceOperationGroup from "@/views/Finance/FinanceOperationGroup.vue";
import FinanceOperationsTableAdmin from "@/views/Finance/FinanceOperationsTableAdmin.vue";
import FinanceOperationsTableOperation from "@/views/Finance/FinanceOperationsTableOperation.vue";

export default {
  components: {
    FinanceOperationsTableOperation,
    FinanceOperationsTableAdmin,
    FinanceOperationGroup,
    OperationPanelHeader,
    FinanceOperationsTableOperationGroup,
    OperationPayTypeMultiple,
    OperationSourceTypeMultiple,
    Checkboxes,
    OperationGroupCatMultiple,
    FinanceOrganizationAutocompleteSingle,
    FinanceCashBoxAutocompleteSingle,
    ComBranchAutocompleteMultiple,
    UserAutocompleteMultiple,
    TextField,
    DateInterval,
    LayoutView
  },
  data: () => ({
    dateFrom:moment().subtract('6', 'month').format('DD.MM.YYYY'),
    dateTo:moment().format('DD.MM.YYYY'),
    panelOpens:[0,1],
    payTypesSelect:[],
    sourceTypesSelect:[],
    operationGroupTypesSelects:[],
    comBranchs:[],
    amount:null,
    filterAdds:[],
    operationGroupId:null,
    operationGroup:null,
    viewType:0,
  }),
  watch:{
    dateFrom(){
      this.tableLoad();
    },
    dateTo(){
      this.tableLoad();
    },
    operationGroup(){
      this.tableLoad();
    },
    filterAdds(){
      this.tableLoad();
    }
  },
  methods:{
    tableLoad(){
      if(typeof this.$refs.tableAdmin!=='undefined')
        this.$refs.tableAdmin.load()
      if(typeof this.$refs.tableOperation!=='undefined')
        this.$refs.tableOperation.load()
      if(typeof this.$refs.tableOperationGroup!=='undefined')
        this.$refs.tableOperationGroup.load()
    },

    rbAdmin(rb){
      if(!this.$tools.isEmpty(this.dateFrom))
        rb.filterAnd([Entity_OperationGroup.entity, Entity_OperationGroup.created].join('.')+'>=?',moment(this.dateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD 00:00:00'));
      if(!this.$tools.isEmpty(this.dateTo))
        rb.filterAnd([Entity_OperationGroup.entity, Entity_OperationGroup.created].join('.')+'<=?',moment(this.dateTo, 'DD.MM.YYYY').format('YYYY-MM-DD 23:59:59'));

      if(this.payTypesSelect.length>0){
        let q = '';
        this.payTypesSelect.forEach(v=>{
          q += [Entity_OperationGroup.entity, Entity_OperationGroup.operations, Entity_Operation.operationType, Entity_OperationType.payType].join('.')+'=? OR ';
          rb._filterParams.push(v.id)
        });
        rb.filterAnd(q.substring(0, q.length-4));
      }

      if(this.sourceTypesSelect.length>0){
        let q = '';
        this.sourceTypesSelect.forEach(v=>{
          q += [Entity_OperationGroup.entity, Entity_OperationGroup.operations, Entity_Operation.operationType, Entity_OperationType.sourceType].join('.')+'=? OR ';
          rb._filterParams.push(v.id)
        });
        rb.filterAnd(q.substring(0, q.length-4));
      }

      if(this.filterAdds.filter(v=>v.id==='fixedShow').length===0){
        rb.filterAnd(
            [Entity_OperationGroup.entity, Entity_OperationGroup.fixed, Entity_OperationGroup.id].join('.')+' is null AND '+
            [Entity_OperationGroup.entity, Entity_OperationGroup.fixedBy, Entity_OperationGroup.id].join('.')+' is null'
        );
      }

      if(this.operationGroupTypesSelects.length>0){
        let q = '';
        this.operationGroupTypesSelects.forEach(v=>{
          q += [Entity_OperationGroup.entity, Entity_OperationGroup.operationGroupType, Entity_OperationGroupType.id].join('.')+'=? OR ';
          rb._filterParams.push(v.id)
        });
        rb.filterAnd(q.substring(0, q.length-4));
      }

      return rb
    },

    rbOperation(rb){
      if(!this.$tools.isEmpty(this.dateFrom))
        rb.filterAnd([Entity_Operation.entity, Entity_Operation.operationGroup, Entity_OperationGroup.created].join('.')+'>=?',moment(this.dateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD 00:00:00'));
      if(!this.$tools.isEmpty(this.dateTo))
        rb.filterAnd([Entity_Operation.entity, Entity_Operation.operationGroup, Entity_OperationGroup.created].join('.')+'<=?',moment(this.dateTo, 'DD.MM.YYYY').format('YYYY-MM-DD 23:59:59'));

      if(this.payTypesSelect.length>0){
        let q = '';
        this.payTypesSelect.forEach(v=>{
          q += [Entity_Operation.entity, Entity_Operation.operationType, Entity_OperationType.payType].join('.')+'=? OR ';
          rb._filterParams.push(v.id)
        });
        rb.filterAnd(q.substring(0, q.length-4));
      }

      if(this.sourceTypesSelect.length>0){
        let q = '';
        this.sourceTypesSelect.forEach(v=>{
          q += [Entity_Operation.entity, Entity_Operation.operationType, Entity_OperationType.sourceType].join('.')+'=? OR ';
          rb._filterParams.push(v.id)
        });
        rb.filterAnd(q.substring(0, q.length-4));
      }

      if(this.filterAdds.filter(v=>v.id==='fixedShow').length===0){
        rb.filterAnd(
            [Entity_Operation.entity, Entity_Operation.operationGroup, Entity_OperationGroup.fixed, Entity_OperationGroup.id].join('.')+' is null AND '+
            [Entity_Operation.entity, Entity_Operation.operationGroup, Entity_OperationGroup.fixedBy, Entity_OperationGroup.id].join('.')+' is null'
        );
      }

      if(this.operationGroupTypesSelects.length>0){
        let q = '';
        this.operationGroupTypesSelects.forEach(v=>{
          q += [Entity_Operation.entity, Entity_Operation.operationGroup, Entity_OperationGroup.operationGroupType, Entity_OperationGroupType.id].join('.')+'=? OR ';
          rb._filterParams.push(v.id)
        });
        rb.filterAnd(q.substring(0, q.length-4));
      }

      return rb
    },

    rbOperationGroup(rb){
      if(!this.$tools.isEmpty(this.dateFrom))
        rb.filterAnd([Entity_OperationGroup.entity, Entity_OperationGroup.created].join('.')+'>=?',moment(this.dateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD 00:00:00'));
      if(!this.$tools.isEmpty(this.dateTo))
        rb.filterAnd([Entity_OperationGroup.entity, Entity_OperationGroup.created].join('.')+'<=?',moment(this.dateTo, 'DD.MM.YYYY').format('YYYY-MM-DD 23:59:59'));


      if(this.payTypesSelect.length>0){
        let q = '';
        this.payTypesSelect.forEach(v=>{
          q += [Entity_OperationGroup.entity, Entity_OperationGroup.operations, Entity_Operation.operationType, Entity_OperationType.payType].join('.')+'=? OR ';
          rb._filterParams.push(v.id)
        });
        rb.filterAnd(q.substring(0, q.length-4));
      }
      if(this.sourceTypesSelect.length>0){
        let q = '';
        this.sourceTypesSelect.forEach(v=>{
          q += [Entity_OperationGroup.entity, Entity_OperationGroup.operations, Entity_Operation.operationType, Entity_OperationType.sourceType].join('.')+'=? OR ';
          rb._filterParams.push(v.id)
        });
        rb.filterAnd(q.substring(0, q.length-4));
      }

      if(this.filterAdds.filter(v=>v.id==='fixedShow').length===0){
        rb.filterAnd(
            [Entity_OperationGroup.entity, Entity_OperationGroup.fixed, Entity_OperationGroup.id].join('.')+' is null AND '+
            [Entity_OperationGroup.entity, Entity_OperationGroup.fixedBy, Entity_OperationGroup.id].join('.')+' is null'
        );
      }

      if(this.operationGroupTypesSelects.length>0){
        let q = '';
        this.operationGroupTypesSelects.forEach(v=>{
          q += [Entity_OperationGroup.entity, Entity_OperationGroup.operationGroupType, Entity_OperationGroupType.id].join('.')+'=? OR ';
          rb._filterParams.push(v.id)
        });
        rb.filterAnd(q.substring(0, q.length-4));
      }

      return rb;
    },
  }
}
</script>