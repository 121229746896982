<template>
  <div>
    <dialog-view
        :open="open"
        title="Оплата"
        @cancel="complete()"
        :cancel-not-close="true"
        content-full-size
        width="540"
    >
      <template v-slot:content>
        <div v-if="payState.isInit">
          <div class="mx-4 my-2">
            <v-row>
              <v-col>
                <div class="mb-1 body-2">Требуется</div>
                <div class="text-h5">{{$tools.price(amount)}}</div>
              </v-col>
              <v-col>
                <div class="mb-1 body-2">{{amountPayed===amount ? 'Получено всего' : amountPayed>amount ? 'Сдача наличными' : 'Недостаточно'}}</div>
                <v-row no-gutters class="align-center">
                  <v-col cols="auto">
                    <v-icon class="text-h5 mr-1" :color="amountPayed===amount ? 'success' : amountPayed>amount ? 'warning' : 'error'">
                      {{amountPayed===amount ? 'mdi-check-circle' : amountPayed>amount ? 'mdi-information' : 'mdi-alert'}}
                    </v-icon>
                  </v-col>
                  <v-col>
                    <div class="text-h5">{{$tools.price(Math.abs(amountPayed===amount ? amountPayed : amount-amountPayed))}}</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <v-divider/>
          <div class="mx-4 mt-2 mb-4">
            <div class="mb-3 body-2">Получено</div>
            <v-form ref="form">
              <alert-view v-if="!$tools.isEmpty(devicesError)" :text="devicesError+'\n Расчет наличными и банковской картой недоступны'" icon="mdi-information-outline"/>
              <alert-view v-else-if="!$tools.isEmpty(accountBalanceError)" :text="accountBalanceError+'\n Расчет через личный счет недоступен'" icon="mdi-information-outline"/>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="amountCash"
                    :disabled="register===null || !$tools.isEmpty(amountInsur)"
                    :error-messages="isChangeBlock ? ['Сдача доступна только при оплате наличными'] : null"
                    outlined dense hide-details="auto" class="big" clearable
                  >
                    <template v-slot:label>
                      <v-icon small class="mr-2">mdi-cash</v-icon>Наличными
                    </template>
                    <template v-slot:append>
                      <v-btn
                          @click="amountCash=$tools.parseNumber(amount-sourceItemsAmount('oms')-sourceItemsAmount('dms')-sourceItemsAmount('contract'))" icon style="margin-top:-6px;margin-right:-10px"
                          :disabled="register===null || !$tools.isEmpty(amountInsur)"
                      ><v-icon size="20">mdi-content-paste</v-icon></v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="amountCard"
                    :disabled="terminal===null"
                    outlined dense hide-details="auto" class="big" clearable
                  >
                    <template v-slot:label>
                      <v-icon small class="mr-2">mdi-credit-card-outline</v-icon>Банковской картой
                    </template>
                    <template v-slot:append>
                      <v-btn
                          @click="amountCard=$tools.parseNumber(amount-sourceItemsAmount('oms')-sourceItemsAmount('dms')-sourceItemsAmount('contract'))"
                          :disabled="terminal===null || !$tools.isEmpty(amountInsur)"
                          icon style="margin-top:-6px;margin-right:-10px"
                      ><v-icon size="20">mdi-content-paste</v-icon></v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="amountBankAccount"
                    :rules="[v => $tools.parseNumber(v)>sourceItemsAmount('bankAccount') ? 'Максимум: '+$tools.price(sourceItemsAmount('bankAccount')) : true]"
                    :disabled="!$tools.isEmpty(amountInsur)"
                    outlined dense hide-details="auto" class="big" clearable
                  >
                    <template v-slot:label>
                      <v-icon small class="mr-2">mdi-bank</v-icon>Расчетный счет
                    </template>
                    <template v-slot:append>
                      <v-btn
                          @click="amountBankAccount=$tools.parseNumber(amount-sourceItemsAmount('oms')-sourceItemsAmount('dms')-sourceItemsAmount('contract'))"
                          :disabled="!$tools.isEmpty(amountInsur)"
                          icon style="margin-top:-6px;margin-right:-10px"
                      ><v-icon size="20">mdi-content-paste</v-icon></v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="amountAccount"
                    :rules="[v =>
                      $tools.isEmpty(v) ? true :
                      $tools.parseNumber(v)>sourceItemsAmount('account') ? 'Максимум: '+$tools.price(sourceItemsAmount('account')) :
                      $tools.parseNumber(v)>accountBalance ? 'Максимум: '+$tools.price(accountBalance) : true
                    ]"
                    :disabled="!$tools.isEmpty(accountBalanceError) || !$tools.isEmpty(amountInsur)"
                    outlined dense hide-details="auto" class="big" clearable
                  >
                    <template v-slot:label>
                      <v-icon small class="mr-2">mdi-account-cash-outline</v-icon>Личный счет
                    </template>
                    <template v-slot:append>
                      <v-btn
                        @click="amountAccount=$tools.parseNumber(amount-sourceItemsAmount('oms')-sourceItemsAmount('dms')-sourceItemsAmount('contract'))"
                        :disabled="!$tools.isEmpty(accountBalanceError) || !$tools.isEmpty(amountInsur)"
                        icon style="margin-top:-6px;margin-right:-10px"
                      ><v-icon size="20">mdi-content-paste</v-icon></v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="sourceItemsAmount('oms')>0">
                  <v-text-field
                      v-model="amountOms"
                      :rules="[v => $tools.isEmpty(v) || $tools.parseNumber(v)===0 ? 'Поле обязательно' : $tools.parseNumber(v)>sourceItemsAmount('oms') ? 'Максимум: '+$tools.price(sourceItemsAmount('oms')) : true]"
                      outlined dense hide-details="auto" class="big" clearable
                  >
                    <template v-slot:label>
                      <v-icon small class="mr-2">mdi-plus-box</v-icon>ОМС
                    </template>
                    <template v-slot:append>
                      <v-btn @click="amountOms=sourceItemsAmount('oms')" icon style="margin-top:-6px;margin-right:-10px"><v-icon size="20">mdi-content-paste</v-icon></v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col v-if="sourceItemsAmount('dms')>0">
                  <v-text-field
                      v-if="sourceItemsAmount('dms')>0"
                      v-model="amountDms"
                      :rules="[v => $tools.isEmpty(v) || $tools.parseNumber(v)===0 ? 'Поле обязательно' : $tools.parseNumber(v)>sourceItemsAmount('dms') ? 'Максимум: '+$tools.price(sourceItemsAmount('dms')) : true]"
                      outlined dense hide-details="auto" class="big" clearable
                  >
                    <template v-slot:label>
                      <v-icon small class="mr-2">mdi-plus-box-multiple</v-icon>ДМС
                    </template>
                    <template v-slot:append>
                      <v-btn @click="amountDms=sourceItemsAmount('dms')" icon style="margin-top:-6px;margin-right:-10px"><v-icon size="20">mdi-content-paste</v-icon></v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col v-if="sourceItemsAmount('contract')>0">
                  <v-text-field
                      v-if="sourceItemsAmount('contract')>0"
                      v-model="amountContract"
                      :rules="[v => $tools.isEmpty(v) || $tools.parseNumber(v)===0 ? 'Поле обязательно' : $tools.parseNumber(v)>sourceItemsAmount('contract') ? 'Максимум: '+$tools.price(sourceItemsAmount('contract')) : true]"
                      outlined dense hide-details="auto" class="big" clearable
                  >
                    <template v-slot:label>
                      <v-icon small class="mr-2">mdi-domain</v-icon>Контракт
                    </template>
                    <template v-slot:append>
                      <v-btn @click="amountContract=sourceItemsAmount('contract')" icon style="margin-top:-6px;margin-right:-10px"><v-icon size="20">mdi-content-paste</v-icon></v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12 mx-n4">
                  <v-divider style="width:calc(100% + 32px);max-width:calc(100% + 32px)"/>
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="mb-2 body-2">Получено через организацию</div>
                </v-col>
                <v-col cols="4" class="pt-0">
                  <v-text-field
                      v-model="amountInsur"
                      :disabled="!$tools.isEmpty(amountCash) || !$tools.isEmpty(amountCard) || !$tools.isEmpty(amountBankAccount) || !$tools.isEmpty(amountAccount)"
                      outlined dense hide-details="auto" class="big" clearable
                  >
                    <template v-slot:label>
                      Сумма
                    </template>
                    <template v-slot:append>
                      <v-btn
                          @click="amountInsur=$tools.parseNumber(amount-sourceItemsAmount('oms')-sourceItemsAmount('dms')-sourceItemsAmount('contract'))"
                          :disabled="!$tools.isEmpty(amountCash) || !$tools.isEmpty(amountCard) || !$tools.isEmpty(amountBankAccount) || !$tools.isEmpty(amountAccount)"
                          icon style="margin-top:-6px;margin-right:-10px"><v-icon size="20">mdi-content-paste</v-icon></v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="8" class="pt-0">
                  <insur-select single @selected="(v)=>insurId=v"/>
                </v-col>
              </v-row>
            </v-form>
          </div>
          <v-divider/>
          <div class="mx-4 my-4">
            <v-textarea v-model="comment" label="Комментарий к платежу" outlined dense hide-details auto-grow rows="2" clearable/>
          </div>
          <v-divider/>
          <div class="overflow-hidden">
            <div class="mx-n2">
              <v-expansion-panels accordion>
                <v-expansion-panel>
                  <v-expansion-panel-header>Кассовое оборудование</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="pt-2" no-gutters>
                      <v-col class="pr-2">
                        <v-select
                            label="Фискальный регистратор"
                            v-model="register"
                            :items="registers"
                            outlined dense hide-details item-text="name" item-value="id">
                          <template v-slot:selection="{item}">
                            <div class="my-2">
                              <div class="body-2 font-weight-medium">{{item.name}}</div>
                            </div>
                          </template>
                          <template v-slot:item="{item}">
                            <div class="my-2">
                              <div class="body-2 font-weight-medium">{{item.name}}</div>
                            </div>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col class="pl-2">
                        <v-select
                            label="Эквайринговый терминал"
                            v-model="terminal"
                            :items="terminals"
                            outlined dense hide-details item-text="name" item-value="id">
                          <template v-slot:selection="{item}">
                            <div class="my-2">
                              <div class="body-2 font-weight-medium">{{item.name}}</div>
                            </div>
                          </template>
                          <template v-slot:item="{item}">
                            <div class="my-2">
                              <div class="body-2 font-weight-medium">{{item.name}}</div>
                            </div>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
          <v-divider/>
          <div class="mx-4 my-4">
            <v-btn @click="pay()" block depressed color="primary" :disabled="amountPayed<amount">Оплатить</v-btn>
          </div>
        </div>
        <div v-if="payState.isLoading">
          <div v-if="payState.step===0" class="pb-4">
            <progress-page icon="mdi-account"/>
            <div class="text-center mt-n4">Сбор информации о кассире</div>
          </div>
          <div v-if="payState.step===1" class="pb-4">
            <progress-page icon="mdi-credit-card-outline"/>
            <div class="text-center mt-n4">Производиться оплата банковской картой<br/>через "{{terminal.name}}"</div>
          </div>
          <div v-if="payState.step===2" class="pb-4">
            <progress-page icon="mdi-cash-register"/>
            <div class="text-center mt-n4">Производиться регистрация платежа<br/>через "{{register.name}}"</div>
          </div>
          <div v-if="payState.step===3" class="pb-4">
            <progress-page icon="mdi-content-save"/>
            <div class="text-center mt-n4">Сохранение платежа</div>
          </div>
        </div>
        <div v-if="payState.isSuccess" class="pb-4">
          <v-sheet class="position-relative d-flex align-center ma-auto" :width="96" :height="96" color="transparent">
            <v-icon class="position-absolute d-block mx-auto" style="margin-left:calc(50% - 27px)!important" color="success" size="54">mdi-check-circle</v-icon>
          </v-sheet>
          <div class="text-center mt-n4">Платеж успешно выполнен</div>
          <div class="mx-4 mt-4">
            <v-btn @click="complete()" block depressed color="primary">OK</v-btn>
          </div>
        </div>
        <div v-if="payState.isError">
          <div class="pb-4">
            <progress-page v-if="payState.step===0" icon="mdi-account" :loading="false"/>
            <progress-page v-if="payState.step===1" icon="mdi-credit-card-outline" :loading="false"/>
            <progress-page v-if="payState.step===2" icon="mdi-cash-register" :loading="false"/>
            <progress-page v-if="payState.step===3" icon="mdi-content-save" :loading="false"/>
            <div class="text-center mt-n4 px-4">{{payState.errorMessage}}</div>
            <div class="text-center mt-2">
              <v-btn v-if="payState.step===0" @click="userLoad(userLoad_args[0])" small depressed><v-icon class="mr-2" dense>mdi-refresh</v-icon>Повторить</v-btn>
              <v-btn v-if="payState.step===1" @click="payCard(payCard_args[0],payCard_args[1],payCard_args[2])" small depressed><v-icon class="mr-2" dense>mdi-refresh</v-icon>Повторить</v-btn>
              <v-btn v-if="payState.step===2" @click="payRegister(payRegister_args[0],payRegister_args[1],payRegister_args[2])" small depressed><v-icon class="mr-2" dense>mdi-refresh</v-icon>Повторить</v-btn>
              <v-btn v-if="payState.step===3" @click="payMisCreate(payMisCreate_args[0],payMisCreate_args[1],payMisCreate_args[2],payMisCreate_args[3])" small depressed><v-icon class="mr-2" dense>mdi-refresh</v-icon>Повторить</v-btn>
            </div>
          </div>
        </div>
      </template>
    </dialog-view>
  </div>

</template>

<script>
import DialogView from "@/components/DialogView";
import Kkm from "@/Kkm";
import State from "@/plugins/state";
import ProgressPage from "@/components/ProgressPage.vue";
import axios from "axios";
import Api from "@/Api";
import AlertView from "@/components/AlertView.vue";
import InsurSelect from "@/views/Insur/components/InsurSelect.vue";
export default {
  components: {InsurSelect, AlertView, ProgressPage, DialogView},
  props:['open', 'userId', 'visitServices'],
  data: () => ({
    accountBalance:null,
    accountBalanceError:null,

    amountCash:null,
    amountCard:null,
    amountBankAccount:null,
    amountAccount:null,
    amountOms:null,
    amountDms:null,
    amountContract:null,
    amountInsur:null,
    insurId:null,
    comment:null,

    kkm: new Kkm(),
    devicesError:null,
    registers:[],
    register:null,
    terminals:[],
    terminal:null,

    payState: new State(),

    userLoad_args: null,
    payCard_args: null,
    payRegister_args: null,
    payMisCreate_args:null,
  }),
  watch:{
    open(){
      if(this.open) {
        this.accountBalanceError = null;
        axios.get(Api.host+'/pay/accountBalance/'+this.userId+'/')
            .then((res)=> {
              this.accountBalance = res.data.amount;
            }).catch(()=>this.accountBalanceError = "Не удалось получить баланс личного счета")

        this.amountCash = null;
        this.amountCard = null;
        this.amountBankAccount = null;
        this.amountAccount = null;
        this.amountOms = this.sourceItemsAmount('oms');
        this.amountDms = this.sourceItemsAmount('dms');
        this.amountContract = this.sourceItemsAmount('contract');
        this.amountInsur = null;
        this.comment = null;

        this.devicesError = null;
        this.registers = [];
        this.register = null;
        this.terminals = [];
        this.terminal = null;
        this.kkm.devices((res)=>{
          this.registers = res.filter(v=>v.typeName==='Фискальный регистратор');
          this.register = this.registers[0];

          this.terminals = res.filter(v=>v.typeName==='Эквайринговый терминал');
          this.terminal = this.terminals[0];

          this.registers.forEach(v1=>{
            this.kkm.deviceStatusSessionError(v1, (res)=>{
              if(res!==null)
                this.devicesError = res+' "'+v1.name+'"';
            }, e=>{
              this.devicesError = e+' "'+v1.name+'"';
            });
          });
        }, e=>{
          this.devicesError = e;
        });

        this.payState.stateInit();
      }
    },
    'amountCash'(str) {
      if (str)
      this.amountCash = this.$tools.priceFormatter(str);
    },
    'amountCard'(str) {
      if (str)
      this.amountCard = this.$tools.priceFormatter(str);
    },
    'amountBankAccount'(str) {
      if (str)
      this.amountBankAccount = this.$tools.priceFormatter(str);
    },
    'amountInsur'(str) {
      if (str)
      this.amountInsur = this.$tools.priceFormatter(str);
    },
  },
  computed:{
    amount(){
      let amount = 0;
      this.visitServices.forEach(v => {
        amount += v.priceTotal;
      })
      return this.$tools.parseNumber(amount);
    },
    amountPayed(){
      let v = 0;
      v += this.$tools.parseNumber(this.amountCash);
      v += this.$tools.parseNumber(this.amountCard);
      v += this.$tools.parseNumber(this.amountBankAccount);
      v += this.$tools.parseNumber(this.amountAccount);
      v += this.$tools.parseNumber(this.amountOms);
      v += this.$tools.parseNumber(this.amountDms);
      v += this.$tools.parseNumber(this.amountContract);
      v += this.insurId==null ? 0 : this.$tools.parseNumber(this.amountInsur);
      return v;
    },
    isChangeBlock(){
      let amountCash = this.$tools.parseNumber(this.amountCash);
      return this.$tools.parseNumber(this.amountPayed-this.amount)>0 && this.$tools.parseNumber(this.amountPayed-amountCash)>0;
    }
  },
  methods: {
    pay(){
      if (this.$refs.form.validate() && !this.isChangeBlock) {

        let items = [];
        this.visitServices.forEach(v => {
          items.push({
            name: v.name + (this.$tools.isEmpty(v.code) ? '' : ' (' + v.code + ')'),
            count: v.count,
            price: v.price,
            priceTotal: v.priceTotal,
            tax: -1,
            type: 'service'
          });
        });

        let amountCashCard = this.$tools.parseNumber(this.amountCash)+this.$tools.parseNumber(this.amountCard);
        if(amountCashCard>0){
          let m = amountCashCard/this.amount;
          if(m>0 && m<1)
            items.forEach(v => {
              v.priceTotal = this.$tools.parseNumber(v.priceTotal*m, 0);
            });
        }

        this.payState.stateLoading();

        this.userLoad((userRes) => {
          if(this.$tools.parseNumber(this.amountCard)>0) {
            this.payCard(this.terminal, this.$tools.parseNumber(this.amountCard), (payCardRes)=>{
              this.payRegister(userRes, items, ()=>{
                this.payMisCreate(this.register, this.terminal, payCardRes.Slip, ()=>{
                  this.payState.stateSuccess();
                });
              });
            });
          } else if(this.$tools.parseNumber(this.amountCash)>0){
            this.payRegister(userRes, items, ()=> {
              this.payMisCreate(this.register, this.terminal, null, () => {
                this.payState.stateSuccess();
              });
            });
          } else {
            this.payMisCreate(null, null, null, () => {
              this.payState.stateSuccess();
            });
          }
        });

        /*
        this.payState.stateLoading(() => {
          this.userLoad((res) => {
            if(this.$tools.parseNumber(this.amountCard)>0){
              this.payState.step = 1;
              this.kkm.payCard(this.terminal, this.amountCard, (res1) => {
                this.payState.step = 2;
                this.kkm.payRegister(
                    this.register,
                    res.data.lastName + ' ' + res.data.firstName,
                    res.data.userProfile.inn,
                    this.$tools.parseNumber(this.amountCash),
                    this.$tools.parseNumber(this.amountCard),
                    arr,
                    () => {
                      this.payCreate(this.register, this.terminal, res1.Slip);
                    }, e => {
                      this.payState.stateInit();
                      this.$store.commit('alertError', 'Фискальный регистратор: ' + e);
                    });
              }, e => {
                this.payState.stateInit();
                this.$store.commit('alertError', 'Эквайринговый терминал: ' + e);
              });
            } else if(this.$tools.parseNumber(this.amountCash)>0){
              this.payState.step = 2;
              this.kkm.payRegister(
                  this.register,
                  res.data.lastName + ' ' + res.data.firstName,
                  res.data.userProfile.inn,
                  this.$tools.parseNumber(this.amountCash),
                  0,
                  arr,
                  () => {
                    this.payCreate(this.register)
                  }, e => {
                    this.payState.stateInit();
                    this.$store.commit('alertError', 'Фискальный регистратор: ' + e);
                  });
            } else {
              this.payCreate();
            }
          });
        });

         */
      }
    },

    userLoad(success){
      this.userLoad_args = [success];
      this.payState.stateLoading();
      this.payState.step = 0;

      axios.get(Api.host+'/user/status/')
          .then((res)=> {
            axios.get(Api.host + '/user/' + res.data.id + '/')
                .then((res) => {
                  success(res);
                })
                .catch(() =>{
                  let v = 'Сбор информации о кассире: Не удалось получить информацию';
                  this.payState.stateError(v);
                  this.$store.commit('alertError', v);
                });
          })
          .catch(() =>{
            let v = 'Сбор информации о кассире: Не удалось получить информацию';
            this.payState.stateError(v);
            this.$store.commit('alertError', v);
          });
    },

    payCard(terminal, amountCard, success){
      this.payCard_args = [terminal, amountCard, success];
      this.payState.stateLoading();
      this.payState.step = 1;

      this.kkm.payCard(terminal, amountCard, (payCardRes) => {
        success(payCardRes);
      }, e => {
        let v = 'Эквайринговый терминал: '+e;
        this.payState.stateError(v);
        this.$store.commit('alertError', v);
      });
    },

    payRegister(userRes, items, success){
      this.payRegister_args = [userRes, items, success];
      this.payState.stateLoading();
      this.payState.step = 2;

      this.kkm.payRegister(
          this.register,
          userRes.data.lastName + ' ' + userRes.data.firstName,
          userRes.data.userProfile.inn,
          this.$tools.parseNumber(this.amountCash),
          this.$tools.parseNumber(this.amountCard),
          items,
          () => {
            success();
          }, e => {
            let v = 'Фискальный регистратор: '+e;
            this.payState.stateError(v);
            this.$store.commit('alertError', v);
          });
    },

    payMisCreate(register=null, terminal=null, slipCard=null, success){
      this.payMisCreate_args = [register, terminal, slipCard, success];
      this.payState.stateLoading();
      this.payState.step = 3;

      let visitServiceIds = [];
      this.visitServices.forEach(v=>{
        visitServiceIds.push(v.id);
      });

      let data = {
        type:'pay',
        userId:this.userId,
        visitServicesIds:visitServiceIds,
        comment:this.comment,
      };
      if(this.$tools.parseNumber(this.amountCash)>0) data.amountCash = this.$tools.parseNumber(this.amountCash)-(this.amountPayed-this.amount);
      if(this.$tools.parseNumber(this.amountCard)>0) data.amountCard = this.$tools.parseNumber(this.amountCard);
      if(this.$tools.parseNumber(this.amountBankAccount)>0) data.amountBankAccount = this.$tools.parseNumber(this.amountBankAccount);
      if(this.$tools.parseNumber(this.amountAccount)>0) data.amountAccount = this.$tools.parseNumber(this.amountAccount);
      if(this.$tools.parseNumber(this.amountOms)>0) data.amountOms = this.$tools.parseNumber(this.amountOms);
      if(this.$tools.parseNumber(this.amountDms)>0) data.amountDms = this.$tools.parseNumber(this.amountDms);
      if(this.$tools.parseNumber(this.amountContract)>0) data.amountContract = this.$tools.parseNumber(this.amountContract);
      if(this.$tools.parseNumber(this.amountInsur)>0 && this.insurId!=null){
        data.amountInsur = this.$tools.parseNumber(this.amountInsur);
        data.insurId = this.insurId;
      }

      if(register!==null)
        data.kktName=register.name;
      if(terminal!==null)
        data.terminalName=terminal.name;
      if(slipCard!==null)
        data.slipCard=slipCard;

      axios.post(Api.host+'/pay/pay/', data)
          .then(()=> {
            success();
          }).catch((e)=>{
            let v = 'Сохранение операции в МИС: '+(this.$tools.isEmpty(e.response.data.message) ? 'Не удалось подключиться' : e.response.data.message);
            this.payState.stateError(v);
            this.$store.commit('alertError', v);
          });
    },

    sourceItems(v){
      if(v==='cashCard')
        return this.visitServices.filter(v1=>v1.insur===null)
      if(v==='bankAccount')
        return this.visitServices.filter(v1=>v1.insur===null);
      if(v==='account')
        return this.visitServices.filter(v1=>v1.insur===null);
      if(v==='oms')
        return this.visitServices.filter(v1=>v1.insur!==null && v1.mode==='oms')
      if(v==='dms')
        return this.visitServices.filter(v1=>v1.insur!==null && v1.mode==='dms')
      if(v==='contract')
        return this.visitServices.filter(v1=>v1.insur!==null && v1.mode==='contract');
      return [];
    },
    sourceItemsAmount(v){
      let amount = 0;
      this.sourceItems(v).forEach(v => {
        amount += v.priceTotal;
      })
      return this.$tools.parseNumber(amount);
    },
    complete(){
      if(!this.payState.isLoading)
        this.$emit('complete');
    }
  }
}
</script>
