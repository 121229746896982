<template>
  <layout-view navTitle="Фильтры">
    <template v-slot:nav>
      <div class="px-4">
        <date-interval name="Дата создания" :valueFrom.sync="dateFrom" :valueTo.sync="dateTo" valueFromMinus="month:1"/>
        
        <checkbox-field class="mb-4" name="Только анализы" :value.sync="onlyTests"/>
       
        <user-autocomplete-multiple name="Клиенты" :select.sync="usersSelected" selectAllEnabled/>
        
        <v-row class="align-center mb-4 pt-1">
          <v-col>
            <direction-autocomplete-multiple class="ml-1 mb-0" :select.sync="directions"/>
          </v-col>
          <v-col cols="auto">
            <tooltip right>
              <v-icon small>mdi-help-circle-outline</v-icon>           
              <template v-slot:content>
                Фильтрует клиентов по наличию медплана с соответствущим направлением.
              </template>
            </tooltip> 
          </v-col>
        </v-row>
        
  
        <user-autocomplete-multiple name="Специалисты" _type="spec" :select.sync="usersSpecSelected" selectAllEnabled/>
        
        <text-field name="Название" :value.sync="medRecordName"/>
      </div>
    </template>
    <template v-slot:content="{height}">
      <v-data-table
          :headers="headers"
          :items="medRecords"         
          :loading="loadState.isLoading"
          :itemsPerPage="25"
          dense
          fixed-header
          :height="height-60"
          class="pt-3"
          :footer-props="{'items-per-page-options': [25, 50, 100]}"
 
      >
        <template v-slot:item="{ item }">
          
          <tr @click="typeof item.medRecordTemplate !== 'undefined' ? medRecordId=item.id : mediaId=item.id; user = item.user;">
            <td class="text-no-wrap">{{item.id}}</td>
            <td class="text-no-wrap">{{item.name!=null&&item.name!='' ? item.name : typeof item.medRecordTemplate !== 'undefined' ? item.medRecordTemplate.name : '-'}} <v-icon v-if="(typeof item.medRecordTemplate === 'undefined')" small class="ml-1" title="Документ">mdi-file</v-icon> <v-icon v-if="!item.isShowInService" class="ml-2" size="14" color="primary" title="Скрыто в личном кабинете">mdi-eye-off-outline</v-icon></td>
            <td>{{item.comment}}</td>
            <td class="text-no-wrap">
              <div v-if="misDesktopOnline&&typeof item.medRecordTemplate === 'undefined'">
                <v-btn small depressed @click.stop="misDesktopOpenFile(item)"><v-icon class="mr-2" small>mdi mdi-open-in-new</v-icon>Открыть локально</v-btn>
              </div>
            </td>
            <td>
              <v-tooltip v-if="item.status==='unactive'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small class="mr-2">mdi-file-alert</v-icon>
                </template>
                <span>Черновик</span>
              </v-tooltip>
            </td>
            <td>
              <div class="d-flex align-center text-no-wrap">
                <span class="mr-1">{{$tools.userFullName(item.user,true)}}</span>
                <copy-button :data="$tools.userFullName(item.user)"/>
                <v-btn 
                    @click.stop
                    x-small
                    icon depressed :href="'/panel/user/client/'+item.user.id+'/'" target="_blank">
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-btn>
              </div>
            </td>
            <td class="text-no-wrap">
              <v-avatar size="32" class="mr-2" v-if="typeof item.userAuthor.imageUrl !== 'undefined'&&item.userAuthor.imageUrl!==null">
                <img :src="item.userAuthor.imageUrl"/>
              </v-avatar>
              {{$tools.userFullName(item.userAuthor)}}              
            </td>
            <td class="text-no-wrap">{{$tools.dateTime(item.created)}}</td>
            <td class="text-no-wrap">
              <v-chip
                v-if="item.isSent"
                class="font-weight-medium"
                color="green"
                text-color="white"
                small
              >
                Отправлено
              </v-chip>
            </td>
            <td>
              <div class="action-btns">
                <v-btn icon x-small @click.stop="downloadAction(item)" :loading="loadState.isLoading">
                  <v-icon small title="Скачать">mdi-download</v-icon>
                </v-btn>
                <v-btn icon x-small @click.stop="typeof item.medRecordTemplate !== 'undefined' ? preview(item) : mediaId=item.id" :loading="previewState.isLoading">
                  <v-icon small title="Предпросмотр">mdi-eye</v-icon>
                </v-btn>
                <v-btn :class="[item.isPrinted ? 'btnSuccessIco btnSuccessIco_dark btnSuccessIco_small' : '']" icon x-small @click.stop="print(item)" :loading="printState.isLoading">
                  <v-icon small title="Печать">mdi-printer</v-icon>
                </v-btn>
                <btn-small-accept :btnClass="[item.isSent ? 'btnSuccessIco btnSuccessIco_dark btnSuccessIco_small' : '']" text="Отправить на E-mail?" icon="mdi-email-arrow-right" btnIcon xSmall @accept="sendEmail(item)" :loading="emailState.isLoading"/>
                <btn-small-accept text="Удалить?" icon="mdi-delete" btnIcon xSmall @accept="deleteAction(item)" :loading="loadState.isLoading"/>
                <btn-small-accept v-if="typeof item.medRecordTemplate !== 'undefined'" text="Создать копию?" icon="mdi-content-copy" btnIcon xSmall @accept="copyAction(item)" :loading="loadState.isLoading"/>   
                
                <v-btn v-if="misDesktopOnline&&typeof item.medRecordTemplate === 'undefined'" icon x-small @click.stop="misDesktopOpenFile(item)" :loading="printState.isLoading">
                  <v-icon small title="Открыть локально">mdi-open-in-new</v-icon>
                </v-btn>

              </div>
            </td>
          </tr>
        </template>

        <template v-slot:footer.prepend>
          <tooltip  right>
            <v-chip color="red lighten-5 mx-1">
              <v-icon class="mr-2" color="red" small>mdi-alert</v-icon>
              <div class="caption black--text">список ограничен</div>
            </v-chip>       
            <template v-slot:content>
              Ограничено до 2000 медзаписей. Для получения более точных результатов воспользуйтесь фильтрами.
            </template>
          </tooltip> 
        </template>
      </v-data-table>

      <med-record-update :med-record-id.sync="medRecordId" @medRecordCopied="medRecordCopied" :userId="user?.id"/>
      <media-view :media-id.sync="mediaId" :user-id="user?.id" :userName="user && $tools.userFullName(user)" @updateList="load()"/>

      <v-dialog
          v-model="previewState.isModalOpen"
          fullscreen
          hide-overlay
          scrollable
          transition="dialog-bottom-transition"
      >
        <v-card class="grey lighten-4">
          <v-toolbar dark :color="!$vuetify.theme.dark ? 'rgb(2 146 77)' : 'rgb(40 40 40)'" max-height="64px">
            <v-btn icon dark @click="previewState.modalClose()">
              <v-progress-circular v-if="previewState.isLoading" indeterminate size="20" width="3"/>
              <v-icon v-else>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              <div class="caption">Предосмотр Мед.Запись</div>
              <div class="body-1">{{ previewState.modalData!=null && previewState.modalData.name!='' ? previewState.modalData.name : previewState.modalData?.medRecordTemplate.name }}</div>
            </v-toolbar-title>
          </v-toolbar>
          <div>
            <iframe
                :src="previewState.modalData?.previewUrl"
                frameBorder="0"
                :style="'width:'+previewWidth+'px;height:'+previewHeight+'px'"
            ></iframe>
          </div>
        </v-card>
      </v-dialog>
    </template>
  </layout-view>
</template>

<script>

import Api from "@/Api";
import State from "@/plugins/state";
import moment from "moment";
import LayoutView from "@/components/LayoutView";
import MedRecordUpdate from "@/views/MedRecord/MedRecordUpdate";
import MediaView from "@/views/Media/MediaView";
import axios from "axios";
import printJS from "print-js";
import DateInterval from "@/componentsV2/base/DateInterval.vue";
import UserAutocompleteMultiple from "@/componentsV2/custom/UserAutocompleteMultiple.vue";
import TextField from "@/componentsV2/base/TextField.vue";
import BtnSmallAccept from "@/componentsV2/base/BtnSmallAccept.vue";
import Tooltip from "@/componentsV2/base/Tooltip.vue";
import CopyButton from "@/components/CopyButton.vue";
import DirectionAutocompleteMultiple from "@/componentsV2/custom/DirectionAutocompleteMultiple.vue";
import CheckboxField from "@/componentsV2/base/CheckboxField.vue";

import {
  Entity_MedRecord,
  Entity_MedRecordTemplate,
  Entity_Media,
  Entity_User,
  Entity_UserProfile,
  Entity_MediaDir,
  Entity_MedPlan,
  Entity_Direction,
  Entity_MedRecordTemplateCategory
  // Entity_Visit,
  // Entity_VisitService,
  // Entity_Service
} from "../../../EntityStoreCacheService";

export default {
  components: {
    LayoutView,
    DateInterval,
    UserAutocompleteMultiple,
    MedRecordUpdate,
    MediaView,
    TextField,
    BtnSmallAccept,
    Tooltip,
    CopyButton,
    DirectionAutocompleteMultiple,
    CheckboxField
  },
  props: ['userSpecDesktop'],
  data:()=>({
    headers: [
      { text: 'ID', value: 'id', sortable: true, class:'text-no-wrap', width:'auto'},
      { text: 'Название', value: 'medRecordTemplate.name', sortable: true, class:'text-no-wrap'},
      { text: 'Комментарий', sortable: false},
      { text: '', sortable: false},
      { text: '', sortable: false},
      { text: 'Клиент', value: 'user.lastName', sortable: true, class:'text-no-wrap'},
      { text: 'Автор', value: 'userAuthor.lastName', sortable: true, class:'text-no-wrap'},
      { text: 'Дата создания', value: 'created', sortable: false, class:'text-no-wrap'},
      { text: 'Отправка на Email', sortable: false},
      { text: '', sortable: false},
    ],

    dateFrom: moment(new Date()).format('DD.MM.YYYY'),
    dateTo: moment(new Date()).format('DD.MM.YYYY'),
    loadState: new State(),
    medRecords:[],
    medRecordId:null,
    medias: [],
    mediaId: null,
    usersSelected: [],
    usersSpecSelected:[],
    misDesktopOnline:false,
    user:null,
    medRecordName: null,
    directions: [],
    onlyTests: false,

    previewState: new State(),
    previewWidth:0,
    previewHeight:0,

    printState: new State(),
    emailState: new State(),
  }),
  mounted(){
   
    this.$tools.misDesktopStatus((v)=>{
      this.misDesktopOnline = v;
    });

    if (typeof this.userSpecDesktop !== 'undefined'){
        let MedPlanRequest = new Entity_MedPlan()
        //user
        .selects([Entity_MedPlan.user, Entity_User.id].join('.'))
        .selects([Entity_MedPlan.user, Entity_User.firstName].join('.'))
        .selects([Entity_MedPlan.user, Entity_User.lastName].join('.'))
        .selects([Entity_MedPlan.user, Entity_User.secondName].join('.'))

        .selects([Entity_MedPlan.user, Entity_User.userProfile, Entity_UserProfile.gender].join('.'))
        .selects([Entity_MedPlan.user, Entity_User.userProfile, Entity_UserProfile.birth].join('.'))

        .filterAnd([Entity_MedPlan.entity, Entity_MedPlan.status].join('.')+' not in (?)',['complete', 'done'])
        .filterAnd([Entity_MedPlan.entity, Entity_MedPlan.userSpec, Entity_User.id].join('.')+'=?',this.userSpecDesktop ? this.userSpecDesktop.id : this.$store.state.user.id)


        .page(1).onPage(10000);

        MedPlanRequest.request((data)=>{   
          console.log(data)
          this.usersSelected = data.items.map(i=>i.user);
          this.load();
        }, (e)=>{
          console.error(e.response.data.message);
        })
    } else {
      this.load();
    }
  },
  watch:{
    dateFrom(){
     this.load();
    },
    dateTo(){
      //console.log(this.dateTo)
      //this.dateFrom = moment(this.dateTo,'DD.MM.YYYY').subtract(7, 'days').format('DD.MM.YYYY')
      this.load();
    },
    usersSelected(){  
      this.load();
    },
    usersSpecSelected() {
      this.load();
    },
    medRecordId(){
      if (this.medRecordId===null)
      this.load();
    },
    medRecordName() {
      this.$tools.throttle(()=>{
        this.load();
      }, 700) 
    }, 
    directions(){
      this.load();
    },
    onlyTests(){
      this.load();
    }
  },
  methods:{
    load(){
      this.$tools.throttle(()=>{
        this.loadState.stateLoading();

        const promises = [];
        const medRecords = new Promise((resolve) => {  
          let medRecordRequest = new Entity_MedRecord()
            .selects(Entity_MedRecord.id)
            .selects(Entity_MedRecord.created)
            .selects(Entity_MedRecord.isSent)
            .selects(Entity_MedRecord.isPrinted)
            .selects(Entity_MedRecord.isShowInService)
            .selects(Entity_MedRecord.name)
            .selects(Entity_MedRecord.status)
            .selects(Entity_MedRecord.comment)
        

            //user
            .selects([Entity_MedRecord.user,Entity_User.id].join('.'))
            .selects([Entity_MedRecord.user,Entity_User.firstName].join('.'))
            .selects([Entity_MedRecord.user,Entity_User.lastName].join('.'))
            .selects([Entity_MedRecord.user,Entity_User.secondName].join('.'))

            //user author
            .selects([Entity_MedRecord.userAuthor, Entity_User.id].join('.'))
            .selects([Entity_MedRecord.userAuthor, Entity_User.firstName].join('.'))
            .selects([Entity_MedRecord.userAuthor, Entity_User.lastName].join('.'))
            .selects([Entity_MedRecord.userAuthor, Entity_User.secondName].join('.'))
            .selects([Entity_MedRecord.userAuthor, Entity_User.imageUrl].join('.'))

            //medRecordTemplate
            .selects([Entity_MedRecord.medRecordTemplate, Entity_MedRecordTemplate.id].join('.'))
            .selects([Entity_MedRecord.medRecordTemplate, Entity_MedRecordTemplate.name].join('.'))


            .filterAnd([Entity_MedRecord.entity, Entity_MedRecord.status].join('.')+'!=?','deleted')


            // filter by created
            if(this.dateFrom) {  
              medRecordRequest.filterAnd([Entity_MedRecord.entity, Entity_MedRecord.created].join('.')+'>=?',moment(this.dateFrom, 'DD-MM-YYYY').format('YYYY-MM-DD 00:00:00'));
            } 

            if(this.dateTo) {  
              medRecordRequest.filterAnd([Entity_MedRecord.entity, Entity_MedRecord.created].join('.')+'<=?',moment(this.dateTo, 'DD-MM-YYYY').format('YYYY-MM-DD 23:59:59'));
            }

            // filter by user
            if (this.usersSelected.length>0) {
              medRecordRequest.filterAnd([Entity_MedRecord.entity, Entity_MedRecord.user, Entity_User.id ].join('.')+' in (?)',this.queryArray(this.usersSelected))
            }

            // filter by userSpec
            if (this.usersSpecSelected.length>0) {
              medRecordRequest.filterAnd([Entity_MedRecord.entity, Entity_MedRecord.userAuthor, Entity_User.id ].join('.')+' in (?)',this.queryArray(this.usersSpecSelected))
            }

            //filter by name
            if (this.medRecordName!==null&&this.medRecordName!==''){
              medRecordRequest.filterAnd([Entity_MedRecord.entity, Entity_MedRecord.name].join('.')+' LIKE ? OR '+
              [Entity_MedRecord.entity, Entity_MedRecord.medRecordTemplate, Entity_MedRecordTemplate.name].join('.')+' LIKE ?'
              ,'%'+this.medRecordName+'%','%'+this.medRecordName+'%');
            }

            //directions
            if (this.directions.length>0) {
              medRecordRequest.filterAnd([Entity_MedRecord.entity, Entity_MedRecord.user, Entity_User.medPlans, Entity_MedPlan.directions, Entity_Direction.id].join('.')+' in (?)', this.queryArray(this.directions))
            }

            //анализы
            if (this.onlyTests) {
              medRecordRequest.filterAnd([Entity_MedRecord.entity, Entity_MedRecord.medRecordTemplate, Entity_MedRecordTemplate.medRecordTemplateCategory, Entity_MedRecordTemplateCategory.name].join('.')+' =?','Анализы');
            }

            medRecordRequest.page(1).onPage(1000)

            medRecordRequest.request((data)=>{
                resolve(data.items);
            }, (e)=>{
              console.error(e.response.data.message);
            })
        });

        promises.push(medRecords);

        const media = new Promise((resolve) => {
          let mediaRequest = new Entity_Media()
          .selects(Entity_Media.id)
          .selects(Entity_Media.created)
          .selects(Entity_Media.isSent)
          .selects(Entity_Media.isPrinted)
          .selects(Entity_Media.isShowInService)
          .selects(Entity_Media.name)
          .selects(Entity_Media.status)
          .selects(Entity_Media.fileName)

          //user
          .selects([Entity_Media.user,Entity_User.id].join('.'))
          .selects([Entity_Media.user,Entity_User.firstName].join('.'))
          .selects([Entity_Media.user,Entity_User.lastName].join('.'))
          .selects([Entity_Media.user,Entity_User.secondName].join('.'))

          //user
          .selects([Entity_Media.userAuthor, Entity_User.id].join('.'))
          .selects([Entity_Media.userAuthor, Entity_User.firstName].join('.'))
          .selects([Entity_Media.userAuthor, Entity_User.lastName].join('.'))
          .selects([Entity_Media.userAuthor, Entity_User.secondName].join('.'))
          .selects([Entity_Media.userAuthor, Entity_User.imageUrl].join('.'))


        
          .filterAnd([Entity_Media.entity, Entity_Media.mediaDir, Entity_MediaDir.id ].join('.')+' NOT IN (?)',[1])
          .filterAnd([Entity_Media.entity, Entity_Media.status].join('.')+'!=?','deleted')


          // filter by created
          if(this.dateFrom) {  
            mediaRequest.filterAnd([Entity_Media.entity, Entity_Media.created].join('.')+'>=?',moment(this.dateFrom, 'DD-MM-YYYY').format('YYYY-MM-DD 00:00:00'));
          } 

          if(this.dateTo) {  
            mediaRequest.filterAnd([Entity_Media.entity, Entity_Media.created].join('.')+'<=?',moment(this.dateTo, 'DD-MM-YYYY').format('YYYY-MM-DD 23:59:59'));
          }

          // filter by user
          if (this.usersSelected.length>0) {
            mediaRequest.filterAnd([Entity_Media.entity, Entity_Media.user, Entity_User.id ].join('.')+' in (?)',this.queryArray(this.usersSelected))
          }

          // filter by userSpec
          if (this.usersSpecSelected.length>0) {
            mediaRequest.filterAnd([Entity_Media.entity, Entity_Media.userAuthor, Entity_User.id ].join('.')+' in (?)',this.queryArray(this.usersSpecSelected))
          }

          //filter by name
          if (this.medRecordName!==null&&this.medRecordName!==''){
            mediaRequest.filterAnd([Entity_Media.entity, Entity_Media.name].join('.')+' LIKE ?','%'+this.medRecordName+'%');
          }

          //directions
          if (this.directions.length>0) {
            mediaRequest.filterAnd([Entity_Media.entity, Entity_Media.user, Entity_User.medPlans, Entity_MedPlan.directions, Entity_Direction.id].join('.')+' in (?)', this.queryArray(this.directions))
          }

          //анализы
          if (this.onlyTests) {
            mediaRequest.filterAnd([Entity_Media.entity, Entity_Media.mediaDir, Entity_MediaDir.name].join('.')+' =?','Анализы');
          }

          mediaRequest.page(1).onPage(1000)

          mediaRequest.request((data)=>{
              resolve(data.items);
          }, (e)=>{
            console.error(e.response.data.message);
          })
        });
        promises.push(media);


        Promise.all(promises).then((result) => {
          let medRecordsAndMedia = [];
          result.forEach(arr=>{
            medRecordsAndMedia = medRecordsAndMedia.concat(arr);
          })

          //sort by created
          let sortedArray = medRecordsAndMedia.slice().sort(function(a, b) {
              return new Date(b.created) - new Date(a.created);
          });


          this.medRecords = sortedArray;

          this.loadState.stateSuccess();
        }).catch((error) => {
          console.error('Error:', error);
          this.loadState.stateError(error)
        }); 
      }, 500)    
    },
    queryArray(items,key='id') {
      let arr = [];
      items.forEach(s=>{
        arr.push(s[key]);
      });
      return arr;
    },
    medRecordCopied(id){
      this.medRecordId = id;
    },
    misDesktopOpenFile(v){
      axios.get(Api.host + '/media/media/' + v.id + '/').then(r1 => {
        let mediaData = r1.data;
        this.$tools.misDesktopOpenFile(
            mediaData.filename,
            mediaData.name + mediaData.filename.substring(mediaData.filename.lastIndexOf("."), mediaData.length),
            "https://"+window.location.hostname+"/api/media/media/upload/desktop/",
            {
              id: v.id,
            },
            ()=>{
              this.$store.commit('alertSuccess', 'Файл открывается локально');
            }, ()=>{
              this.$store.commit('alertError', 'Не удалось открыть файл локально');
            }
        );
      });
    },
    downloadAction(item){
      this.loadState.stateLoading();
      function download(href,name) {
        const a = document.createElement('a');
        a.href = href;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      if (typeof item.medRecordTemplate!=='undefined') {
        let name = item.name!==null ? item.name : item.medRecordTemplate.name;
        Api.service.medRecord.medRecordDownload(item.id, (data)=>{
          for(let index in data){
            download(data[index],name+' ('+this.$tools.userFullName(item.user)+')'+'.docx')
            this.loadState.stateSuccess();
          }
        });
      }  else {
        let fileExtension = item.fileName.substring(item.fileName.lastIndexOf('.'))
        download('/media/'+item.fileName,item.name+' ('+this.$tools.userFullName(item.user)+')'+fileExtension )
        this.loadState.stateSuccess();
      }
    },
    preview(item) {
      this.previewState.stateLoading();
      this.previewWidth = window.innerWidth;
      this.previewHeight = window.innerHeight-64;

      Api.service.medRecord.medRecordDownload(item.id, (data)=>{
        for(let index in data){
          let obj = item;
          obj.previewUrl = 'https://view.officeapps.live.com/op/embed.aspx?src='+data[index];
          console.log(obj)
          this.previewState.modalOpen(obj);
        
        }
        this.previewState.stateSuccess();
      });
    },
    print(item){
      this.printState.stateLoading();
      if (typeof item.medRecordTemplate!=='undefined') {
        axios.get(Api.host+`/medRecord/medRecord/${item.id}/preview/`)
        .then(r=>{
          let images = [];
          r.data.forEach(v=>{
            images.push(v);
          });
          printJS({ printable: images, type: 'image', header: ''});
          this.printState.stateSuccess();
          this.load();
        }).catch(e=>{
          console.log(e);
          this.printState.stateError(e.response.data.errors);
        });
      }else {
        Api.service.media.mediaPreview(item.id, (data)=>{
          let images = [];
          data.forEach((v)=>{
            images.push(v);
          });
          printJS({ printable: images, type: 'image', header: ''});
          this.printState.stateSuccess();
          this.load();
        });
      }  
    },
    sendEmail(item){
      this.emailState.stateLoading();
      if (typeof item.medRecordTemplate!=='undefined') {
        Api.service.medRecord.medRecordEmailSend(item.id, ()=>{
          this.emailState.stateSuccess();
          this.emailState.modalClose();
          this.$store.commit('alertSuccess', 'Успешно отправлено');
          this.load();
        });
      } else {
        Api.service.media.mediaEmailSend(item.id, ()=>{
          this.emailState.stateSuccess();
          this.emailState.modalClose();
          this.$store.commit('alertSuccess', 'Успешно отправлено');
          this.load();
        });
      }  
    },
    deleteAction(item){
      this.loadState.stateLoading();
      if (typeof item.medRecordTemplate!=='undefined') {
        Api.service.medRecord.medRecordDelete(item.id, ()=>{
          this.loadState.stateSuccess();
          this.load();
        });
      } else {
        Api.service.media.mediaDelete(item.id, ()=>{
          this.loadState.stateSuccess();
          this.load();
        });
      } 
    },
    copyAction(item){
      this.loadState.stateLoading();
      Api.service.medRecord.medRecordCopy(item.id, ()=>{
        this.loadState.stateSuccess();
        this.$store.commit('alertSuccess', 'Успешно скопировано');
        this.load();
      });
    },
  }
}
</script>

<style lang="scss" scoped>
  .action-btns {
    display: flex;
    gap: 10px;
  }
</style>