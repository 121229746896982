<template>
  <div>
    <autocomplete-multiple :name="name" :search-text.sync="searchText" :items="items" :select.sync="localSelect" :loading="loadState.isLoading" 
    v-bind="typeof selectAllEnabled !== 'undefined' ? { selectAllEnabled } : {}"/>
  </div>

</template>

<script>
import State from "@/plugins/state";
import AutocompleteMultiple from "@/componentsV2/base/AutocompleteMultiple.vue";

// import Dot from "@/componentsV2/base/Dot.vue";

import {
  Entity_UserProfile,
  Entity_Referral, 
  Entity_User, 
} from "../../../EntityStoreCacheService";


export default {
  components: {
    AutocompleteMultiple,
    //Dot
  },
  props:[
    'name',
    'select',
    'selectAllEnabled'
  ],
  data:()=>({
    loadState: new State(),
    searchText: null,
    items:[],
    localSelect: null
  }),
  watch: {
    select() {
      this.localSelect = this.select;
    },
    localSelect() {
      this.$emit('update:select', this.localSelect)
    },
    searchText(){
      this.$tools.throttle(()=>{
        if (this.searchText&&this.searchText!=='') {
          let rb = new Entity_Referral()
            .selects(Entity_Referral.comment)
            .selects(Entity_Referral.contractNumber)
            .selects(Entity_Referral.phone)
            .selects(Entity_Referral.id)
            .selects(Entity_Referral.name)
            .selects(Entity_Referral.status)
            //user
            .selects([Entity_Referral.user, Entity_User.id].join('.'))
            .selects([Entity_Referral.user, Entity_User.firstName].join('.'))
            .selects([Entity_Referral.user, Entity_User.lastName].join('.'))
            .selects([Entity_Referral.user, Entity_User.secondName].join('.'))
            .selects([Entity_Referral.user, Entity_User.phone].join('.'))
            //userProfile
            .selects([Entity_Referral.user, Entity_User.userProfile, Entity_UserProfile.birth].join('.'))
            .selects([Entity_Referral.user, Entity_User.userProfile, Entity_UserProfile.gender].join('.'))
            .selects([Entity_Referral.user, Entity_User.userProfile, Entity_UserProfile.email].join('.'))
            .selects([Entity_Referral.user, Entity_User.userProfile, Entity_UserProfile.phone1].join('.'))
            .selects([Entity_Referral.user, Entity_User.userProfile, Entity_UserProfile.workOrgPhone].join('.'))
          

            if(!this.$tools.isEmpty(this.searchText)) {
              let v = this.searchText.trim();

              if (v.includes('@')) {
                rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.userProfile, Entity_UserProfile.email].join('.') + ' LIKE ?', '%' + v + '%')
              } else if (/\d/.test(v)) {
                rb.filterAnd(
                    [Entity_Referral.entity, Entity_Referral.phone].join('.') + ' LIKE ? OR ' +
                    [Entity_Referral.entity, Entity_Referral.user, Entity_User.phone].join('.') + ' LIKE ? OR ' +
                    [Entity_Referral.entity, Entity_Referral.user, Entity_User.userProfile, Entity_UserProfile.phone1].join('.') + ' LIKE ?'
                    ,
                    '%' + v.replace(/\D/g, '') + '%',
                    '%' + v.replace(/\D/g, '') + '%',
                    '%' + v.replace(/\D/g, '') + '%'
                )
              } else {
                let arr = v.split(' ');
              
                if (arr.length === 1) {
                  rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.lastName].join('.') + ' LIKE ?', '%' + arr[0] + '%')
                } else if (arr.length === 2) {
                  rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.lastName].join('.') + ' LIKE ?', '%' + arr[0] + '%')
                  rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.firstName].join('.') + ' LIKE ?', '%' + arr[1] + '%')
                } else if (arr.length === 3) {
                  rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.lastName].join('.') + ' LIKE ?', '%' + arr[0] + '%')
                  rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.firstName].join('.') + ' LIKE ?', '%' + arr[1] + '%')
                  rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.secondName].join('.') + ' LIKE ?', '%' + arr[2] + '%')
                }

                rb.filterOr([Entity_Referral.entity, Entity_Referral.name].join('.') + ' LIKE ?', '%' + arr[0] + '%')
              }
            }

            //exclude added
            if (this.items.length>0) {
              rb.filterAnd([Entity_Referral.entity, Entity_Referral.id].join('.') + ' not in (?)', [this.items.map(i=>i.id)]);
            }

            rb.order([Entity_Referral.name].join('.'), true)

            rb.page(1).onPage(1000);

            rb.request((data)=>{        
              const mergedArray = [...this.items, ...data.items];
              this.items = mergedArray.sort((a, b) => a.name.localeCompare(b.name));
              this.loadState.stateSuccess();
            }, (e)=>{
            console.error(e.response.data.message);
            this.loadState.stateError();
          })
        }
      }, 300);
    },
  },
}
</script>