<template>
  <v-text-field
      v-model="localDate"
      :error-messages="localDateError"
      :label="name"
      outlined dense hide-details="auto"
      style="margin-bottom: 16px"
      :disabled="typeof disabled !=='undefined'"
  >
    <template v-slot:append>
      <v-menu v-if="typeof disabled === 'undefined'" v-model="isOpen" :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" style="margin-top:-6px;margin-right:-10px;">
            <v-icon color="grey" size="18">mdi-calendar-month</v-icon>
          </v-btn>
        </template>
        <v-date-picker :first-day-of-week="1" v-model="localDateCalendar" color="primary" show-current no-title @change="isOpen=false">
          <v-btn block depressed @click="today()">Текущий день</v-btn>
        </v-date-picker>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
import moment from "moment";

export default {
  props: [
    'name', 'value', 'disabled'
  ],
  data: ()=>({
    isOpen:false,
    dateFrom:null,
  }),
  mounted(){
  },
  computed:{
    localDate:{
      get(){
        return this.value;
      },
      set(v){
        this.$emit('update:value', v);
      }
    },
    localDateCalendar:{
      get(){
        if(this.$tools.isEmpty(this.localDate))
          return moment().format('YYYY-MM-DD')
        return moment(this.localDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
      },
      set(v){
        this.localDate = moment(v, 'YYYY-MM-DD').format('DD.MM.YYYY');
      }
    },
    localDateError:{
      get(){
        if(!this.$tools.isEmpty(this.localDate) && moment(this.localDate, 'DD.MM.YYYY').format('DD.MM.YYYY')!==this.localDate)
          return ['Неверный формат. Пример: 01.01.2001'];
        return [];
      },
      set(){
      }
    }
  },
  methods:{
    today(){
      this.isOpen = false;
      this.$emit('update:value', moment().format('DD.MM.YYYY'));
    }
  }
}
</script>
