<template>
  <v-dialog
      v-model="loadState.isModalOpen"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
  >
    <v-card class="grey lighten-4">
      <v-toolbar dark :color="!$vuetify.theme.dark ? 'rgb(2 146 77)' : 'rgb(40 40 40)'" max-height="64" style="z-index: 1">
        <v-btn icon dark @click="$emit('update:mediaId', null)">
          <v-progress-circular v-if="loadState.isLoading" indeterminate size="20" width="3"/>
          <v-icon v-else>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="loadState.isSuccess">
          <div class="caption">Документ</div>
          <div class="body-1">{{ mediaData.name }}</div>
        </v-toolbar-title>
        <v-spacer/>
        <div class="d-inline-block mr-2">
          <log entity-name="Media" :entity-id="mediaId"/>
        </div>
        <v-btn icon @click="load" title="Обновить">
          <v-icon>mdi mdi-refresh</v-icon>
        </v-btn>
        <v-btn icon @click="misDesktopOpenFile" title="Открыть локально">
          <v-icon>mdi mdi-open-in-new</v-icon>
        </v-btn>
        <v-btn icon @click="download" title="Скачать">
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-btn :class="{ btnSuccessIco: mediaData?.isPrinted }" icon @click="print()" title="Печать" :loading="printState.isLoading">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
        <v-btn :class="{ btnSuccessIco: mediaData?.isSent }" icon @click="emailState.modalOpen()" :loading="emailState.isLoading">
          <v-icon title="Отправить на Email">mdi-email-arrow-right</v-icon>
        </v-btn> 
        <v-btn icon @click="deleteState.modalOpen()" title="Удалить">
          <v-icon>mdi-delete</v-icon>
        </v-btn>

        <v-dialog v-model="emailState.isModalOpen" width="500">
          <v-card>
            <v-card-title>Отправить мед.запись на почту клиента?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="emailState.modalClose()">Отмена</v-btn>
              <v-btn color="primary" text @click="emailAction" :loading="emailState.isLoading">Отправить</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>  

        <v-dialog v-model="deleteState.isModalOpen" width="250">
          <v-card>
            <v-card-title>Удалить?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="deleteState.modalClose()">Отмена</v-btn>
              <v-btn color="red" text @click="deleteAction" :loading="deleteState.isLoading">Удалить</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-menu v-if="mediaData!=null" rounded="lg" offset-y>
          <template v-slot:activator="{ attrs, on }">
            <v-btn v-on="on" icon title="Сведения">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <div class="px-4 pt-2">
            <div class="caption">Автор</div>
            <div class="body-2 mb-2" :title="'#'+mediaData.userAuthor.id+' '+mediaData.userAuthor.name">
              {{ mediaData.userAuthor.name }}
            </div>
            <div class="caption">Принадлежит</div>
            <div class="body-2 mb-2" :title="'#'+mediaData.user.id+' '+mediaData.user.name">
              {{ mediaData.user.name }}
            </div>
            <div v-if="mediaData.mediaTemplate!=null" class="caption">Шаблон</div>
            <div v-if="mediaData.mediaTemplate!=null" class="body-2 mb-2" :title="'#'+mediaData.mediaTemplate.id+' '+mediaData.mediaTemplate.name">
              {{ mediaData.mediaTemplate.name }}
            </div>
            <div class="caption">Создан</div>
            <div class="body-2 mb-2">{{ mediaData.created }}</div>
          </div>
        </v-menu>
      </v-toolbar>
      <div v-if="loadState.isSuccess" class="view">
        <div class="editor elevation-2 white">
          <v-row class="px-4 my-2">
            <v-col cols="12">
              <v-textarea v-model="mediaData.name" label="Название" rows="1" outlined dense auto-grow hide-details="auto"/>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="mediaData.des" label="Описание" rows="1" outlined dense auto-grow hide-details="auto"/>
            </v-col>
            <v-col>
              <checkbox-field name="Показать в личном кабинете" :value.sync="mediaData.isShowInService"/>
            </v-col>
            <v-col cols="12">
              <v-btn color="blue darken-1" text @click="saveState.modalOpen()">Сохранить</v-btn>
              <v-dialog v-model="saveState.isModalOpen" width="250">
                <v-card>
                  <v-card-title>Сохранить?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="saveState.modalClose()">Отмена</v-btn>
                    <v-btn color="primary" text @click="save" :loading="saveState.isLoading">Сохранить</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </div>
        <div class="preview px-6 pt-6">
          <div v-if="previewState.isLoading">
            <v-progress-circular indeterminate size="20" width="3" color="primary"/>
          </div>
          <div v-if="previewState.isSuccess">
            <div v-for="(preview, index) in previews" :key="'previewPage'+index" class="pb-6">
              <v-sheet
                  v-if="preview.indexOf('.png')!==-1"
                  :width="isZoom ? zoomMaxView-200 : 600"
                  rounded class="d-inline-block overflow-hidden transition-ease-in-out" elevation="2"
              >
                <v-img :src="preview" class="full-width"/>
              </v-sheet>
              <v-sheet
                  v-if="preview.indexOf('.mp4')!==-1"
                  :width="isZoom ? zoomMaxView : 800"
                  rounded class="d-inline-block overflow-hidden" elevation="2"
              >
                <video :src="preview" controls class="full-width float-left"></video>
              </v-sheet>
              <v-sheet
                  v-if="preview.indexOf('.mp3')!==-1"
                  :width="isZoom ? zoomMaxView : 600"
                  rounded class="d-inline-block overflow-hidden pa-4" elevation="2"
              >
                <audio :src="preview" controls class="full-width float-left"></audio>
              </v-sheet>
              <v-sheet
                  v-if="preview.indexOf('.png')===-1 && preview.indexOf('.mp4')===-1 && preview.indexOf('.mp3')===-1"
                  rounded class="d-inline-block overflow-hidden pa-4" elevation="2"
              >
                <div>
                  <v-icon size="34" class="mb-2">mdi-information-outline</v-icon>
                  <div class="body-2">Просмотр не доступен</div>
                </div>
              </v-sheet>
            </div>
            <div class="zoomBar">
              <v-btn fab dark color="primary" @click="zoomSwitch">
                <v-icon dark>{{isZoom ? 'mdi-magnify-minus-outline' : 'mdi-magnify-plus-outline'}}</v-icon>
              </v-btn>
            </div>
          </div>

        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";
import Log from "@/views/Log/Log";
import printJS from 'print-js'
import axios from "axios";
import CheckboxField from "@/componentsV2/base/CheckboxField.vue";

export default {
  components: {Log, CheckboxField},
  props: ['mediaId', 'userId', 'userName'],
  data: () => ({
    loadState: new State(),
    mediaData:null,
    isZoom: false,
    zoomMaxView: window.innerWidth-600,
    previews:[],

    previewState: new State(),

    saveState: new State(),
    deleteState: new State(),
    printState: new State(),
    misDesktopOnline:false,
    emailState: new State(), 
  }),
  watch:{
    mediaId(){
      if (this.mediaId==null){
        this.loadState.modalClose();
        this.$emit('updateList');
      } else{
        this.load();
        this.loadState.modalOpen();
      }
    }
  },
  mounted() {
    this.$tools.misDesktopStatus((v)=>{
      this.misDesktopOnline = v;
    });
  },
  methods:{
    load(){
      this.loadState.stateLoading();
      Api.service.media.media(this.mediaId, (data)=>{
        this.loadState.stateSuccess();
        this.mediaData = data;
        this.preview();
      });
    },
    preview(){
      this.previewState.stateLoading();
      Api.service.media.mediaPreview(this.mediaId, (data)=>{
        this.previews = data;
        if (this.previews.length>0)
          this.previewState.stateSuccess();
        else
          this.previewState.stateError();
      });
    },
    download(){
      const a = document.createElement('a');
      a.href = this.mediaData.filename;
      let fileExtension = this.mediaData.filename.substring(this.mediaData.filename.lastIndexOf('.'))
      a.download = this.mediaData.name+' ('+this.userName.trim()+')'+fileExtension;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    zoomSwitch(){
      this.isZoom = !this.isZoom;
    },

    save(){
      this.saveState.stateLoading();
      Api.service.media.mediaUpdate(this.mediaData.id, {
        name: this.mediaData.name,
        des: this.mediaData.des,
        userId: this.userId,
        isShowInService:this.mediaData.isShowInService
      }, ()=>{
        this.saveState.stateSuccess();
        this.saveState.modalClose();
      });
    },
    deleteAction(){
      this.deleteState.stateLoading();
      Api.service.media.mediaDelete(this.mediaData.id, ()=>{
        this.deleteState.stateSuccess();
        this.deleteState.modalClose();
        this.$emit('update:mediaId', null);
        this.$emit('updateList');
      });
    },
    print(){
      this.printState.stateLoading();
      Api.service.media.mediaPreview(this.mediaData.id, (data)=>{
        this.printState.stateSuccess();
        let images = [];
        data.forEach((v)=>{
          images.push(v);
        });
        printJS({ printable: images, type: 'image', header: ''});
        this.mediaData.isPrinted = true;
      });
    },
    emailAction(){
      this.emailState.stateLoading();
      Api.service.media.mediaEmailSend(this.mediaData.id, ()=>{
        this.emailState.stateSuccess();
        this.emailState.modalClose();
        this.mediaData.isSent = true;
        this.$store.commit('alertSuccess', 'Успешно отправлено');
      });
    },
    misDesktopOpenFile(){
      axios.get(Api.host + '/media/media/' + this.mediaData.id + '/').then(r1 => {
        this.mediaData = r1.data;
        this.$tools.misDesktopOpenFile(
          this.mediaData.filename,
          this.mediaData.name + this.mediaData.filename.substring(this.mediaData.filename.lastIndexOf("."), this.mediaData.length),
          "https://"+window.location.hostname+"/api/media/media/upload/desktop/",
          {
            id: this.mediaData.id,
          },
          ()=>{
            this.$store.commit('alertSuccess', 'Файл открывается локально');
          }, ()=>{
            this.$store.commit('alertError', 'Не удалось открыть файл локально');
          }
       );
      });
    }
  }
}
</script>

<style scoped lang="scss">
.view {
  position: absolute;
  top: 64px;
  bottom: 0;
  left: 0;
  right:0;
  .editor {
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 600px;
  }
  .preview{
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left:600px;
    right:0;
    text-align: center;
    .zoomBar{
      position: fixed;
      right: 2rem;
      bottom: 1rem;
    }
  }
}

</style>