<template>
  <autocomplete-multiple
      name="Теги"
      :items="items"
      :select.sync="localSelect"
      :loading="state.isLoading"
      inline
  />
</template>

<script>
import State from "@/plugins/state";
import AutocompleteMultiple from "@/componentsV2/base/AutocompleteMultiple.vue";

import {
  Entity_UserProfileTag,
} from "../../../EntityStoreCacheService";

export default {
  components: {AutocompleteMultiple,},
  props:['select'],
  data:()=>({
    localSelect: null,
    state: new State(),
    items:[]
  }),
  mounted() {
    this.localSelect = this.select;
    new Entity_UserProfileTag()
        .selects(Entity_UserProfileTag.name)
        .order(Entity_UserProfileTag.name, true)
        .page(1).onPage(1000)
        .addStateCallback((v)=> this.state.setRequestBuilderState(v))
        .addStateCallback((v)=>{
          if(v.success)
            this.items = v.items;
        })
        .request()
  },
  watch: {
    select() {
      this.localSelect = this.select;
    },
    localSelect() {
      this.$emit('update:select', this.localSelect)
    },
  }
}
</script>