<template>
  <v-row no-gutters>
    <v-col class="navBar border-right" v-if="!sidebarCollapse">
      <v-sheet :height="height" class="overflow-auto">
        <v-row no-gutters class="mb-2">
          <v-col cols="12">
            <v-row class="px-3 pt-2" no-gutters align="center">
              <v-col><h3 class="font-weight-regular">{{$tools.dateText(beginDate)}}</h3></v-col>
              <v-col cols="auto">
                <v-btn icon @click="setToday">
                  <v-icon v-if="isToday" color="primary" small>mdi-checkbox-blank-circle</v-icon>
                  <v-icon v-else small>mdi-checkbox-blank-circle-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <date-picker :daySelected.sync="beginDate" cookieName="userSpecPlansDay"/>
            <div class="text-center caption">
            {{ $tools.date(beginDate) }} - {{ $tools.date(endDate) }}
            </div>
            <div class="d-flex justify-center">
              <v-btn-toggle
                class="py-3"
                v-model="dateRange"
                mandatory
                dense
              >
                <v-btn small :value="7">
                  Неделя
                </v-btn>
                <v-btn small :value="14">
                  2 недели
                </v-btn>
                <v-btn small :value="currentMonthLength" >
                  месяц
                </v-btn>
              </v-btn-toggle>
            </div>
          </v-col>
        </v-row>
        
        <div class="mx-3">
          <v-row class="rowXSmall mb-4">
            <v-col cols="6">
              <v-btn block small depressed color="silver" @click="updateZoom('add')" :disabled="zoom===2">
                <v-icon>mdi-magnify-minus-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn block small depressed color="silver" @click="updateZoom('subtract')" :disabled="zoom===1">
                <v-icon>mdi-magnify-plus-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn block small depressed color="silver" to="/userSpecPlan/">
                День
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn block small depressed color="silver" @click="copyState.modalOpen(1)">
                Копировать
              </v-btn>
              <dialog-view title="Копирование расписания" :open="copyState.isModalOpen" @cancel="copyState.modalClose()">
                <template v-slot:content>
                  <div v-if="copyState.modalData===1">
                    <div class="caption">
                      Выберите промежуток который вы хотите скопировать
                    </div>
                    <v-date-picker
                        v-model="copyState.modalData1"
                        range
                        :show-current="true"
                        :first-day-of-week="1"
                        no-title
                        show-adjacent-months
                        color="primary"
                        full-width
                    />
                  </div>
                  <div v-if="copyState.modalData===2">
                    <div class="caption">
                      Выберите день с которого необходимо вставить выбранный промежуток
                    </div>
                    <v-date-picker
                        v-model="copyState.modalData2"
                        :show-current="true"
                        :first-day-of-week="1"
                        no-title
                        show-adjacent-months
                        color="primary"
                        full-width
                    />
                  </div>
                  <div v-if="copyState.modalData===3">
                    <div class="caption mb-3">
                      Выберите филиал
                    </div>
                    <com-branch-autocomplete-multiple :select.sync="copyState.modalData3"/>
                  </div>
                </template>
                <template v-slot:actions>
                  <v-btn v-if="copyState.modalData===1" @click="copyState.modalData=2" color="primary" text>Далее</v-btn>
                  <v-btn v-if="copyState.modalData===2" @click="copyState.modalData=3" color="primary" text>Далее</v-btn>
                  <v-btn v-if="copyState.modalData===3&&copyState.modalData3" @click="copy()" color="primary" text>Копировать</v-btn>
                </template>
              </dialog-view>
              <progress-page :loading="copyState.isLoading" icon="mdi-content-copy" dialog/>
            </v-col>
          </v-row>
        </div>

        <div class="px-3">
          <com-div-autocomplete-multiple :select.sync="filter.comDivSelected"/>

          <com-branch-autocomplete-multiple :select.sync="comBranchsSelected" globalState/>

          <spec-autocomplete-multiple :select.sync="filter.specsSelected" />

          <user-autocomplete-multiple name="Специалисты" _type="spec" :select.sync="filter.usersSelected"/>

          <checkbox-field name="Скрыть пустые раб. места" class="mb-3" :value.sync="filter.hideEmptyComPlace"/>

          <checkbox-field name="Показать статистику" class="mb-3" :value.sync="filter.showWidgets"/>

          <checkbox-field name="Показать удалённые" :value.sync="filter.showDeleted"/>
        </div>
      </v-sheet>
    </v-col>
    <v-col>
      <v-progress-linear v-if="loadState.isLoading" indeterminate color="primary" style="z-index:10"/>
      <transition name="fade">
        <v-row class="rowSmall my-3 mx-3" v-show="(noFade||loadState.isSuccess)&&filter.showWidgets">
          <v-col cols="auto">
            <card-view :title="'Загрузка рабочих мест: '+indicators.comPlaceLoadHoursSum+' ч'" :loading="loadState.isLoading" minHeight="84">
              <template v-slot:content>
                <div class="d-flex justify-center">
                  <progress-widget
                      :text1="typeof indicators.comPlaceLoadPercent!=='undefined' ? indicators.comPlaceLoadPercent+' %' : '0'"
                      :value="indicators.comPlaceLoadPercent"
                  />
                  </div>
              </template>
            </card-view>
          </v-col>

          <v-col cols="auto" v-if="indicators.userSpecPlansHoursSum>0">
            <card-view :title="'Загрузка рабочих мест специалистом: '+indicators.userSpecPlansHoursSum+' ч'" :loading="loadState.isLoading"  minHeight="84">
              <template v-slot:content>
                <div class="d-flex justify-center">
                  <progress-widget
                      :text1="typeof indicators.comPlaceLoadPercentByUser!=='undefined' ? indicators.comPlaceLoadPercentByUser+' %' : '0'"
                      :value="indicators.comPlaceLoadPercentByUser"
                  />
                  </div>
              </template>
            </card-view>
          </v-col>
        </v-row>
      </transition>
      <transition name="fade">
      <v-sheet class="calendarSheet" v-show="noFade||loadState.isSuccess" :style="{cursor:cursorStyle}">
        <v-btn 
          @click="sidebarCollapse = !sidebarCollapse;"
          class="mt-6 sidebar-collapse-btn"
          fab
          small
          depressed
        >
          <v-icon v-if="!sidebarCollapse">mdi-arrow-expand-left</v-icon>
          <v-icon v-if="sidebarCollapse">mdi-arrow-expand-right</v-icon>
        </v-btn>

        <div class="work-schedule" :style="{width:width+'%',height:calendarHeight+'px',right:userSpecPlanState.isModalOpen ? '440px' : '0'}" @mouseup.right.stop="loadState.isSuccess&&eventDrag.length>0  ? finishDrag(eventDrag[0]) : null"  @scroll="calendarScroll($event)" ref="scrollContainer">
          
   
          <div class="work-schedule__dates-row">
            <div class="work-schedule__weekday" v-for="day in week" :key="day.date+'_'+day.day">
              <div class="work-schedule__day-of-week">
                <div class="work-schedule__date-wrapper">         
                  <tooltip bottom>
                    <span class="work-schedule__date-wrapper-day">{{day.day}}</span>
                    <span class="work-schedule__date-wrapper-date">{{$tools.date(day.dateYMD)}}</span>                  
                    <template v-slot:content>
                      {{day.day}} {{$tools.date(day.dateYMD)}}
                    </template>
                  </tooltip>                 
                </div>
              </div>
              <div class="work-schedule__hours" :style="{backgroundSize: cellWidth +'px 100%'}">
                <!-- <div class="work-schedule__cell" :class="{'work-schedule__cell_zoom-enabled':zoom>1}" v-for="time in day.time" :key="time" 
                :style="{width:cellWidth+'px',transform: `translate3d(-${zoom===1 ? 15 : 7.5}px, 0px, 0px)`}">{{time}}</div> -->
                <div class="work-schedule__cell" :class="{'work-schedule__cell_zoom-enabled':zoom>1}" v-for="time in day.time.slice(0,-1)" :key="time">{{time}}</div>
              </div>  
            </div>
          </div>  

          <div class="work-schedule__schedule-rows" ref="workplace">     
            <template v-for="comPlace in comPlaces">
            <user-spec-plan-row :key="'cp_'+comPlace.id"
                @mouseMove="mouseMove"
                :cellWidth="cellWidth"
                :sidebarCollapse="sidebarCollapse"
                :week="week"
                :zoom="zoom"
                :xScroll="xScroll"
                :comPlace="comPlace"
                :events="events"
                :eventStretching="eventStretching"
                :eventDrag="eventDrag"
                :eventCopy="eventCopy"
                :loadState="loadState"
                :userSpecPlanState="userSpecPlanState"
                :selectedPlace="selectedPlace"
                :keyPressed="keyPressed"
                :selectedDate="selectedDate"
                :tooltipStyle="tooltipStyle"
                :selectedTime="selectedTime"
                :cursorStyle.sync="cursorStyle"
                @hideTooltip="hideTooltip"
                @startCopy="startCopy"
                @finishCopy="finishCopy"
                @startDrag="startDrag"
                @finishStretching="finishStretching"
                @startStretching="startStretching"
            />
            </template>
          </div>
         
        </div>
      </v-sheet>
      </transition>

      <user-spec-plan
          v-if="comRooms.length>0"
          :state="userSpecPlanState"
          :comRooms="comRooms"
          @cancel="userSpecPlanState.modalClose()"
          @createdUpdatedDeleted="userSpecPlanState.modalClose();noFade=true;load()"
        />

    </v-col>
  </v-row>
</template>


<script>
import moment from "moment";
import State from "@/plugins/state";
import axios from "axios";
import Api from "@/Api";
import UserSpecPlan from "@/views/UserSpec/components/UserSpecPlan";
import UserSpecPlanRow from "@/views/UserSpec/components/UserSpecPlanRow";


import DialogView from "@/components/DialogView";
import ProgressPage from "@/components/ProgressPage";
//import SpecSelect from "@/views/Spec/components/SpecSelect";
import DatePicker from "@/components/DatePicker";
import ComBranchAutocompleteMultiple from "@/componentsV2/custom/ComBranchAutocompleteMultiple.vue";
import CheckboxField from "@/componentsV2/base/CheckboxField.vue";


import UserAutocompleteMultiple from "@/componentsV2/custom/UserAutocompleteMultiple.vue";
import SpecAutocompleteMultiple from "@/componentsV2/custom/SpecAutocompleteMultiple.vue";

import ComDivAutocompleteMultiple from "@/componentsV2/custom/ComDivAutocompleteMultiple.vue";
import CardView from "@/components/CardView";
import Tooltip from "@/componentsV2/base/Tooltip.vue";
import ProgressWidget from "../../componentsV2/base/ProgressWidget.vue";

import {
  Entity_UserSpecPlan,
  Entity_UserSpec, 
  Entity_User, 
  Entity_Spec, 
  Entity_ComPlace, 
  Entity_ComRoom, 
  Entity_ComFloor, 
  Entity_ComBuilding, 
  Entity_ComBranch,
  Entity_ComDiv
} from "../../../EntityStoreCacheService";

export default {
  components: {
    ComBranchAutocompleteMultiple,
    SpecAutocompleteMultiple,
    ProgressPage, 
    DialogView, 
    UserSpecPlan, 
    UserSpecPlanRow, 
    DatePicker,
    CheckboxField, 
    UserAutocompleteMultiple,
    ComDivAutocompleteMultiple,
    CardView,
    Tooltip,
    ProgressWidget
  },
  data: () => ({
    beginDate: moment(new Date()).format('YYYY-MM-DD'),
    endDate: null,

    dateRange: 14,
    currentMonthLength:31,
    zoom:1,
    height: 600,
    calendarHeight:600,
    width: 100,

    loadState: new State(),
    noFade:false,

    //filter by combranch
    comBranchsSelected:[],

    filter: {
      //filter by spec
      specsSelected:[],

      //filter by user
      usersSelected:[],
          //show deleted
      showDeleted: false,

      hideEmptyComPlace: false,

      //widgets
      showWidgets:false,

      //comDiv
      comDivSelected:[],
      
    },


    //events
    userSpecPlanState: new State(),
    events:[],

    //grid
    comRooms:[],
    comPlaces:[],
    week:[],
    step:30,
    cellWidth: 30,
    workingHours:null,

    //cursor
    cursorStyle: null,
    tooltipStyle: null,
    sidebarCollapse: false,

    xScrollInitial: null,
    xScroll: null,

    //selected
    selectedTime: null,
    selectedDate: null,
    selectedPlace: null,
    selectedWorkDayBegin:null, 
    selectedWorkDayEnd: null, 

    //drag and drop
    eventDrag:[],
    
    //eventStretching
    eventStretching:[],
    eventStretchingDay:null,

    //validation
    dayTimeValidation: true,
    

    copyState: new State(),

    //copy by ctrl/alt&click
    keyPressed: false,
    eventCopy:null,


    indicators: {
      comPlaceLoadPercent:0,
      comPlaceLoadHoursSum:0,
      comPlaceLoadPercentByUser:0,
      userSpecPlansHoursSum:0
    }
  }),
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('keyup', this.onKeyUp);
    window.removeEventListener('resize', this.resize());
  },
  mounted(){
    // Получение JSON-строки из localStorage
    const filterSettings = localStorage.getItem('userSpecPlansFilters');
    if (typeof localStorage.userSpecPlansFilters!=='undefined'&&filterSettings!==null)
      this.filter = JSON.parse(filterSettings);

    this.setEndDay();

    this.$cookie.delete('userSpecPlansLink')
    this.$cookie.set('userSpecPlansLink', '/userSpecPlans/', 30);
    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('keyup', this.onKeyUp);
    window.addEventListener('resize', this.resize());
    this.load();
  },
  computed:{
    isToday(){
      return this.beginDate===moment(new Date()).format('YYYY-MM-DD');
    }
  },
  watch:{
    beginDate(){
      this.setEndDay();
      if(this.loadState.isSuccess) {
        this.setDaysInMonth();
        this.load();
        this.scrollToStart();
        console.log(1)
      }  
    },
    comBranchsSelected(){
      this.load();
    },
    filter: {
      handler() {
        localStorage.setItem('userSpecPlansFilters', JSON.stringify(this.filter));
        this.load();
      },
      deep: true,
    },
    // 'filter.specsSelected'() {
    //   this.load();
    // },
    // 'filter.showDeleted'() {
    //   this.load();
    // },
    // 'filter.hideEmptyComPlace'(){
    //   this.load();
    // },
    // 'filter.comDivSelected'() {
    //   this.load();
    // },
    'filter.showWidgets'() {
      this.resize();
    },
    zoom(){
      this.step = 30*this.zoom;
      this.cellWidth = 30/this.zoom;
      this.renderGrid();
    },
    dateRange(){
      this.setEndDay();
      this.setDaysInMonth()
      this.load();
    }
  },
  methods: {
    load(){
      this.$tools.throttle(()=>{
        this.loadState.stateLoading();
        const loadingData = [];
        const userSpecPlans = new Promise((resolve, reject) => {
            let rb = new Entity_UserSpecPlan()
            .selects(Entity_UserSpecPlan.id)
            .selects(Entity_UserSpecPlan.begin)
            .selects(Entity_UserSpecPlan.end)
            .selects(Entity_UserSpecPlan.type)
            .selects(Entity_UserSpecPlan.gridMinutes)
            .selects(Entity_UserSpecPlan.status)
      
            //User
            .selects([Entity_UserSpecPlan.userSpec,Entity_UserSpec.user,Entity_User.id].join('.'))
            .selects([Entity_UserSpecPlan.userSpec,Entity_UserSpec.user, Entity_User.firstName].join('.'))
            .selects([Entity_UserSpecPlan.userSpec,Entity_UserSpec.user, Entity_User.lastName].join('.'))
            .selects([Entity_UserSpecPlan.userSpec,Entity_UserSpec.user, Entity_User.secondName].join('.'))
            .selects([Entity_UserSpecPlan.userSpec,Entity_UserSpec.user, Entity_User.imageUrl].join('.'))


            //spec comBranches
            //.selects([Entity_UserSpecPlan.userSpec, Entity_UserSpec.comDiv, Entity_ComDiv.comBranch,Entity_ComBranch.name ].join('.'))
            .selects([Entity_UserSpecPlan.userSpec, Entity_UserSpec.comDiv, Entity_ComDiv.comBranch,Entity_ComBranch.color ].join('.'))

            //UserSpec (spec for userSpecPlans)
            .selects([Entity_UserSpecPlan.userSpec,Entity_UserSpec.id].join('.'))
            .selects([Entity_UserSpecPlan.userSpec,Entity_UserSpec.commentAdmin].join('.'))
            //.selects([Entity_UserSpecPlan.userSpec,Entity_UserSpec.user,Entity_User.id].join('.'))
            .selects([Entity_UserSpecPlan.userSpec,Entity_UserSpec.spec, Entity_Spec.id].join('.'))
            .selects([Entity_UserSpecPlan.userSpec,Entity_UserSpec.spec, Entity_Spec.name].join('.'))
            .selects([Entity_UserSpecPlan.userSpec,Entity_UserSpec.spec, Entity_Spec.color].join('.'))
            
            //comRoom
            .selects([Entity_UserSpecPlan.comPlace, Entity_ComPlace.comRoom, Entity_ComRoom.id].join('.'))
            .selects([Entity_UserSpecPlan.comPlace, Entity_ComPlace.comRoom, Entity_ComRoom.name].join('.'))
            .selects([Entity_UserSpecPlan.comPlace, Entity_ComPlace.comRoom, Entity_ComRoom.sid].join('.'))
            .selects([Entity_UserSpecPlan.comPlace, Entity_ComPlace.comRoom, Entity_ComRoom.comPlaces, Entity_ComPlace.id].join('.'))
            .selects([Entity_UserSpecPlan.comPlace, Entity_ComPlace.comRoom, Entity_ComRoom.comPlaces, Entity_ComPlace.name].join('.'))

            //comPlace
            .selects([Entity_UserSpecPlan.comPlace,Entity_ComPlace.id].join('.'))
            .selects([Entity_UserSpecPlan.comPlace,Entity_ComPlace.name].join('.'))

            //comBranch
            .selects([Entity_UserSpecPlan.comPlace,Entity_ComPlace.comRoom,Entity_ComRoom.comFloor,Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch,Entity_ComBranch.id].join('.'))
            .selects([Entity_UserSpecPlan.comPlace,Entity_ComPlace.comRoom,Entity_ComRoom.comFloor,Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch,Entity_ComBranch.name].join('.'))
            .selects([Entity_UserSpecPlan.comPlace,Entity_ComPlace.comRoom,Entity_ComRoom.comFloor,Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch,Entity_ComBranch.color].join('.'))
            .selects([Entity_UserSpecPlan.comPlace,Entity_ComPlace.comRoom,Entity_ComRoom.comFloor,Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch,Entity_ComBranch. workPlan].join('.'))

            
            //filter by date
            rb.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.begin].join('.')+'>=?',moment(this.beginDate).format('YYYY-MM-DD 00:00:00'));
            rb.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.begin].join('.')+'<=?',moment(this.endDate).format('YYYY-MM-DD 23:59:59'));

            //filter by spec
            if(this.filter.specsSelected.length>0) {
              rb.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.userSpec, Entity_UserSpec.spec, Entity_Spec.id].join('.')+' in (?)',this.queryArray(this.filter.specsSelected));
            }

            //filter by user
            if (this.filter.usersSelected.length>0) {
              rb.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.userSpec, Entity_UserSpec.user,Entity_User.id].join('.')+' in (?)',this.queryArray(this.filter.usersSelected));
            }

            //filter by comBranchs
            if(this.comBranchsSelected.length>0) {
              rb.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.comPlace, Entity_ComPlace.comRoom, Entity_ComRoom.comFloor, Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch,Entity_ComBranch.id].join('.')+' in (?)',this.queryArray(this.comBranchsSelected));
            }

            if (this.filter.showDeleted) {
              rb.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.status].join('.')+'=?','deleted');
            } else {
              rb.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.status].join('.')+'=?','active');
            }

            rb.page(1).onPage(10000);
            //rb.order([Entity_UserSpecPlan.comPlace,Entity_ComPlace.comRoom,Entity_ComRoom.comFloor,Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch,Entity_ComBranch.id].join('.'), true)

            rb.request((data)=>{
              this.events = data.items;
              resolve()
            }, (e)=>{
              reject();
              console.error(e.response.data.message);
            })
        });
        loadingData.push(userSpecPlans);

        // comPlaces
        const comPlaces = new Promise((resolve, reject) => {
        let rb = new Entity_ComPlace()
        .selects(Entity_ComPlace.id)
        .selects(Entity_ComPlace.name)
        
        //comDiv
        .selects([Entity_ComPlace.comDiv, Entity_ComDiv.id].join('.'))
        .selects([Entity_ComPlace.comDiv, Entity_ComDiv.name].join('.'))

        //comRoom
        .selects([Entity_ComPlace.comRoom, Entity_ComRoom.id].join('.'))
        .selects([Entity_ComPlace.comRoom, Entity_ComRoom.name].join('.'))
        .selects([Entity_ComPlace.comRoom, Entity_ComRoom.sid].join('.'))
        .selects([Entity_ComPlace.comRoom, Entity_ComRoom.comPlaces, Entity_ComPlace.id].join('.'))
        .selects([Entity_ComPlace.comRoom, Entity_ComRoom.comPlaces, Entity_ComPlace.name].join('.'))
          
        //comBranch
        .selects([Entity_ComPlace.comRoom, Entity_ComRoom.comFloor, Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch, Entity_ComBranch.id].join('.'))
        .selects([Entity_ComPlace.comRoom, Entity_ComRoom.comFloor, Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch, Entity_ComBranch.name].join('.'))
        .selects([Entity_ComPlace.comRoom, Entity_ComRoom.comFloor, Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch, Entity_ComBranch.color].join('.'))
        .selects([Entity_ComPlace.comRoom, Entity_ComRoom.comFloor, Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch, Entity_ComBranch.workPlan].join('.'))


        let begin = moment(this.beginDate).format('YYYY-MM-DD 00:00:00');
        let end = moment(this.endDate).format('YYYY-MM-DD 23:59:59')
        rb.virtual(Entity_ComPlace.virtual_comPlaceLoad, begin, end)

        //filter by comBranchs
        if(this.comBranchsSelected.length>0) {
          rb.filterAnd([Entity_ComPlace.entity, Entity_ComPlace.comRoom, Entity_ComRoom.comFloor, Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch, Entity_ComBranch.id].join('.')+' in (?)',this.queryArray(this.comBranchsSelected));
        }

        //filter by comDiv
        if(this.filter.comDivSelected.length>0) {
          rb.filterAnd([Entity_ComPlace.entity, Entity_ComPlace.comDiv, Entity_ComDiv.id].join('.')+' in (?)',this.queryArray(this.filter.comDivSelected));
        }

        //filter by date
        if(this.filter.specsSelected.length>0||this.filter.usersSelected.length>0||this.filter.hideEmptyComPlace) {
          rb.filterAnd([Entity_ComPlace.entity, Entity_ComPlace.userSpecPlans, Entity_UserSpecPlan.begin].join('.')+'>=?',moment(this.beginDate).format('YYYY-MM-DD 00:00:00'));
          rb.filterAnd([Entity_ComPlace.entity, Entity_ComPlace.userSpecPlans, Entity_UserSpecPlan.begin].join('.')+'<=?',moment(this.endDate).format('YYYY-MM-DD 23:59:59'));
        }

        //filter by spec
        if(this.filter.specsSelected.length>0) {
          rb.filterAnd([Entity_ComPlace.entity, Entity_ComPlace.userSpecPlans, Entity_UserSpecPlan.userSpec, Entity_UserSpec.spec, Entity_Spec.id].join('.')+' in (?)',this.queryArray(this.filter.specsSelected));
        }

        //filter by user
        if (this.filter.usersSelected.length>0) {
          rb.filterAnd([Entity_ComPlace.entity, Entity_ComPlace.userSpecPlans, Entity_UserSpecPlan.userSpec, Entity_UserSpec.user,Entity_User.id].join('.')+' in (?)',this.queryArray(this.filter.usersSelected));
        }

        if ((this.filter.specsSelected.length>0||this.filter.usersSelected.length>0||this.filter.hideEmptyComPlace)&&!this.filter.showDeleted) {
          rb.filterAnd([Entity_ComPlace.entity, Entity_ComPlace.userSpecPlans, Entity_UserSpecPlan.status].join('.')+'=?','active');
        }


        rb.order([Entity_ComPlace.comRoom, Entity_ComRoom.comFloor, Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch, Entity_ComBranch.id].join('.'), true)
        // rb.order([Entity_ComPlace.comRoom, Entity_ComRoom.name].join('.'), true)
        // rb.order([Entity_ComPlace.comRoom, Entity_ComRoom.sid].join('.'), true)


        rb.page(1).onPage(10000);

        rb.request((data)=>{
          
          this.comPlaces = data.items;

          //comRooms
          let arr = this.comPlaces.map(cp => cp.comRoom);
          this.comRooms = Array.from(new Set(arr.map(item => item.id))).map(id => arr.find(item => item.id === id));

          //working hours
          if(this.workingHours===null) {
            const workPlans = this.comRooms.map(cr => cr.comFloor.comBuilding.comBranch.workPlan); 
            this.workingHours = workPlans[0]; 

            const compareTimes = (time, dayOfWeek, type='begin') => {
                let momentTime1 = moment(this.workingHours[dayOfWeek].begin, 'HH:mm');
                let momentTime2 = moment(time, 'HH:mm');

                if (type==='begin') {
                  if (momentTime2.isBefore(momentTime1)) {
                    this.workingHours[dayOfWeek].begin = momentTime2;
                  }
                } else {
                  momentTime1 =  moment(this.workingHours[dayOfWeek].end, 'HH:mm');
                  if (momentTime1.isBefore(momentTime2)) {
                    this.workingHours[dayOfWeek].end = momentTime2;
                  }
                }
            }
    
            //search for minimum and maximum working time
            workPlans.forEach(wp =>{
              wp.forEach((dayOfWeek, index)=>{
                //find begin time
                compareTimes(dayOfWeek.begin, index);
                //find end time
                compareTimes(dayOfWeek.end, index, 'end');
              })
            })
          }

          //render grid
          this.renderGrid();


          resolve();
          
        }, (e)=>{
          reject();
          this.loadState.stateError();
          console.error(e.response.data.message);
        })   

      });
      loadingData.push(comPlaces);


      Promise.all(loadingData)
      .then(() => {

        //widjet
        let totalHours = 0;
        let totalUserSpecPlanHours = 0;

        // this.comPlaces.map(cp => cp.comPlaceLoad).forEach(i=>{
        //   totalHours += i.totalHours
        //   totalUserSpecPlanHours += i.totalUserSpecPlanHours
        // }); 

        let comRoomIds = [];
        this.comPlaces.forEach(cp=>{
          if (!comRoomIds.includes(cp.comRoom.id)) {//исключаем рабочие места из одной комнаты
            totalHours += cp.comPlaceLoad.totalHours 
            comRoomIds.push(cp.comRoom.id);
          }  
          totalUserSpecPlanHours += cp.comPlaceLoad.totalUserSpecPlanHours
        }); 

        let userSpecPlansHoursSum = 0; 
        if(this.filter.usersSelected.length>0) {              
          this.events.forEach(e=>{
            userSpecPlansHoursSum += moment(e.end).diff(moment(e.begin),'h');
          })
        }

        this.indicators.comPlaceLoadPercent =  totalUserSpecPlanHours!==0 ? Math.round(totalUserSpecPlanHours/totalHours * 100) : 0;
        this.indicators.comPlaceLoadHoursSum = totalUserSpecPlanHours;
        this.indicators.userSpecPlansHoursSum = userSpecPlansHoursSum;
        this.indicators.comPlaceLoadPercentByUser = userSpecPlansHoursSum !==0 ? Math.round(userSpecPlansHoursSum/totalHours * 100) : 0;


        this.loadState.stateSuccess();
        this.noFade = false;
      })
      .catch((error) => {
        console.error('Error:', error);
      });

      }, 300);
    },
    renderGrid(){
      this.week = [];
      const daysOfWeek = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']; // массив с днями недели

      for (let i = 0; i < this.dateRange; i++) {   
          let day = {};
          
          let date = moment(this.beginDate).add(i, 'd');
          let dayOfWeek = date.format('ddd');
          
          day.date = date.format('DD-MM-YYYY');
          day.dateYMD = date.format('YYYY-MM-DD');
          day.day = dayOfWeek;  
          
          // Определение индекса дня недели для weekTimeGrid
          let dayIndex = daysOfWeek.indexOf(dayOfWeek);
          
          // Если индекс дня найден (от 0 до 6), то вызываем соответствующее время
          if (dayIndex !== -1) {
              day.time = this.weekTimeGrid(this.workingHours, dayIndex);
          }

          this.week.push(day);
      }
    },
    weekTimeGrid(time, index) {
      const WorkingHours = [];
      const startTime = moment(time[index].begin, "HH:mm");
      const endTime = moment(time[index].end, "HH:mm");
      let currentTime = startTime.clone();

      WorkingHours.push(startTime.format("HH:mm"));
      while (currentTime.isBefore(endTime)) {
        currentTime.add(this.step, 'minutes');
        
        if (currentTime.format('HH:mm') === '00:00') {
          currentTime = moment('23:59', 'HH:mm');
        }

        WorkingHours.push(currentTime.format("HH:mm"));
      }

      return WorkingHours;
    },
    setEndDay() {
      this.endDate = moment(this.beginDate).add(this.dateRange-1, 'd').format('YYYY-MM-DD');
    },
    setDaysInMonth() {
      // если выбрали первое число и диапазон месяц, то устанавливает количество дней из текущего месяца
      if (this.dateRange>14) {
        if (moment(this.beginDate).date() === 1) {
          this.dateRange = moment(this.beginDate).daysInMonth(); 
        } else if (moment(this.beginDate).date() !== 1) {
          this.dateRange = 31
        }
        this.currentMonthLength = this.dateRange;
      }
    },
    userSpecPlanUpdateTime(event,load=true){    
      this.eventValidation(event);

      if(this.dayTimeValidation) {
        this.noFade = true;
        this.loadState.stateLoading();
        axios.put(Api.host+'/userSpecPlan/'+event.id+'/', {
          begin:moment(event.begin).format('YYYY-MM-DD HH:mm:00'),
          end:moment(event.end).format('YYYY-MM-DD HH:mm:00'),
          comPlace: event.comPlace.id
        })
        .then((r) =>{
          this.loadState.stateSuccess();
          if(this.userSpecPlanState.isModalOpen)
            this.userSpecPlanState.modalOpen(r.data);
          if (load)
            this.load();
        })
        .catch(e =>{
          this.$store.commit('alertError', e.response.data.message);
          this.load();
        });
      }
    },
    userSpecCopyEvent(event){
      this.eventValidation(event);

      if(this.dayTimeValidation) {
        this.noFade = true;
        this.loadState.stateLoading();
        axios.post(Api.host+'/userSpecPlan/', {
          comPlace: event.comPlace.id,
          userSpec:event.userSpec.id,
          begin:moment(event.begin).format('YYYY-MM-DD HH:mm:00'),
          end:moment(event.end).format('YYYY-MM-DD HH:mm:00'),
          type:event.type,
          gridMinutes:event.gridMinutes,
          userSpecCommentAdmin:event.userSpec.commentAdmin
        })
        .then(()=> {
          this.$store.commit('alertSuccess', 'План работы создан');
          this.load();
        })
        .catch((e)=> {
          this.$store.commit('alertError', e.response.data.message);
          this.load();
        });
      }
    },
    userSpecPlanNew({ category, date, time }){
      let t = moment(date+' '+time, 'YYYY-MM-DD HH:mm');
      let beginTime = t.clone().minute(t.minute() - (t.minute() % 5)).toDate();
      let endTime = t.clone().minute(t.minute() - (t.minute() % 5)).add(5, 'minutes').toDate();

      let valid = true;
      let startBlock = 0;
      let endBlock = 0;
      this.events.forEach((value)=>{
        if (value.type!=='userSpecPlanBlank'){
          startBlock = value.start.getTime();
          endBlock = value.end.getTime();
          if (value.category===category.id){
            if (beginTime<startBlock && endTime>startBlock)
              valid = false;
            if (beginTime>=startBlock && endTime<=endBlock)
              valid = false;
            if (beginTime<endBlock && endTime>endBlock)
              valid = false;
          }
        }
      });

      if(valid){
        this.userSpecPlanNewRemove();
        this.events.push({
          start: beginTime,
          end: endTime,
          color: 'primary',
          timed: true,
          category: category.id,
          comPlace: category,
          type:'userSpecPlanNew'
        });
      }
    },
    userSpecPlanNewRemove(){
      this.events = this.events.filter(v=>v.type!=='userSpecPlanNew');
    },
    copy(){
      this.copyState.stateLoading();

      let comBranchs = [];
      this.copyState.modalData3.forEach(branch=>{
        comBranchs.push(branch.id);
      })
      axios.post(Api.host+'/userSpecPlan/copy/', {
        fromBegin:this.copyState.modalData1[0],
        fromEnd:this.copyState.modalData1[this.copyState.modalData1.length-1],
        toBegin:this.copyState.modalData2,
        comBranchIds: comBranchs.join(', ')
      })
        .then((r) =>{
          this.$store.commit('alertSuccess', r.data.message);
          this.copyState.modalClose()
          this.copyState.stateSuccess();
          this.copyState.modalData2 = [];
          //this.beginDate = this.copyState.modalData2;
          this.load();
        })
        .catch(e =>{
          this.copyState.stateError(e);
        });
    },
    mouseMove($event, day, comPlace) {
      let x = $event.pageX
      let cursorOffset = Math.ceil(x - $event.target.getBoundingClientRect().left)

      //tooltip style
      this.tooltipStyle = {
        transform: 'translate3d('+cursorOffset+'px, 0, 0)',
        opacity: 1
      }

      this.selectedDate = day.dateYMD
      this.selectedPlace = comPlace  

      //current work day begin/end time
      this.selectedWorkDayBegin = day.time[0]; 
      this.selectedWorkDayEnd = day.time.slice(-1)[0];


      let zoom = 1;
      if (this.zoom>1) {
        zoom = 4;
      }

      if (cursorOffset % 5 === 0)  {
        this.selectedTime = moment(this.selectedWorkDayBegin, "HH:mm").add(cursorOffset*zoom,'m').format("HH:mm");
      }

      if(this.eventDrag.length!==0){ 
        this.handleEventDrag()
      } else  if(this.eventStretching.length!==0){ 
        this.handleEventStretching()
      }  else if (this.eventCopy!==null){
        this.handleEventCopy()
      }
    },
    //drag and drop
    handleEventDrag(){
        let duration = moment(this.eventDrag[0].end).diff(this.eventDrag[0].begin, 'minutes');
        let beginTime = moment(this.selectedDate+' '+this.selectedTime, 'YYYY-MM-DD HH:mm');
        let endTime = moment(beginTime).add(duration, 'minutes');

        this.eventDrag[0].comPlace = this.selectedPlace;
        this.eventDrag[0].begin = beginTime.toDate();
        this.eventDrag[0].end = endTime.toDate();
    },
    startDrag(event){
      let index = this.events.findIndex(item => item.id === event.id);
      delete this.events.splice(index, 1);
      this.eventDrag.push(event);

    },
    finishDrag(event){
      this.events.push(event);
      this.eventDrag = [];
      this.userSpecPlanUpdateTime(event,false); 
    },
    //stretchiing
    handleEventStretching(){
      const maxTime = moment(this.eventStretchingDay.dateYMD+' '+this.eventStretchingDay.time.slice(-1)[0], 'YYYY-MM-DD HH:mm')
      if (maxTime.diff(moment(this.selectedDate+' '+this.selectedTime, 'YYYY-MM-DD HH:mm'), 'minutes')>=0) {
          this.eventStretching[0].end = moment(this.selectedDate+' '+this.selectedTime, 'YYYY-MM-DD HH:mm').toDate();
      } else {
        this.eventStretching[0].end = maxTime.toDate();
      }
    },
    startStretching(event,day){
      let index = this.events.findIndex(item => item.id === event.id);
      delete this.events.splice(index, 1);

      this.eventStretchingDay = day;
      this.eventStretching.push(event);
    },
    finishStretching(event){
      this.events.push(event);
      this.eventStretching = [];
      this.eventStretchingDay = null;
      this.userSpecPlanUpdateTime(event,false); 
    },
    //copy
    handleEventCopy(){
      if (this.eventCopy&&this.keyPressed === 'Control') {
        let duration = moment(this.eventCopy.end).diff(this.eventCopy.begin, 'minutes');
        let beginTime = moment(this.selectedDate+' '+this.selectedTime, 'YYYY-MM-DD HH:mm');
        let endTime = moment(beginTime).add(duration, 'minutes');


        this.eventCopy.comPlace = this.selectedPlace;
        this.eventCopy.begin = beginTime.toDate();
        this.eventCopy.end = endTime.toDate();

      } else if (this.eventCopy&&this.keyPressed === 'Alt') { //same time      
        let originalTimeBegin = this.$tools.dateTimeFormat(this.eventCopy.begin, 'HH:mm');
        let originalTimeEnd= this.$tools.dateTimeFormat(this.eventCopy.end, 'HH:mm');
        let beginTime = moment(this.selectedDate+' '+originalTimeBegin, 'YYYY-MM-DD HH:mm');
        let endTime = moment(this.selectedDate+' '+originalTimeEnd, 'YYYY-MM-DD HH:mm');

        this.eventCopy.comPlace = this.selectedPlace;
        this.eventCopy.begin = beginTime.toDate();
        this.eventCopy.end = endTime.toDate();
      }
    },
    startCopy($event,event) {
      if(this.keyPressed){
        $event.preventDefault()
        $event.stopPropagation()
        this.eventCopy = this.$tools.copy(event);
      }
    },
    finishCopy(){
      this.userSpecCopyEvent(this.eventCopy); 
    },
    //validation
    eventValidation(event){
      const beginTime = event.begin
      const endTime = event.end
      const comPlace = event.comPlace
      const workDayDateTimeEnd = moment(this.selectedDate+' '+this.selectedWorkDayEnd, 'YYYY-MM-DD HH:mm')

      //between day
      this.dayTimeValidation = true
      if (workDayDateTimeEnd.diff(moment(endTime), 'minutes')<0) {
        this.dayTimeValidation = false
      }

      //between event
      this.events.filter(event => event.comPlace.id === comPlace.id).forEach(event =>{
        let eventBegin = moment(event.begin)
        let eventEnd = moment(event.end)

        //если сопадает рабочее место и пересекается время
        if (event.comPlace.id===comPlace.id
        &&(
          moment(beginTime).isBetween(eventBegin,eventEnd)
          ||moment(endTime).isBetween(eventBegin,eventEnd)
          ||eventBegin.isBetween(beginTime,endTime)
          ||eventEnd.isBetween(beginTime,endTime)
        )) {
          this.dayTimeValidation = false
        }
      
      })
      
      if (this.dayTimeValidation == false) {
        this.load()
        this.$store.commit('alertError', 'Ошибка перемещения, время недоступно');
      }
    },


    onKeyDown(event) {
      if (event.key === 'Control'||event.key === 'Alt') {
        this.keyPressed = event.key;
      }
    },
    onKeyUp(event) {
      if (event.key === 'Control'||event.key === 'Alt') {
        this.keyPressed = false;
        this.eventCopy = null;
      }
    },
    resize(){
      let widjetsH = 0;
      if (this.filter.showWidgets) {
        widjetsH = 125
      }
      this.height = window.innerHeight-64;
      this.calendarHeight = this.height - widjetsH
    },
    setToday () {
      this.beginDate = moment(new Date()).format('YYYY-MM-DD');
      this.load();
    },
    hideTooltip() {
        this.tooltipStyle = {
          opacity: 0
        }
    },
    calendarScroll(event) {
      const scrollX = event.target.scrollLeft;
      this.xScroll = scrollX;
    },
    scrollToStart() {
      if(this.$refs.scrollContainer) {
        this.$refs.scrollContainer.scrollTo({
          left: 0,
          behavior: 'smooth'
        });
      }
    },
    updateZoom(action) {
      if (action === "add") {
        this.zoom = this.zoom % 2 === 0 ? this.zoom + 2 : this.zoom + 1;
      } else if (action === "subtract") {
          if (this.zoom <= 2) 
          this.zoom = 1;
          else 
          this.zoom = this.zoom % 2 === 0 ? this.zoom - 2 : this.zoom - 1;
      }
    },
    // eventsRowShow(comPlace) {
    //   if (this.usersSelected.length===0&&this.specsSelected.length==0)
    //     return true
    //   else if ((this.usersSelected.length>0||this.specsSelected.length>0)&&this.events.filter(e=>e.comPlace.id===comPlace.id).length>0)
    //     return true
    //   else
    //     return false
    // },
    queryArray(items,key='id') {
      let arr = [];
      items.forEach(s=>{
        arr.push(s[key]);
      });
      return arr;
    }
  }
}
</script>

<style lang="scss" scoped>
  $indent: 200px;
  $grey:#e0e0e0;
  $border: #e0e0e0 1px solid;
  $cell: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAABGCAYAAADb7SQ4AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LmRhYmFjYmIsIDIwMjEvMDQvMTQtMDA6Mzk6NDQgICAgICAgICI+IDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCAyMi40IChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0MUI2QTBBQzBDRjcxMUVEOUE5NEJDNENFM0IxOUU2OCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0MUI2QTBBRDBDRjcxMUVEOUE5NEJDNENFM0IxOUU2OCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjQxQjZBMEFBMENGNzExRUQ5QTk0QkM0Q0UzQjE5RTY4IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjQxQjZBMEFCMENGNzExRUQ5QTk0QkM0Q0UzQjE5RTY4Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+5I7JhQAAAEVJREFUeNrszUEBACAMBKCbRda/1ZpoC30IBaiZ2Umqu3PTyiNisVgsFovFYrFYLBaLxWKxWCwWi8VisVgsFov/iI8AAwAdNQSJa6ulbwAAAABJRU5ErkJggg==') top left/ 30px 100% repeat-x;
  %separator {
    content: "";
    position: absolute;      
    transform: translate3d(-1px, 0, 0);
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: 3px 0px 0px 0px #ffc10765;
    z-index: 9;
  }
  %cell-size {
    width: 30px;
  }
  .work-schedule {
    background: #ffffff;
    padding: 1rem;
    overflow-x: auto;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    &__dates-row {
      margin-left: $indent;
      width: 100%;
      display: flex;
    }
    &__weekday {
      // border: $border;
      position: relative;
      border-bottom: none;
      display: inline-flex;
      flex-direction: column;
      text-align: center;
      &::after {
        @extend %separator;
      }
    }  
    &__day-of-week {
        // border-top: $border;
        // border-left: $border;
        position: relative;
        height: 25px;
    }
    &__date-wrapper {
      position: absolute;
      inset: 0;
      z-index: 999;
      white-space: nowrap;
      overflow: hidden;

      &-day {
        font-weight: bold;
        margin-right: .5rem;
        display: inline-block;
        background: #ffffff;
      }
      &-date { 
        white-space: nowrap;
        display: inline-block;
        background: #ffffff;
      }
    }
    &__hours {
      display: flex;
      background: $cell;     
      border-top: 1px solid #e0e0e0;  
    }
    &__cell {
      width: 30px;
      height: 70px;
      writing-mode: vertical-rl;
      font-size: .75rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;
      transform: translate3d(-15px, 0px, 0px);
      z-index: 9;
      // &:first-child {
      //   color: #ffc107;
      // }
      &:nth-child(2n) {
        font-size: .625rem;
        font-weight: 300;
      } 
      &::before{
        content: '';
        position: absolute;
        background: #ffffff;
        width: 100%;
        height: 80%;
        left: 0;
        top: 0;
        z-index: -1;
      }
      &:last-child {
        border-right: none;
      }
      &_zoom-enabled {
        width: 15px;
        transform: translate3d(-7.5px, 0px, 0px);
        color: #ffffff;
        &:nth-child(2n) {
          color: #000000;
        }
        // &:first-child {
        //   color: #000000;
        // }
      }
    }
    &__schedule-rows {
      display: inline-flex;
      flex-direction: column;
    }
  }
  .schedule-row:last-child {
    border-bottom:1px solid $grey
  }
  .sidebar-collapse-btn {
     position: absolute;
     z-index: 9;
     left: 90px;
     top: 30px;
  }
</style>