<template>
  <layout-view>
    <template v-slot:content>
      <progress-page v-if="state.isLoading"/>
      <div v-else class="ma-3">
        <table class="tableBordered">
          <tr>
            <td rowspan="2"><finance-standart-header name="Хозяйственная операция"/></td>
            <td rowspan="2"><finance-standart-header name="Аналитика"/></td>
            <td rowspan="2"><finance-standart-header name="Операция"/></td>
            <td colspan="2"><finance-standart-header name="Дебет"/></td>
            <td colspan="2"><finance-standart-header name="Кредит"/></td>
          </tr>
          <tr>
            <td><finance-standart-header name="Счет"/></td>
            <td><finance-standart-header name="Аналитика"/></td>
            <td><finance-standart-header name="Счет"/></td>
            <td><finance-standart-header name="Аналитика"/></td>
          </tr>
          <tr v-for="item in items" :key="item.id">
            <td class="pl-2"><chip-tooltip-text :text="item.name" :info="'#'+item.id"/></td>
            <td>
              <chip-tooltip-text v-for="(v, i) in item.analyticTypes" :key="i" :text="v.name" :info="'#'+v.id" chipClass="mr-1 mb-1"/>
            </td>
            <td class="pa-0" colspan="13">
              <table class="tableBordered1" v-for="operationType in item.operationTypes" :key="operationType.id">
                <tr>
                  <td><chip-tooltip-text :text="operationType.name" :info="'#'+operationType.id"/></td>
                  <td><chip-tooltip-text :text="operationType.accountPlanDebet.sid" :info="'#'+operationType.accountPlanDebet.id"/></td>
                  <td>
                    <chip-tooltip-text v-for="v in operationType.accountPlanDebet.analyticTypes" :key="v.id" :text="v.name" :info="'#'+v.id" chipClass="mb-2 mr-2"/>
                  </td>
                  <td><chip-tooltip-text :text="operationType.accountPlanCredit.sid" :info="'#'+operationType.accountPlanCredit.id"/></td>
                  <td>
                    <v-sheet min-height="100">
                      <chip-tooltip-text v-for="v in operationType.accountPlanCredit.analyticTypes" :key="v.id" :text="v.name" :info="'#'+v.id" chipClass="mb-2 mr-2"/>
                    </v-sheet>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </template>
    <template v-slot:footer>
    </template>
  </layout-view>
</template>

<script>
import LayoutView from "@/components/LayoutView";
import ProgressPage from "@/components/ProgressPage.vue";
import {
  Entity_AccountPlan,
  Entity_AnalyticType,
  Entity_OperationGroupType,
  Entity_OperationType
} from "../../../EntityStoreCacheService";
import State from "@/plugins/state";
import ChipTooltipText from "@/componentsV2/base/ChipTooltipText.vue";
import FinanceStandartHeader from "./FinanceStandartHeader.vue";
export default {
  components: {FinanceStandartHeader, ChipTooltipText, ProgressPage, LayoutView},
  data: () => ({
    state: new State(),
    items:[]
  }),
  mounted() {
    new Entity_OperationGroupType()
        .selects(Entity_OperationGroupType.id)
        .selects(Entity_OperationGroupType.name)

        .selects([Entity_OperationGroupType.analyticTypes, Entity_AnalyticType.id].join('.'))
        .selects([Entity_OperationGroupType.analyticTypes, Entity_AnalyticType.name].join('.'))

        .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.id].join('.'))
        .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.name].join('.'))

        .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.accountPlanCredit, Entity_AccountPlan.id].join('.'))
        .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.accountPlanCredit, Entity_AccountPlan.sid].join('.'))

        .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.accountPlanCredit, Entity_AccountPlan.analyticTypes, Entity_AnalyticType.id].join('.'))
        .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.accountPlanCredit, Entity_AccountPlan.analyticTypes, Entity_AnalyticType.name].join('.'))

        .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.accountPlanDebet, Entity_AccountPlan.id].join('.'))
        .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.accountPlanDebet, Entity_AccountPlan.sid].join('.'))

        .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.accountPlanDebet, Entity_AccountPlan.analyticTypes, Entity_AnalyticType.id].join('.'))
        .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.accountPlanDebet, Entity_AccountPlan.analyticTypes, Entity_AnalyticType.name].join('.'))

        .order(Entity_OperationGroupType.id, true)
        .page(1)
        .onPage(1000)
        .addStateCallback((v)=> this.state.setRequestBuilderState(v))
        .addStateCallback((v)=>{
          if(v.success){
            this.items = v.items;
            this.items.forEach(v=>{
              v.analyticTypes = v.analyticTypes.sort((a, b) => a.name.localeCompare(b.name))
              v.operationTypes.forEach(v1=>{
                v1.accountPlanCredit.analyticTypes = v1.accountPlanCredit.analyticTypes.sort((a, b) => a.name.localeCompare(b.name))
                v1.accountPlanDebet.analyticTypes = v1.accountPlanDebet.analyticTypes.sort((a, b) => a.name.localeCompare(b.name))
              })
            })
          }
        })
        .request()
  }
}
</script>

<style scoped lang="scss">
.tableBordered {
  border-collapse: collapse;
  td{
    border: 1px solid #CCC;
    font-size: 14px;
    padding: 10px;
    min-width: 250px;
    width: 250px;
    max-width: 250px;
    text-align: left;
  }
  .tableBordered1{
    border-collapse: collapse;
    margin-left: -1px;
    margin-bottom: -1px;
    td{
      border-left: none;
      border-top: none;
      height: initial !important;
    }
  }
}
</style>