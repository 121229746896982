<template>
  <div class="d-flex justify-center box">
    <div class="d-flex align-center">
      <img :src="logo" width="300" class="logo"/>
      <v-card width="300" :color="statusState.isLoading ? 'transparent' : ''" :elevation="statusState.isLoading ? 0 : ''">
        <v-progress-circular v-if="statusState.isLoading" indeterminate color="primary" size="32" class="center"/>
        <v-form :class="statusState.isLoading ? 'o-0' : ''" @submit.prevent="login">
          <v-toolbar elevation="0" color="transparent" dense>
            <v-toolbar-title>Войти</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-alert v-if="errorMessage!==null" dense type="error" class="caption">{{errorMessage}}</v-alert>
            <v-text-field v-model="phone" class="mb-4" label="Телефон" outlined dense hide-details="auto" v-mask="'+7 (###) ###-##-##'"/>
            <v-text-field v-model="pass" type="password" label="Пароль" outlined dense hide-details="auto"/>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text color="primary" type="submit" :loading="loginState.isLoading">Войти</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>

import State from "@/plugins/state";
import Api from "@/Api";
import axios from "axios";
import Session from "@/plugins/session";

export default {
  data: () => ({
    logo: require('@/assets/logo-mediana.svg'),
    statusState: new State(),
    loginState: new State(),
    errorMessage: null,
    phone:null,
    pass:null,
  }),
  mounted() {
    if (this.$store.state.user===null){
      this.statusState.stateLoading();
      Api.service.user.status((d)=>{
        if (typeof d.message!=='undefined'){
          this.statusState.stateError();
          this.errorMessage = d.message;
        } else {
          this.statusState.stateSuccess();
          if (typeof d.id!=='undefined'){
            this.$store.state.user = d;
          }
        }
      })
    }
  },
  methods: {
    login(){
      this.loginState.stateLoading();
      let formData = new FormData();
      formData.append("phone", this.phone);
      formData.append("pass", this.pass);
      axios.post(Api.host+'/user/login/', formData, {withCredentials: true})
          .then(r=>{
            if (typeof r.data.id!=='undefined'){
              this.loginState.stateLoadingSecond();

              if(typeof r.headers["x-jwt-token"]!=='undefined')
                Session.update(r.headers["x-jwt-token"]);
              this.$store.state.session = Session.read();
              //old start
              this.$store.state.user = r.data;
              //old end
            }
            if (typeof r.data.message!=='undefined'){
              this.loginState.stateError();
              this.errorMessage = r.data.message;
            }
          })
          .catch(() =>{
            this.loginState.stateError();
          });
    }
  }
}
</script>


<style type="scss" scoped>
.box{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.center{
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -16px 0 0 -16px;
}
.logo {
  position: absolute;
  top:5vw;
  left: 5vw;
}
</style>