<template>
  <dialog-view
      :open="referrerId!=null||referrerCreate"
      :title="referrerId!=null ? 'Направляющий #'+referrerId : 'Создать направляющего'"
      :loading="loadState.isLoading"
      mid
      contentFullSize @cancel="close()"
  >
    <template v-slot:barActions>
      <div ref="barAction">
        <!-- <log entity-name="referrer" :entity-id="referrerId" logClass="mr-2"/> -->
        <dialog-view
            v-if="referrerId!=null"
            :title="'Удалить Направляющего #'+referrerId+'?'"
            acceptName="Удалить"
            @acceptAction="referrerDelete()"
            :loading="deleteState.isLoading"
        >
          <template v-slot:activator>
            <v-btn icon><v-icon>mdi-trash-can-outline</v-icon></v-btn>
          </template>
        </dialog-view>
      </div>
    </template>

    <template v-slot:actions>
      <v-btn @click="referrerCreateOrUpdate()" color="primary" text type="submit" :loading="loadState.isLoading" :disabled="referrer&&(!referrer.name || referrer?.name.trim() === '')">Сохранить</v-btn>
    </template>

    <template v-slot:header>

    </template>

    <template v-slot:content>

      <div v-if="referrer&&loadState.isSuccess" class="px-3 py-5">
        <text-field name="Имя" :value.sync="referrer.name" required/>
        <text-field name="Телефон" :value.sync="referrer.phone"/>
        <text-field name="Номер контракта" :value.sync="referrer.contractNumber"/>
        <user-autocomplete-single name="Выбрать клиента" :select.sync="referrer.user"/>
        <text-area-field name="Комментарий" :value.sync="referrer.comment" autoGrowDisabled :rows="2"/>
        <checkbox-field name="Активен" :value.sync="status"/>
      </div>

    </template>
  </dialog-view>

</template>

<script>
import Api from "@/Api";
import axios from "axios";
import State from "@/plugins/state";
import DialogView from "@/components/DialogView";
//import Log from "@/views/Log/Log";
import TextField from "@/componentsV2/base/TextField.vue";
import UserAutocompleteSingle from "@/componentsV2/custom/UserAutocompleteSingle.vue";
import TextAreaField from "@/componentsV2/base/TextAreaField.vue";
import CheckboxField from "@/componentsV2/base/CheckboxField.vue";

import {
  Entity_Referral, 
  Entity_User, 
} from "../../../../EntityStoreCacheService";

export default {
  components: {
    DialogView,
    //Log,
    TextField,
    UserAutocompleteSingle,
    TextAreaField,
    CheckboxField
  },
  props: ['referrerId','referrerCreate'],
  data: () => ({
    referrer:null,
    loadState:new State(),
    deleteState:new State(),
    status: true,

  }),
  mounted(){
    this.emptyReferrer();
    this.loadState.stateSuccess();
  },
  watch: {
    referrerId() {
      if (this.referrerId!=null) {
        this.emptyReferrer();
        this.load();
      }  
    },
    referrerCreate() {
      this.emptyReferrer();
    }
  },
  methods: {
    emptyReferrer() {
      this.referrer = {
        name:null,
        phone:null,
        user:null,
        comment: null,
        status: false,
        contractNumber: null
      }
    },
    load() {
      this.loadState.stateLoading();
      let rb = new Entity_Referral()
        .selects(Entity_Referral.comment)
        .selects(Entity_Referral.contractNumber)
        .selects(Entity_Referral.phone)
        .selects(Entity_Referral.id)
        .selects(Entity_Referral.name)
        .selects(Entity_Referral.status)
        .selects(Entity_Referral.user)


        //user
        .selects([Entity_Referral.user, Entity_User.id].join('.'))
        .selects([Entity_Referral.user, Entity_User.firstName].join('.'))
        .selects([Entity_Referral.user, Entity_User.lastName].join('.'))
        .selects([Entity_Referral.user, Entity_User.secondName].join('.'));

        rb.filterAnd([Entity_Referral.entity, Entity_Referral.id].join('.') + ' = ?', this.referrerId)
        
        rb.request((data)=>{ 
            this.status = data.items[0].status === 'active' ? true : false
            this.referrer = data.items[0];
            this.loadState.stateSuccess();
          }, (e)=>{
        console.error(e.response.data.message);
        this.loadState.stateError(e)
      })
    },
   
    close(){
      this.$emit('update:referrerId', null);
      this.$emit('update:referrerCreate', false);
      setTimeout(()=>{
        this.referrer = null;
      }, 250);
    },

    referrerCreateOrUpdate(){
      this.loadState.stateLoading();
      this.referrer.status = this.status ? 'active' : 'unactive';
      if (this.referrerCreate) {
        axios.post(Api.host+'/referral/', this.referrer)
        .then(()=>{
          this.close();
          this.$emit('update');
          this.loadState.stateSuccess();
        }).catch(() =>{
          this.loadState.stateError();
        });
      } else {      
        axios.put(Api.host+'/referral/'+this.referrerId+'/', this.referrer)
        .then(()=>{
          this.close();
          this.$emit('update');
          this.loadState.stateSuccess();
        }).catch(() =>{
          this.loadState.stateError();
        });
      }
    },
    referrerDelete() {
      this.deleteState.stateLoading();
      axios.delete(Api.host+'/referral/'+this.referrerId+'/',{withCredentials: true})
        .then(()=>{
          this.close();
          this.$emit('update');
          this.deleteState.stateSuccess();
        })
        .catch(e => {
          console.error(e.response.data.message);
          this.deleteState.stateError();
        });
    }

  }
}
</script>