<template>
  <div>
    <!-- basic types -->
    <div v-if="type==='integer' || type==='text'">{{$tools.isEmpty(value) ? '' : value}}</div>
    <div v-if="type==='boolean'">
      <v-icon v-if="value" small color="grey lighten">mdi-check-circle</v-icon>
    </div>
    <div v-if="type==='dateTime'">{{$tools.dateTime(value)}}</div>
    <div v-if="type==='price'">{{$tools.price(value)}}</div>
    <div v-if="type==='comment'">
      <tooltip-view v-if="!$tools.isEmpty(value)" bottom :content="value">
        <template v-slot:action>
          <v-sheet color="transparent" max-width="300" class="text-overflow">
            {{value}}
          </v-sheet>
        </template>
      </tooltip-view>
    </div>

    <div v-if="type==='analyticTypes'">
      <chip v-for="(v,k) in item.analyticTypes" :key="k" class="mr-2" small>{{v.name}}</chip>
    </div>

    <!-- specified types -->
    <div v-if="type==='operationGroupType' && value!==null">
      <v-sheet color="transparent" width="270">
        <v-row no-gutters>
          <v-col class="text-wrap">
            {{$tools.isEmpty(value.name) ? '' : value.name}}
          </v-col>
          <v-col v-if="!$tools.isEmpty(item.comment)" cols="auto">
            <tooltip-view bottom :content="item.comment">
              <template v-slot:action>
                <v-icon small color="grey" class="mr-2">mdi-comment-text-outline</v-icon>
              </template>
            </tooltip-view>
          </v-col>
          <v-col cols="12">
            <tooltip-view v-if="item.fixedBy!==null" bottom :content="'Хоз. операция: #'+item.fixedBy.id+' от '+$tools.dateTime(item.fixedBy.created)">
              <template v-slot:action>
                <div class="red--text"><v-icon small color="red" class="mr-2">mdi-arrow-u-down-right</v-icon>Сторнировано</div>
              </template>
            </tooltip-view>
            <tooltip-view v-if="item.fixed!==null" bottom :content="'Хоз. операция: #'+item.fixed.id+' от '+$tools.dateTime(item.fixed.created)">
              <template v-slot:action>
                <div class="red--text"><v-icon small color="red" class="mr-2">mdi-arrow-u-up-right</v-icon>Сторнирует</div>
              </template>
            </tooltip-view>
          </v-col>
        </v-row>
      </v-sheet>
    </div>
    <div v-if="type==='operationGroupAnalytics'" class="ml-n2">
      <div v-for="(v,k) in operationGroupAnalyticsCollect(item)" :key="k" class="d-inline-block ml-2">
        <v-sheet color="grey lighten-4" class="d-inline-block px-2 py-1" rounded width="180">
          <tooltip-view bottom>
            <template v-slot:action>
              <div class="caption text-overflow">{{v.name}}</div>
              <div class="text-overflow">{{v.valueName}}</div>
            </template>
            <template v-slot:content>
              <v-row no-gutters class="mb-2">
                <v-col class="pr-2">
                  <div class="caption">Значение</div>
                  <div class="body-2 mb-2" v-html="$tools.nl2br(v.valueName)"></div>
                  <div v-if="!$tools.isEmpty(v.value)" class="caption">Значение альяс:</div>
                  <div v-if="!$tools.isEmpty(v.value)" class="body-2 mb-2">{{v.value}}</div>
                </v-col>
              </v-row>
            </template>
          </tooltip-view>
        </v-sheet>
      </div>
    </div>
    <div v-if="type==='operationGroupOperations'" class="ml-n2">
      <div v-for="(v,k) in operationGroupOperations(item)" :key="k" class="ml-2 d-inline-block">
        <tooltip-view bottom>
          <template v-slot:action>
            <v-sheet color="grey lighten-4" class="d-inline-block px-2 py-1" rounded width="210">
              <div class="caption text-overflow">{{v.name}}</div>
              <div class="text-overflow">{{$tools.price(v.amount)}}</div>
            </v-sheet>
          </template>
          <template v-slot:content>
            <v-row no-gutters class="mb-2">
              <v-col class="pr-2">
                <div class="caption">{{v.name}}</div>
                <div class="body-2">Скидка: {{$tools.price(v.discount)}}</div>
                <div class="body-2">Итого: {{$tools.price(v.amount)}}</div>
              </v-col>
            </v-row>
          </template>
        </tooltip-view>
      </div>
    </div>
    <div v-if="type==='operationGroupOperationsByPayType'">
      <div v-for="(v,k) in operationGroupOperationsByPayType(item)" :key="k" class="d-inline-block">
        <div v-if="FinanceApi.payTypes.filter(v1=>v1.id===v.payType).length===0">Не указано</div>
        <tooltip-view bottom>
          <template v-slot:action>
            <div v-for="item1 in FinanceApi.payTypes.filter(v1=>v1.id===v.payType)" :key="item1.id">
              <v-icon v-if="!$tools.isEmpty(item1.icon)" :color="item1.color" small class="mr-2 mt-n1 d-inline-block">{{item1.icon}}</v-icon>{{item1.name}}
              <dot v-if="k<operationGroupOperationsByPayType(item).length-1"/>
            </div>
          </template>
          <template v-slot:content>
            <v-row no-gutters class="mb-2">
              <v-col class="pr-2">
                <div class="body-2">Скидка: {{$tools.price(v.discount)}}</div>
                <div class="body-2">Итого: {{$tools.price(v.amount)}}</div>
              </v-col>
            </v-row>
          </template>
        </tooltip-view>
      </div>
      <dot v-if="item.fixedBy!==null || item.fixed!==null"/>
      <tooltip-view v-if="item.fixedBy!==null" bottom :content="'Хоз. операция: #'+item.fixedBy.id+' от '+$tools.dateTime(item.fixedBy.created)">
        <template v-slot:action>
          <div class="red--text d-inline-block"><v-icon small color="red" class="mr-2 mt-n1">mdi-arrow-u-down-right</v-icon>Сторнировано</div>
        </template>
      </tooltip-view>
      <tooltip-view v-if="item.fixed!==null" bottom :content="'Хоз. операция: #'+item.fixed.id+' от '+$tools.dateTime(item.fixed.created)">
        <template v-slot:action>
          <div class="red--text d-inline-block"><v-icon small color="red" class="mr-2 mt-n1">mdi-arrow-u-up-right</v-icon>Сторнирует</div>
        </template>
      </tooltip-view>
    </div>
    <div v-if="type==='operationGroupOperationsBySourceType'">
      <div v-for="(v,k) in operationGroupOperationsBySourceType(item)" :key="k" class="d-inline-block">
        <div v-if="FinanceApi.sourceTypes.filter(v1=>v1.id===v.sourceType).length===0">Не указано</div>
        <div v-for="item1 in FinanceApi.sourceTypes.filter(v1=>v1.id===v.sourceType)" :key="item1.id">
          <v-icon v-if="!$tools.isEmpty(item1.icon)" :color="item1.color" small class="mr-2 mt-n1 d-inline-block">{{item1.icon}}</v-icon>{{item1.name}}
          <div v-if="v.sourceType==='card'" class="d-inline-block">
            <div v-for="(v,k) in OperationAnalyticFinder.methods.valueFind('Слип чек', item)" :key="k">
              <tooltip-view>
                <template v-slot:action>
                  <v-icon small class="pa-1">mdi-receipt-text-outline</v-icon>
                </template>
                <template v-slot:content>
                  <div class="caption" v-html="$tools.nl2br(v.valueName)"></div>
                </template>
              </tooltip-view>
            </div>
          </div>
          <dot v-if="k<operationGroupOperationsBySourceType(item).length-1"/>
        </div>
      </div>
    </div>

    <div v-if="type==='operationGroupAnalytics_detail'">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Название</th>
              <th>Значение</th>
              <th>Значение альяс</th>
            </tr>
          </thead>
          <tbody v-for="(v,k) in operationAllAnalyticsCollect(item)" :key="k">
            <tr
                @click="$tools.isEmpty(FinanceApi.analyticValueLinkGenerate(v)) ? null : $tools.openUrl(FinanceApi.analyticValueLinkGenerate(v), true)"
                :class="$tools.isEmpty(FinanceApi.analyticValueLinkGenerate(v)) ? '' : 'clickable'"
            >
              <td>{{v.name}}</td>
              <td>
                <span v-html="$tools.nl2br(v.valueName)"></span>
                <v-icon v-if="!$tools.isEmpty(FinanceApi.analyticValueLinkGenerate(v))" small class="ml-2">mdi-open-in-new</v-icon>
              </td>
              <td>{{v.value}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div v-if="type==='operationAnalytics_detail'">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Название</th>
              <th>Значение</th>
              <th>Значение альяс</th>
            </tr>
          </thead>
          <tbody v-for="(v,k) in operationAnalyticsCollect(item)" :key="k">
            <tr
                @click="$tools.isEmpty(FinanceApi.analyticValueLinkGenerate(v)) ? null : $tools.openUrl(FinanceApi.analyticValueLinkGenerate(v), true)"
                :class="$tools.isEmpty(FinanceApi.analyticValueLinkGenerate(v)) ? '' : 'clickable'"
            >
              <td>{{v.name}}</td>
              <td>
                <span v-html="$tools.nl2br(v.valueName)"></span>
                <v-icon v-if="!$tools.isEmpty(FinanceApi.analyticValueLinkGenerate(v))" small class="ml-2">mdi-open-in-new</v-icon>
              </td>
              <td>{{v.value}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div v-if="type==='operationGroup.fixedBy' && value!==null">
      <v-icon small color="warning lighten">mdi-check-circle</v-icon>
    </div>
    <div v-if="type==='operationGroup.fixed' && value!==null">
      <v-icon small color="warning lighten">mdi-check-circle</v-icon>
    </div>
    <div v-if="type==='operationGroupDiscount'" class="text-right">
      {{$tools.price(operationGroupDiscount(item))}}
    </div>
    <div v-if="type==='operationGroupTotal'" class="text-right">
      {{$tools.price(operationGroupTotal(item))}}
    </div>

    <div v-if="type==='operation_operationType_name'">
      {{value}}
      <tooltip-view v-if="item.operationGroup.fixedBy!==null" bottom :content="'Хоз. операция: #'+item.operationGroup.fixedBy.id+' от '+$tools.dateTime(item.operationGroup.fixedBy.created)">
        <template v-slot:action>
          <div class="red--text"><v-icon small color="red" class="mr-2">mdi-arrow-u-down-right</v-icon>Сторнировано</div>
        </template>
      </tooltip-view>
      <tooltip-view v-if="item.operationGroup.fixed!==null" bottom :content="'Хоз. операция: #'+item.operationGroup.fixed.id+' от '+$tools.dateTime(item.operationGroup.fixed.created)">
        <template v-slot:action>
          <div class="red--text"><v-icon small color="red" class="mr-2">mdi-arrow-u-up-right</v-icon>Сторнирует</div>
        </template>
      </tooltip-view>
    </div>

  </div>
</template>

<script>
import TooltipView from "@/components/TooltipView.vue";
import FinanceApi from "../../views/Finance/FinanceApi";
import Chip from "@/componentsV2/base/Chip.vue";
import Dot from "./Dot.vue";
import OperationAnalyticFinder from "@/componentsV2/custom/OperationAnalyticFinder.vue";

export default {
  components: {Dot, Chip, TooltipView},
  props:['item', 'header'],
  data:()=>({
    type:null,
    value:null,
  }),
  mounted(){
    this.load();
  },
  watch:{
    item(){
      this.load();
    }
  },
  computed: {
    OperationAnalyticFinder() {
      return OperationAnalyticFinder
    },
    FinanceApi() {
      return FinanceApi
    }
  },
  methods:{
    load(){
      this.type = this.header.type;
      this.value = this.dataFromItemByAlias(this.item, this.header.value);
    },
    dataFromItemByAlias(item, alias){
      if(alias==null)
        return null;

      let f = (item1, alias1)=>{
        let data = item1;
        let f = alias1.split('.');
        let i = 0;
        f.forEach(v=>{
          if(data!==null && typeof data[v]!=='undefined'){
            data = data[v];
            i++;
          }
        });
        return f.length===i ? data : null
      };

      if(alias.includes(',')){
        let d = '';
        alias.split(',').forEach(v=>{
          let r = f(item, v);
          if(r!==null)
            d +=f(item, v)+',';
        })
        return d==='' ? null : d.substring(0, d.length-1);
      }

      return f(item, alias);
    },

    //custom
    operationGroupTotal(item){
      let sum = 0;
      item.operations.forEach(v=>{
        sum +=v.amount-v.discount;
      })
      return sum;
    },
    operationGroupDiscount(item){
      let amount = 0;
      item.operations.forEach(v=>{
        amount += parseFloat(v.discount);
      });
      return amount;
    },

    operationGroupAnalyticsCollect(item){
      let arr = [];
      item.analyticValueGroups.forEach(v=>{
        arr.push({
          name:v.analyticType.name,
          sort:v.analyticType.sort,
          valueName:v.valueName,
          value:v.value,
        });
      });
      arr = arr.sort((a,b)=>a.sort===b.sort ? 0 : a.sort>b.sort ? 1 : -1)
      return arr;
    },
    operationAllAnalyticsCollect(item){
      let arr = [];
      item.analyticValueGroups.forEach(v=>{
        arr.push({
          name:v.analyticType.name,
          sort:v.analyticType.sort,
          valueName:v.valueName,
          value:v.value,
        });
      });

      item.operations.forEach(v=>{
        v.analyticValueCredits.forEach(v1=>{
          arr.push({
            name:v1.analyticTypeCredit.name,
            sort:v1.analyticTypeCredit.sort,
            valueName:v1.valueName,
            value:v1.value,
          });
        });
        v.analyticValueDebets.forEach(v1=>{
          arr.push({
            name:v1.analyticTypeDebet.name,
            sort:v1.analyticTypeDebet.sort,
            valueName:v1.valueName,
            value:v1.value,
          });
        });
      });

      arr = arr.sort((a, b) => a.name.localeCompare(b.name));

      let arr1 = [];
      arr.forEach(v=>{
        if(arr1.filter(v1=>v1.name===v.name && v1.valueName===v.valueName && v1.valueName===v.valueName).length===0)
          arr1.push(v);
      });

      return arr1;
    },
    operationAnalyticsCollect(operations){
      let arr = [];

      operations.analyticValueCredits.forEach(v1=>{
          arr.push({
            name:v1.analyticTypeCredit.name,
            sort:v1.analyticTypeCredit.sort,
            valueName:v1.valueName,
            value:v1.value,
          });
        });
      operations.analyticValueDebets.forEach(v1=>{
        arr.push({
          name:v1.analyticTypeDebet.name,
          sort:v1.analyticTypeDebet.sort,
          valueName:v1.valueName,
          value:v1.value,
        });
      });

      arr = arr.sort((a, b) => a.name.localeCompare(b.name));

      let arr1 = [];
      arr.forEach(v=>{
        if(arr1.filter(v1=>v1.name===v.name && v1.valueName===v.valueName && v1.valueName===v.valueName).length===0)
          arr1.push(v);
      });

      return arr1;
    },
    operationGroupOperations(item){
      let arr = [];

      item.operations.forEach(v=>{
        arr.push({
          name:v.operationType.name,
          payType:v.operationType.payType,
          sourceType:v.operationType.sourceType,
          amount:parseFloat(v.amount),
          discount:parseFloat(v.discount),
        });
      });

      arr = arr.sort((a, b) => a.name.localeCompare(b.name));

      let arr1 = [];
      arr.forEach(v=>{
        if(arr1.filter(v1=>v1.name===v.name).length===0){
          arr1.push(v);
        } else {
          arr1.filter(v1=>v1.name===v.name)[0].amount += parseFloat(v.amount)
          arr1.filter(v1=>v1.name===v.name)[0].discount += parseFloat(v.discount)
        }
      });

      return arr1;
    },

    operationGroupOperationsByPayType(item){
      let arr = [];

      item.operations.forEach(v=>{
        arr.push({
          name:v.operationType.name,
          payType:v.operationType.payType,
          sourceType:v.operationType.sourceType,
          amount:parseFloat(v.amount),
          discount:parseFloat(v.discount),
        });
      });

      arr = arr.sort((a, b) => a.payType.localeCompare(b.payType));

      let arr1 = [];
      arr.forEach(v=>{
        if(arr1.filter(v1=>v1.payType===v.payType).length===0){
          arr1.push(v);
        } else {
          arr1.filter(v1=>v1.payType===v.payType)[0].amount += parseFloat(v.amount)
          arr1.filter(v1=>v1.payType===v.payType)[0].discount += parseFloat(v.discount)
        }
      });

      return arr1;
    },

    operationGroupOperationsBySourceType(item){
      let arr = [];

      item.operations.forEach(v=>{
        arr.push({
          name:v.operationType.name,
          payType:v.operationType.payType,
          sourceType:v.operationType.sourceType,
          amount:parseFloat(v.amount),
          discount:parseFloat(v.discount),
        });
      });

      arr = arr.sort((a, b) => a.sourceType.localeCompare(b.sourceType));

      let arr1 = [];
      arr.forEach(v=>{
        if(arr1.filter(v1=>v1.sourceType===v.sourceType).length===0){
          arr1.push(v);
        } else {
          arr1.filter(v1=>v1.sourceType===v.sourceType)[0].amount += parseFloat(v.amount)
          arr1.filter(v1=>v1.sourceType===v.sourceType)[0].discount += parseFloat(v.discount)
        }
      });

      return arr1;
    },


  }
}
</script>
