<template>
  <group :name="name">
    <v-row no-gutters>
      <v-col class="pr-2">
        <date :value.sync="localValueFrom" name="C" class="mt-1 mb-0" :disabled="dateFromDisabled"/>
      </v-col>
      <v-col class="pl-2">
        <date :value.sync="localValueTo" name="По" class="mt-1 mb-0" :disabled="dateToDisabled"/>
      </v-col>
    </v-row>
  </group>
</template>

<script>
import Date from "@/componentsV2/base/Date.vue";
import Group from "@/componentsV2/base/Group.vue";

export default {
  components: {Group, Date},
  props: [
    'name', 'valueFrom','valueTo', 'dateFromDisabled', 'dateToDisabled'
  ],
  data: () => ({
    localValueFrom:null,
    localValueTo:null,
  }),
  mounted() {
    this.localValueFrom = this.valueFrom;
    this.localValueTo = this.valueTo;
  },
  watch:{
    valueFrom() {
      this.localValueFrom = this.valueFrom;
    },
    valueTo() {
      this.localValueTo = this.valueTo;
    },
    localValueFrom(){
      this.$emit('update:valueFrom', this.localValueFrom);
    },
    localValueTo(){
      this.$emit('update:valueTo', this.localValueTo);
    },
  }
}
</script>
