<template>
  <div>
    <div v-if="referrer===null">

        <v-btn
            small depressed
            @click="
            mainState.modalOpen();
            mainState.stateInit();
          ">
          <v-icon small class="pr-2">mdi-magnify</v-icon>Поиск
        </v-btn>
    
    </div>
    <div v-else>
      <div v-if="typeof small === 'undefined'" class="title font-weight-regular mb-1">
        <span>{{referrer.user ? $tools.userFullName(referrer.user) :referrer.name}}</span>
        <v-btn v-if="referrer.user"
            icon depressed :href="'/panel/user/client/'+referrer.user.id+'/'" target="_blank">
          <v-icon :small="typeof small !== 'undefined'">mdi-open-in-new</v-icon>
        </v-btn>
      </div>

      <tooltip v-else right>       
            <span>{{referrer.user ? $tools.userFullName(referrer.user) :referrer.name}}</span>
            <v-btn v-if="referrer.user"
                icon depressed :href="'/panel/user/client/'+referrer.user.id+'/'" target="_blank">
              <v-icon :small="typeof small !== 'undefined'">mdi-open-in-new</v-icon>
            </v-btn>
            <v-btn v-if="typeof small !== 'undefined'" @click="deleteState.modalOpen()"  x-small icon depressed>
              <v-icon small>mdi-trash-can-outline</v-icon>
            </v-btn>             
          <template v-slot:content>
            <call-phone :phone="referrer.user ? referrer.user.phone : referrer.phone" class="mr-2"/>
            <div v-if="!$tools.isEmpty(referrer.comment)" class="my-3">
              <v-icon small>mdi-comment-text-outline</v-icon>
              <div class="caption mt-n5 ml-5">{{referrer.comment}}</div>
            </div>
          </template>
      </tooltip>  

      <div class="font-weight-regular" v-if="typeof small === 'undefined'">

        <call-phone :phone="referrer.user ? referrer.user.phone : referrer.phone" class="mr-2"/>
        
        <div v-if="!$tools.isEmpty(referrer.comment)" class="my-3">
          <v-icon small>mdi-comment-text-outline</v-icon>
          <div class="caption mt-n5 ml-5">{{referrer.comment}}12312</div>
        </div>

        <template v-if="referrer.user?.userProfile">
          <div>
            <!-- <div class="my-2">
              <span class="mr-2"><v-icon class="mr-2 mt-n1" small>mdi-account-outline</v-icon>{{referrer.user.userProfile.gender==='male' ? 'Мужчина' : 'Женщина'}}</span>
              <span class="mr-2" v-if="referrer.user.userProfile.birth!=='Invalid date'"><v-icon class="mr-2 mt-n1" small>mdi-calendar-star</v-icon>{{$tools.date(referrer.user.userProfile.birth)}}</span>
            </div> -->

            <span v-if="!$tools.isEmpty(referrer.user.userProfile.workOrgName)" class="mr-2 d-flex align-center my-2"><v-icon class="mr-2" small>mdi-office-building</v-icon>{{referrer.user.userProfile.workOrgName}}</span>

            <call-phone class="mb-2" v-if="!$tools.isEmpty(referrer.user.userProfile.workOrgPhone)" :phone="referrer.user.userProfile.workOrgPhone"/>
      
          </div>
        </template>
      </div>

      <v-btn  v-if="typeof small === 'undefined'" @click="deleteState.modalOpen()" small class="mt-2" depressed><v-icon small class="pr-2">mdi-trash-can-outline</v-icon>Удалить</v-btn>
    </div>
    <dialog-view
        :open="mainState.isModalOpen"
        :title="mainState.step===0 ? 'Поиск направляющего' : 'Создать направляющего'"
        @cancel="close()"
    >
      <template v-slot:content>
        <div v-if="mainState.isLoading" class="py-4 text-center">
          <v-progress-circular indeterminate color="primary"/>
        </div>
        <div v-if="mainState.step===0">
          <v-text-field
              v-if="mainState.isInit"
              v-model="referrerSearch"
              label="Поиск"
              dense outlined hide-details="auto"
              clearable
              class="mt-1 mb-4"
              hint="Ф.И.О., телефон"
          />
          <div v-if="mainState.isSuccess">
            <div v-if="searchedItems.length>0">
              <v-list class="pa-0 ma-n4">
                <v-list-item v-for="(item, index) in searchedItems" :key="index" link @click="userCreate(item)">
                  <v-list-item-title class="py-2">
                    <h3 class="font-weight-regular">{{item.user ? $tools.userFullName(item.user) : item.name}}</h3>
                    <div v-if="(item.user&&!$tools.isEmpty(item.user.phone))||!$tools.isEmpty(item.phone)" class="font-weight-regular mt-2">
                      <span><v-icon class="mr-2" small>mdi-phone-outline</v-icon>+{{item.user ? item.user.phone : item.phone}}</span>
                    </div>
                    <div v-if="!$tools.isEmpty(item.comment)" class="my-3">
                      <v-icon small>mdi-comment-text-outline</v-icon>
                      <div class="caption mt-n5 ml-5">{{item.comment}}</div>
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </div>
            <div v-else>
              <alert-view icon="mdi-information-outline" text="Не найдено" />
            </div>
          </div>
        </div>
        
        <div v-if="newReferrer" v-show="mainState.step===1 && mainState.isInit" class="px-3 py-5">
          <text-field name="Имя" :value.sync="newReferrer.name" required/>
          <text-field name="Телефон" :value.sync="newReferrer.phone"/>
          <text-field name="Номер контракта" :value.sync="newReferrer.contractNumber"/>
          <user-autocomplete-single name="Выбрать клиента" :select.sync="newReferrer.user"/>
          <text-area-field name="Комментарий" :value.sync="newReferrer.comment" autoGrowDisabled :rows="2"/>
          <checkbox-field name="Активен" :value.sync="status"/>
        </div>

      </template>
      <template v-slot:actions>
        <div v-if="mainState.step===0">
          <div v-if="mainState.isInit">
            <v-btn text @click="search()" color="primary">Поиск</v-btn>
          </div>
          <div v-if="mainState.isSuccess">
            <v-btn
                @click="
                  mainState.stepNext();
                  mainState.stateInit();
                "
                class="mr-3" text color="primary"
            >
              Создать
            </v-btn>
          </div>
        </div>
        <div v-if="mainState.step===1">
          <v-btn text @click="userCreate()" :loading="mainState.isLoading" color="primary" :disabled="newReferrer&&(!newReferrer.name || newReferrer?.name.trim() === '')">Сохранить</v-btn>
        </div>
      </template>
    </dialog-view>
    <dialog-view
        :open="deleteState.isModalOpen"
        title="Удалить направляющего?"
        @cancel="deleteState.modalClose()"
    >
      <template v-slot:actions>
        <v-btn text @click="deleteUserForVisit()" :loading="deleteState.isLoading" color="primary">Удалить</v-btn>
      </template>
    </dialog-view>
  </div>
</template>

<script>

import State from "@/plugins/state";
import axios from "axios";
import Api from "@/Api";
import DialogView from "@/components/DialogView";
import CallPhone from "@/components/CallPhone.vue";
import TextField from "@/componentsV2/base/TextField.vue";
import UserAutocompleteSingle from "@/componentsV2/custom/UserAutocompleteSingle.vue";
import TextAreaField from "@/componentsV2/base/TextAreaField.vue";
import CheckboxField from "@/componentsV2/base/CheckboxField.vue";
import AlertView from "@/components/AlertView.vue";
import Tooltip from "@/componentsV2/base/Tooltip.vue";

import {
  Entity_UserProfile,
  Entity_Referral, 
  Entity_User, 
} from "../../../EntityStoreCacheService";

export default {
  components: {
    CallPhone, 
    DialogView, 
    TextField,
    UserAutocompleteSingle,
    TextAreaField,
    CheckboxField,
    AlertView,
    Tooltip
  },
  props: ['visitId','referrer', 'small'],
  data: () => ({
    mainState: new State(),
    deleteState: new State(),

    referrerSearch: null,
    newReferrer:null,
    status: true,

    searchedItems:[],
  }),
  mounted() {
    this.emptyReferrer();
  },
  methods: {
    emptyReferrer() {
      this.newReferrer = {
        name:null,
        phone:null,
        user:null,
        comment: null,
        status: false,
        contractNumber: null
      }
    },
    search(){
      this.mainState.stateLoading();

      let rb = new Entity_Referral()
        .selects(Entity_Referral.comment)
        .selects(Entity_Referral.contractNumber)
        .selects(Entity_Referral.phone)
        .selects(Entity_Referral.id)
        .selects(Entity_Referral.name)
        .selects(Entity_Referral.status)
        //user
        .selects([Entity_Referral.user, Entity_User.id].join('.'))
        .selects([Entity_Referral.user, Entity_User.firstName].join('.'))
        .selects([Entity_Referral.user, Entity_User.lastName].join('.'))
        .selects([Entity_Referral.user, Entity_User.secondName].join('.'))
        .selects([Entity_Referral.user, Entity_User.phone].join('.'))
        //userProfile
        .selects([Entity_Referral.user, Entity_User.userProfile, Entity_UserProfile.birth].join('.'))
        .selects([Entity_Referral.user, Entity_User.userProfile, Entity_UserProfile.gender].join('.'))
        .selects([Entity_Referral.user, Entity_User.userProfile, Entity_UserProfile.email].join('.'))
        .selects([Entity_Referral.user, Entity_User.userProfile, Entity_UserProfile.phone1].join('.'))
        .selects([Entity_Referral.user, Entity_User.userProfile, Entity_UserProfile.workOrgPhone].join('.'))
      

        if(!this.$tools.isEmpty(this.referrerSearch)) {
          let v = this.referrerSearch.trim();

          if (v.includes('@')) {
            rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.userProfile, Entity_UserProfile.email].join('.') + ' LIKE ?', '%' + v + '%')
          } else if (/\d/.test(v)) {
            rb.filterAnd(
                [Entity_Referral.entity, Entity_Referral.phone].join('.') + ' LIKE ? OR ' +
                [Entity_Referral.entity, Entity_Referral.user, Entity_User.phone].join('.') + ' LIKE ? OR ' +
                [Entity_Referral.entity, Entity_Referral.user, Entity_User.userProfile, Entity_UserProfile.phone1].join('.') + ' LIKE ?'
                ,
                '%' + v.replace(/\D/g, '') + '%',
                '%' + v.replace(/\D/g, '') + '%',
                '%' + v.replace(/\D/g, '') + '%'
            )
          } else {
            let arr = v.split(' ');
          
            if (arr.length === 1) {
              rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.lastName].join('.') + ' LIKE ?', '%' + arr[0] + '%')
            } else if (arr.length === 2) {
              rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.lastName].join('.') + ' LIKE ?', '%' + arr[0] + '%')
              rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.firstName].join('.') + ' LIKE ?', '%' + arr[1] + '%')
            } else if (arr.length === 3) {
              rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.lastName].join('.') + ' LIKE ?', '%' + arr[0] + '%')
              rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.firstName].join('.') + ' LIKE ?', '%' + arr[1] + '%')
              rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.secondName].join('.') + ' LIKE ?', '%' + arr[2] + '%')
            }

            rb.filterOr([Entity_Referral.entity, Entity_Referral.name].join('.') + ' LIKE ?', '%' + arr[0] + '%')
          }
        }
        rb.page(1).onPage(10);

        rb.request((data)=>{        

          this.searchedItems = data.items;

          this.mainState.stateSuccess();
        }, (e)=>{
        console.error(e.response.data.message);
        this.mainState.stateError();
      })


    },
    userCreate(referrer=null){
      if(!referrer){
        this.mainState.stateLoading();
        this.newReferrer.status = this.status ? 'active' : 'unactive';
        axios.post(Api.host+'/referral/', this.newReferrer)
        .then((r)=>{
          this.setReferrer(r.data);
        }).catch(() =>{
          this.mainState.stateError();
        });
      } else {
        this.setReferrer(referrer);
      }
    },
    setReferrer(referrer) {
      this.mainState.stateLoading();
      axios.post(Api.host+'/visit/'+this.visitId+'/referral/', {
        referralId: referrer.id
      })
      .then(()=> {
        this.$emit('created', referrer);
        this.emptyReferrer()
        this.mainState.modalClose();
      })
      .catch(function (error) {
        this.mainState.stateError();
        console.log(error)
      });
    },
    deleteUserForVisit(){
      this.deleteState.stateLoading();
      axios.delete(Api.host+'/visit/'+this.visitId+'/referral/')
          .then(() =>{
            this.deleteState.stateSuccess();
            this.deleteState.modalClose();
            this.$emit('delete');
          })
          .catch(e =>{
            Api.responseError(e)
            this.deleteState.stateError();
          });
    },

    close(){
      if(this.mainState.step===0) {
        if(this.mainState.isInit)
          this.mainState.modalClose();
        else
          this.mainState.stateInit();
      } else if(this.mainState.step>0){
        if(this.mainState.isInit){
          this.mainState.stateSuccess();
          this.mainState.stepPrev();
        } else {
          this.mainState.stateInit();
        }
      }
    }
  }
}
</script>