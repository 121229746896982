<template>
  <autocomplete-single
      :name="name"
      :search-text.sync="searchText"
      :items="data.items"
      :select.sync="localSelect"
      :loading="api.loading"
      backgroundColor="white"
      :exclude="typeof exclude !=='undefined' ? exclude : false"
      @open="includeAction"
  >
    <template v-slot:itemInfo="{item}">
      <v-row no-gutters class="caption">
        <template v-if="typeof showId !== 'undefined'">
          <v-col cols="auto">Id: {{ item.id }}</v-col>
          <v-col cols="auto"><dot/></v-col>
        </template>
        <v-col cols="auto">{{item.userProfile.gender==='male'?'Мужчина':'Женщина'}}</v-col>
        <v-col v-if="!$tools.isEmpty(item.userProfile.birth)" cols="auto"><dot/></v-col>
        <v-col v-if="!$tools.isEmpty(item.userProfile.birth)" cols="auto">{{$tools.years(item.userProfile.birth)}}</v-col>
        <v-col cols="auto"><dot/></v-col>
        <v-col cols="auto">{{item.type==='admin' ? 'Администратор' : item.type==='spec' ? 'Специалист' : 'Клиент'}}</v-col>
        <v-col v-if="typeof item._recom!=='undefined'" cols="12" class="primary--text">Общий номер телефона</v-col>
      </v-row>
    </template>
  </autocomplete-single>
</template>

<script>
import GraphqlApi from "@/plugins/graphqlApi";
import Dot from "@/componentsV2/base/Dot.vue";
import AutocompleteSingle from "@/componentsV2/base/AutocompleteSingle.vue";
export default {
  components: {AutocompleteSingle, Dot},
  props:[
    'name',
    'select',
    'exclude',
    'include',
    'showId',
    '_type',
  ],
  data:()=>({
    api: new GraphqlApi(),
    searchText: null,
    data: {count:0, items:[]},
    localSelect: null
  }),
  watch: {
    select() {
      this.localSelect = this.select;
      if(typeof this.localSelect!=='undefined' && this.localSelect!==null) {
        this.localSelect.name = this.localSelect.lastName+' '+this.localSelect.firstName+' '+this.localSelect.secondName
        this.data = {
          count: 1,
          items: [this.localSelect]
        }
      }
    },
    localSelect() {
      this.$emit('update:select', this.localSelect)
    },
    searchText(){
      if(this.$tools.isEmpty(this.searchText)){
        this.data = {
          count: 0,
          items: [this.localSelect]
        }
        this.includeAction();
      }
      if(this.searchText) {
        let arr1 = this.searchText.split(' ');
        let whereQL = '';
        let whereQLParams = '';
        if(typeof this._type!=='undefined'){
          whereQL = 'user.type=?1 AND ';
          whereQLParams += this._type+',';
        }

        if (/^\d+$/.test(arr1)&&arr1.length>0) {//only id
          whereQL += 'user.id = ?'+(typeof this._type==='undefined' ? '1' : '2');
          whereQLParams += arr1[0];
        } else {
          if(arr1.length>0){
            whereQL += 'user.lastName LIKE ?'+(typeof this._type==='undefined' ? '1' : '2');
            whereQLParams += '%'+arr1[0]+'%';
          }
          if(arr1.length>1){
            whereQL += ' AND user.firstName LIKE ?'+(typeof this._type==='undefined' ? '2' : '3');
            whereQLParams += ',%'+arr1[1]+'%';
          }
          if(arr1.length>2){
            whereQL += ' AND user.secondName LIKE ?'+(typeof this._type==='undefined' ? '3' : '4');
            whereQLParams += ',%'+arr1[2]+'%';
          }
        }

        this.api.requestSingleModelMultipleDataV2(this.data, '/api/graphqlv2/', `
          query Query($whereQLParams: String) {
            users(
              whereQL: "`+whereQL+`"
              whereQLParams: $whereQLParams
              orderQL: "user.lastName"
            ) {
              count
              items {
                id
                firstName
                lastName
                secondName
                type
                userProfile{
                  gender
                  birth
                }
              }
            }
          }
      `,{whereQLParams:whereQLParams}, null, (v)=>{
          v.name = v.lastName+' '+v.firstName+' '+v.secondName;
          return v;
        }, null);
      }  
    }
  },
  mounted() {
    this.localSelect = this.select;
    if(typeof this.localSelect!=='undefined' && this.localSelect!==null) {
      this.localSelect.name = this.localSelect.lastName+' '+this.localSelect.firstName+' '+this.localSelect.secondName
      this.data = {
        count: 1,
        items: [this.localSelect]
      }
    }
  },
  methods:{
    includeAction(){    
      if (typeof this.include !== 'undefined') {
        let arr = [];
        this.include.forEach(v=>{
          if(this.data.items.filter(v1=>v1.id===v.id).length===0){
            v._recom = true;
            arr.push(v);
          }
        });
        if(arr.length>0) {
          this.data.items = [...arr, ...this.data.items]
          this.data.count += arr.length
        }
      }  
    }
  }
}
</script>