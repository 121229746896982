<template>
  <dialog-view
      :open="this.medPlanId!=null"
      :title="'План лечения #'+(medPlanId!=null ? medPlanId : medPlan!==null ? medPlan.id : '')"
      :loading="medPlanState.isLoading"
      big contentFullSize @cancel="close()"
  >
    <template v-slot:barActions>
      <div ref="barAction">
        <log entity-name="MedPlan" :entity-id="medPlanId" logClass="mr-2"/>
        <dialog-view
            :title="'Удалить план лечения #'+medPlanId+'?'"
            acceptName="Удалить"
            @acceptAction="medPlanDelete()"
            :loading="medPlanDeleteState.isLoading"
        >
          <template v-slot:activator>
            <v-btn icon><v-icon>mdi-trash-can-outline</v-icon></v-btn>
          </template>
        </dialog-view>
      </div>
    </template>

    <template v-slot:header v-if="this.medPlan!=null">
      <div ref="headerContent">
        <v-row class="mt-1">
          <v-col cols="6">

            <v-row>
              <v-col cols="12" class="py-1">
                <field-view
                  label="Название" :value="medPlan.name"
                  :url="'/api/medPlan/medPlan/'+medPlanId+'/'" field="name" type="text"
              />
              </v-col>
              <v-col cols="12" class="py-1">
                <user-autocomplete-single name="Лечащий врач" _type="spec" :select.sync="medPlan.userSpec" class="mt-1 mb-0"/>
              </v-col>
              <v-col cols="12" class="py-1">               
              <field-view
                label="Комментарий" :value.sync="medPlan.comment"
                :url="'/api/medPlan/medPlan/'+medPlanId+'/'" field="comment" type="textarea"/>
              </v-col>
              <v-col cols="12"> 
                  <span class="mr-1">Клиент: {{$tools.userFullName(medPlan.user)}}</span>
                  <copy-button :data="$tools.userFullName(medPlan.user)"/>
                  <v-btn 
                      @click.stop
                      x-small
                      icon depressed :href="'/panel/user/client/'+medPlan.user.id+'/'" target="_blank">
                    <v-icon small>mdi-open-in-new</v-icon>
                  </v-btn>
              </v-col> 
            </v-row>

          </v-col>
          <v-col cols="6">

            <v-row>

              <v-col cols="4" class="py-1">
                <field-view
                label="Дата назначения" :value.sync="medPlan.begin"
                :url="'/api/medPlan/medPlan/'+medPlanId+'/'" field="begin" type="date" clearable/>
              </v-col>

              <v-col cols="4" class="py-1">
                <field-view
                label="Дата закрытия" :value.sync="medPlan.end"
                :url="'/api/medPlan/medPlan/'+medPlanId+'/'" field="end" type="date" clearable/>
              </v-col>

              <v-col cols="4" class="py-1">
              
                <v-select
                  class="mt-1"
                  label="Статус"
                  v-model="medPlan.status"
                  @change="statusUpdate()"
                  background-color="white"
                  :items="[
                    {name:'Активен',value:'active'},
                    {name:'Цель достигнута',value:'achieved'},
                    {name:'Выполнен',value:'done'},
                    {name:'Завершен',value:'complete'},
                  ]"
                  outlined dense hide-details="auto" item-text="name">
                  <template v-slot:append>
                    <v-progress-circular v-if="statusState.isLoading" indeterminate class="mt-1 mr-1" color="primary" size="16" width="2"/>
                    <v-icon v-if="statusState.isSuccess" color="success">mdi-check</v-icon>
                    <v-icon v-if="statusState.isError" color="error">mdi-information-outline</v-icon>
                  </template>
                </v-select>
            
              </v-col>

              <v-col cols="4" class="py-1">
                <field-view
                label="Планируемая дата" :value.sync="medPlan.plannedDate"
                :url="'/api/medPlan/medPlan/'+medPlanId+'/'" field="plannedDate" type="date" @updated="load(true)" clearable/> 
              </v-col>
              <v-col cols="4" class="py-1">
                <field-view
                label="Дата следующего шага" :value.sync="medPlan.nextStepDate"
                :url="'/api/medPlan/medPlan/'+medPlanId+'/'" field="nextStepDate" type="date" @updated="load(true)" clearable/>    
              </v-col>           
              <v-col cols="4" class="py-1">
                <field-view
                label="Цель плана достигнута" :value.sync="medPlan.achievedDate"
                :url="'/api/medPlan/medPlan/'+medPlanId+'/'" field="achievedDate" type="date" @updated="load(true)" clearable/>    
              </v-col>
 
        
              <v-col cols="12" class="py-1">
                <direction-autocomplete-multiple :select.sync="directions" :directionCatId="13" categoryValidation class="mt-1 mb-0">
                  <template v-slot:append>
                    <v-progress-circular v-if="directionsLoading" indeterminate class="mt-1 mr-1" color="primary" size="16" width="2"/>
                    <v-icon v-if="directionsSaved" color="success">mdi-check</v-icon>
                  </template>
                </direction-autocomplete-multiple>    
              </v-col>

              <v-col cols="4">         
                <btn-question @click="changeMedPlanPrice()" :loading="medPlanState.isLoading" title="Изменить цену плана?" color="grey lighten-3" small block>
                  <template v-slot:default>Изменить цену плана</template>
                  
                  <template v-slot:content>
                    <div class="mx-5 my-5">
                      <div class="row align-center mb-5">
                        <div class="col">
                          <v-text-field label="Новая цена" v-model="medPlanTotalPriceNew" type="number" class="mt-4" outlined dense hide-details="auto" :rules="totalPriceRules"/>
                        </div>
                        <div class="col-auto"><div class="caption mt-2">или</div></div>
                        <div class="col">
                          <v-text-field label="Скидка в процентах" v-model="medPlanTotalPricePercentDiscount" type="number" class="mt-4" outlined dense hide-details="auto"/>
                        </div>
                      </div>
                      <tooltip right>              
                        <v-icon  class="ml-1" small>mdi-help-circle-outline</v-icon> Алгоритм расчёта
                        <template v-slot:content>
                          <ol>
                            <li>Вычисляется процент между базовой и новой ценой.</li>
                            <li>Применяется скидка в процентах к каждой услуге (если она не привязана к активному визиту и не оплачена).</li>
                            <li>Так как при вычислении скидки для каждой услуги происходит округление, в большинстве случаев невозможно сразу получить точную цену. Для этого программа автоматически распределяет остаток равномерно по услугам (до 100 р.).</li>
                          </ol>
                        </template>
                      </tooltip>
                    </div>

                  </template>
                  
                </btn-question>
              </v-col>

              <v-col cols="4">
                <btn-question @click="restoreMedPlanPrice()" :loading="medPlanState.isLoading" title="Вернуть базовую цену?" color="grey lighten-3" small block>
                  <template v-slot:default>Вернуть базовую цену</template>
                </btn-question>
              </v-col>

              <v-col cols="4">
                <btn-question @click="saveMedPlanServices()" :loading="medPlanState.isLoading"  title="Сохранить изменения?" color="grey lighten-3" small block>
                  <template v-slot:default>Сохранить</template>
                </btn-question>
              </v-col>   

            </v-row>

          </v-col>
        </v-row>

        <v-row>
          <v-col cols="9">

            <v-btn
              @click="isServiceAddOpen=true"
              color="grey lighten-3" depressed small class="mr-2"
            >
              Добавить услугу
            </v-btn>

            <v-btn
                @click="medPlanVisitServiceDelete()"
                :loading="medPlanVisitServiceDeleteState.isLoading"
                depressed small
                :disabled="visitServicesWithoutVisit.length===0"
                color="grey lighten-3"
                class="mr-2"
            >
              Удалить услуги {{visitServicesWithoutVisit.length>0 ? '('+visitServicesWithoutVisit.length+')' : ''}}
            </v-btn>

            <v-btn
                @click="isShowForClient()"
                depressed small
                :disabled="visitServicesSelected.length===0"
                color="grey lighten-3"
                class="mr-2"
                title="Показать/Скрыть в личном кабинете"
            >
            Показать/Скрыть в ЛК{{visitServicesSelected.length>0 ? ' ('+visitServicesSelected.length+')' : ''}}
            </v-btn>

            <v-btn
                @click="removePlannedDate()"
                depressed small
                :disabled="visitServicesSelected.length===0"
                color="grey lighten-3"
                class="mr-2"
                title="Очистить даты"
            >
            Очистить даты {{visitServicesSelected.length>0 ? ' ('+visitServicesSelected.length+')' : ''}}
            </v-btn>


            <v-btn
                @click="visitsModal = true"
                depressed small
                :disabled="visitServicesWithoutVisit.length===0"
                color="grey lighten-3"
                class="mr-2"
            >
            Добавить в визит {{visitServicesWithoutVisit.length>0 ? ' ('+visitServicesWithoutVisit.length+')' : ''}}
            </v-btn>
          </v-col>
          <v-col cols="3" class="d-flex justify-end">
            <v-chip
              class="mr-2"
              color="green"
              text-color="white"
            
              v-if="medPlanState.isSuccess"
            >
              Стоимость: {{ medPlanTotalPrice }} ₽
            </v-chip>

            <v-chip
                v-if="medPlanTotalPercentDiscount>0&&medPlanState.isSuccess"
              class="mr-2 font-weight-medium"
              color="orange"
              text-color="white"
              
            >
            Скидка {{ medPlanTotalPercentDiscount }} %
            </v-chip>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:content v-if="this.medPlan!=null">
      <v-data-table
        :headers="[
            { text: 'ID', sortable: false, groupable: false, width:'auto'},
            { text: 'Название', value:'name', sortable:true, groupable: false},
            { text: '', sortable: false, groupable: false},
            { text: 'Визит', value:'visitBegin', sortable: true, groupable: false},
            { text: 'План выполнения', value:'group', sortable: false, groupable: false},
            { text: 'Цена', sortable: false, groupable: false},
            { text: 'Скидка', sortable: false, width:'150', groupable: false},
            { text: 'Итого', sortable: false, groupable: false},
            { text: 'Организация', sortable: false, groupable: false},
            { text: 'Оплата', sortable: false, groupable: false},
          ]"
        :options.sync="options"
        :items="medPlanServices"
        :items-per-page="-1"
        disable-pagination
        :footer-props="{
          'disable-items-per-page':true,
          'disable-pagination':true,
          'items-per-page-text':''
        }"
        :loading="medPlanState.isLoading"
        dense
        showSelect
        :height="tableHeight"
        fixed-header
        :group-by="groupByPlannedDate ? 'groupByPlannedDate' : serviceCatUniqueSections.length>1 ? 'sectionName' : []"
        ref="nTable"
    >

      <template v-slot:header.group>
        <div class="d-flex align-center">
          <span>План выполнения</span>
          <tooltip right v-if="medPlan.plannedDate">              
            <v-switch
              v-model="groupByPlannedDate"
              class="ml-2 my-0"
              dense
              hide-details
            ></v-switch> 
            <template v-slot:content>
              группировка по по планируемой дате
            </template>
          </tooltip>
        </div>       
      </template>

      <template v-slot:group.header="{items, isOpen, toggle}">
        <th colspan="11">
          <div class="d-flex align-center justify-space-between py-2">
            <div class="d-flex align-center">
              <v-icon @click="toggle" dense small
                >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
              </v-icon>
              <span class="text-subtitle-1 pl-3 font-weight-medium">
                {{formatGroupTitle(groupByPlannedDate||serviceCatUniqueSections.length>1 ? items[0] : 'Без категории')}}
              </span>
            </div>
            <v-btn v-if="!groupByPlannedDate"
                @click="isServiceAddOpen=true;serviceCatId=items[0].serviceCatId"
                color="grey lighten-3"  small class="ml-3"
            >
              Добавить услугу в раздел 
            </v-btn>
          </div>
        </th>
      </template>

      <template v-slot:footer.prepend>
        <v-btn small text @click="$tools.download($refs,`Медплан_${medPlanId}`)" class="mr-2" :loading="medPlanState.isLoading">
          <v-icon small class="pr-2">mdi-download</v-icon>Скачать
        </v-btn>
        <v-spacer/>
      </template>

      <template v-slot:header.data-table-select>
        <v-checkbox v-model="selectAllChekbox" @click="selectAll(medPlanServices)"  dense hide-details />
      </template>

      <template v-slot:item="{ item }">
        <tr>
            <td>
              <v-checkbox v-model="item._selected" @click="visitServicesSelected=medPlanServices.filter(v=>v._selected)" class="ma-0 pa-0" :key="'service_'+item.id" @click.stop dense hide-details />
            </td>
            <td class="text-wrap">{{ item.id }}</td>
            <td class="text-wrap">
              <div class="serviceName">
                <tooltip v-if="item.type==='serviceComposite'" right>              
                  <v-icon class="mr-2" size="18" style="margin-top:-1px">mdi-label-multiple-outline</v-icon>
                  <template v-slot:content>
                    Составная услуга
                  </template>
                </tooltip>

                <tooltip v-if="item.type==='product'" right>              
                  <v-icon class="mr-2" size="16">mdi-basket-outline</v-icon>
                  <template v-slot:content>
                    Товар
                  </template>
                </tooltip>
                <span>{{ item.name }}</span>
              </div>
            </td>
            <td class="text-center"><v-icon v-if="!item.isShowForClient" class="ml-2" size="14" color="primary" title="Скрыто в личном кабинете">mdi-eye-off-outline</v-icon></td>
            <td class="text-center" :data-print="item.visit ? $tools.date(item.visit.begin) : '-'">
              <v-btn
                  v-if="withoutVisit(item)"
                  @click="visitCreate(item.id)"
                  depressed x-small
                  title="Создать визит"
                  color="grey lighten-3"
              >
                Создать визит
              </v-btn>
              <v-btn v-else @click="visitId=item.visit.id" depressed x-small color="grey lighten-3">
                    {{item.visit?.begin|date}}
              </v-btn>
            </td>
            <td class="text-center" @click="changeDateModalOpen(item)">
              <span v-html="formatDate(item.startDayDate, item.endDayDate, item.visit)"></span>
            </td>
            <td class="text-no-wrap">
              {{$tools.price(item.price)}}
            </td>
            <td :data-print="item.discount">
              <template v-if="withoutVisit(item)">
              <v-text-field
                    v-if="item.serviceDiscountType === '₽'"
                    v-model.number="item.discount"
                    @input="discountCalc(item.id)"
                    type="text" background-color="white" hide-details outlined dense class="_inside-input-wrapper" size="2">
                  <template v-slot:append>
                    <v-select
                        :items="moneyOrPercent"
                        v-model="item.serviceDiscountType"
                        hide-details dense
                        @change="refreshDiscount(item.id);discountCalc(item.id);"
                    ></v-select>
                  </template>
                </v-text-field>
                <v-text-field
                    v-else
                    v-model.number="item.discountPercent"
                    @input="discountCalc(item.id)"
                    type="text" background-color="white" hide-details outlined dense class="_inside-input-wrapper" size="2" >
                  <template v-slot:append>
                    <v-select
                        :items="moneyOrPercent"
                        v-model="item.serviceDiscountType"
                        hide-details dense
                        @change="refreshDiscount(item.id);discountCalc(item.id);"
                    ></v-select>
                  </template>
                </v-text-field>
              </template>
              <span v-else>{{ $tools.price(item.discount) }}</span>
            </td>
            <td class="text-no-wrap">
              {{$tools.price(item.priceTotal)}}
            </td>
            <td v-if="item.insur">{{ item.insur.name }}</td>
            <td class="text-center" v-else>-</td>
            <td class="status">
              <visit-service-status :status="item.payStatus"/>
            </td>
          </tr>
      </template>
    </v-data-table>


    <dialog-view
        title="Изменить дату?"
        :open="changeDateModal"
        @cancel="changeDateModal = false;"
      >
      <template v-slot:content>
        <date-interval name="План выполнения" :valueFrom.sync="serviceStartDay" :valueTo.sync="serviceEndDay"/>
        <div class="d-flex justify-center">
        <v-btn text color="error" @click="serviceStartDay=null;serviceEndDay=null;">Очистить даты</v-btn>
        </div>
      </template>
      <template v-slot:actions>
        <v-btn text color="primary" @click="changeDate(serviceId);changeDateModal = false">ОК</v-btn>
      </template>
    </dialog-view>


    <dialog-view
        title="Обнаружены изменения"
        :open="serviceUpdateWarning"
        @cancel="serviceUpdateWarning = false;"
      >
      <template v-slot:content>
        <v-alert
          outlined
          type="warning"
          prominent
          border="left"
        >
          Услуги были добавлены в визит, перезагрузить медплан?
        </v-alert>
      </template>
      <template v-slot:actions>
        <v-btn text color="primary" @click="load();serviceUpdateWarning = false;visitsModal = false;">ОК</v-btn>
      </template>
    </dialog-view>


      <dialog-view
          :title="visitServicesWithoutVisit.length>0 ? 'Добавить выбранные услуги в визит ('+visitServicesWithoutVisit.length+')' : ''"
          :open="visitsModal"
          @cancel="visitsModal = false;"
          big
      >
        <template v-slot:barActions>
          <v-btn
              @click="visitCreate()"
              depressed small
              :disabled="visitServicesWithoutVisit.length===0"
              color="grey lighten-3"
              class="mr-2"
              title="Создать визит"
          >
          Создать визит {{visitServicesWithoutVisit.length>0 ? ' ('+visitServicesWithoutVisit.length+')' : ''}}
          </v-btn>
        </template>
        <template v-slot:content>
          <n-table-view
            ref="otherVisits"
            name="Список"
            :genRequestBuilder="visitsRequestBuilder"
            :headers="[
                    { text: 'ID', value: 'id', class:'text-no-wrap', sortable:false},
                    { text: 'Статус', value: 'status', class:'text-no-wrap', sortable:false},
                    { text: '', value: '', class:'text-no-wrap', sortable:false},
                    { text: 'Специалист', value: 'userSpec', class:'text-no-wrap', sortable:false},
                    { text: 'Оплачено',  class:'text-no-wrap', sortable:false},
                    { text: 'Осталось',  class:'text-no-wrap', sortable:false},
                    { text: 'Дата визита', value: 'begin', class:'text-no-wrap', width: '100%', sortable:true},
                  ]" 
                  :sortBy="['begin']"       
            tableClass="tableStatused"      
            >
      
            <template v-slot:item="{ item }">
              <tr @click="visitId=item.id">
                <td class="text-no-wrap">{{item.id}}</td>
                <td class="text-no-wrap">
                  <visit-status :status="$tools.findById(statuses, item.status)" :isPaidFull="item.paySummary.isPaidFull" :isSecond="item.isSecond" small/>
                </td> 
                <td>
                  <v-btn
                      @click.stop="visitServiceAdd(item.id);"
                      depressed small
                      :disabled="visitServicesWithoutVisit.length===0"
                      color="grey lighten-3"
                  >
                     Добавить в визит
                  </v-btn>
                </td>
                <td class="text-no-wrap position-relative">
                  <div class="position-absolute mt-2">
                    <v-avatar class="user" size="32">
                      <img :src="item.userSpec.user.imageUrl" v-if="item.userSpec.user.imageUrl">
                    </v-avatar>
                  </div>
                  <div class="ml-10 mt-1">
                    {{item.userSpec.user.lastName != null ? item.userSpec.user.lastName + ' ' : ''}}
                    {{item.userSpec.user.firstName != null ? item.userSpec.user.firstName + ' ' : ''}}
                    {{item.userSpec.user.secondName != null ? item.userSpec.user.secondName + ' ' : ''}}
                  </div>
                  <div class="ml-10 caption mt-n1 mb-1">
                    {{item.userSpec.spec.name}}
                  </div>
                </td>
                <td class="text-no-wrap text-right">{{item.paySummary.success.toFixed(2)}} Р</td>
                <td class="text-no-wrap text-right">{{item.paySummary.wait.toFixed(2)}} Р</td>
                <td class="text-no-wrap full-width">{{$tools.date(item.begin)}} {{$tools.time(item.begin)}} - {{$tools.time(item.end)}}</td>
              </tr>
            </template>
          </n-table-view>
        </template>
        <!-- <template v-slot:actions>
          <v-btn text color="primary" @click="changeDate(serviceId);changeDateModal = false">ОК</v-btn>
        </template> -->
      </dialog-view>

      <visit-service-add :open.sync="isServiceAddOpen" hideFree @complete="medPlanVisitServiceCreate" />
      <visit1 :visitId.sync="visitId" />
    </template>
  </dialog-view>

</template>

<script>
import State from "@/plugins/state";
import DialogView from "@/components/DialogView";
import Api from "@/Api";
import Log from "@/views/Log/Log";
import FieldView from "@/components/FieldView";
import VisitServiceAdd from "@/views/Visit/VisitServiceAdd.vue";
import moment from "moment";  
import VisitServiceStatus from "@/components/VisitServiceStatus";
import axios from "axios";
import BtnQuestion from "@/components/btnQuestion.vue";
import UserAutocompleteSingle from "@/componentsV2/custom/UserAutocompleteSingle.vue";
import DirectionAutocompleteMultiple from "@/componentsV2/custom/DirectionAutocompleteMultiple.vue";
import DateInterval from "@/componentsV2/base/DateInterval.vue";
import NTableView from "@/componentsV2/base/NTableView.vue";
import VisitStatus from "@/components/VisitStatus";
import Tooltip from "@/componentsV2/base/Tooltip.vue";
import CopyButton from "@/components/CopyButton.vue";

import {
  Entity_MedPlan, 
  Entity_User, 
  Entity_UserProfile, 
  Entity_Direction, 
  Entity_DirectionCat, 
  Entity_VisitService, 
  Entity_ServiceCat, 
  Entity_Service, 
  Entity_Visit,
  // Entity_UserSpecPlan,
  Entity_UserSpec,
  Entity_Spec,
  Entity_ComPlace, 
  Entity_ComRoom
} from "../../../EntityStoreCacheService";

export default {
  components: {
    DirectionAutocompleteMultiple,
    VisitServiceStatus,
    Visit1: () => import('@/views/Visit/Visit'),
    VisitServiceAdd,
    FieldView,
    Log,
    //TableView,
    DialogView,
    BtnQuestion,
    UserAutocompleteSingle,
    Tooltip,
    DateInterval,
    NTableView,
    VisitStatus,
    CopyButton
  },
  props: ['medPlanId'],
  data: () => ({
    options:{},
    tableHeight: 0,
    medPlanState:new State(),
    statusState:new State(),
    medPlan:null,
    medPlanVisitServicesUrl:null,
    visitId:null,

    isServiceAddOpen:false,

    medPlanDeleteState:new State(),
    medPlanSaveState:new State(),

    medPlanVisitServiceDeleteState:new State(),

    visitServicesSelectedAll:false,
    visitServicesSelected:[],
    visitServicesWithoutVisit:[],
    visitServiceAddState:new State(),
    selectAllChekbox: false,

    moneyOrPercent: ['₽','%'],
    medPlanServices: [],
    // medPlanServicesTotal:0,
    medPlanTotalPriceBase:0,
    medPlanTotalPrice: 0,
    medPlanTotalPriceNew: 0,
    medPlanTotalPricePercentDiscount:0,
    totalPriceRules: [],

    userSpecState:new State(),
    specsSelected:[],
    serviceCatId:null,
    serviceCatUniqueSections:[],
    directionsLoading:false,
    directionsSaved:false,

    changeDateModal: false,
    serviceStartDay: null,
    serviceEndDay: null,
    serviceId: null,

    visitsModal: false,
    statuses:Api.model.Visit.statuses,
    checkServiceTimer:null,
    serviceUpdateWarning: false,

    groupByPlannedDate:false
  }),
  mounted(){
    this.checkServiceTimer = null;
    clearInterval(this.checkServiceTimer);
    this.checkServiceTimer = setInterval(() => {
      this.checkServices();
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.checkServiceTimer);
  },
  computed: {
    formatDate() {
      return (start, end, visit) => {
        if (start===null&end===null) return '<i aria-hidden="true" class="v-icon notranslate mdi mdi-calendar-alert-outline theme--light" style="cursor:pointer;"></i>'
        
        const currentDate = moment();
        const startDay = this.$tools.date(start,'DD.MM.YYYY');
        const endDay = end!==null ? this.$tools.date(end,'DD.MM.YYYY'):'';
        const divider = start&&end ? ' - ' : '';
        const prefix = start&&!end ? 'c ' : !start&&end ? 'по ' : '';
        let alert = false;

        if (visit===null||visit?.status!=='complete') {
          if (end!==null) {
            if(moment(end).subtract(1, 'days').diff(moment(currentDate), 'days')<0) alert=true
          } 
          // else {
          //   if(moment(start).diff(moment(currentDate), 'days')<0) alert=true
          // }
        }

        if(alert===true) {
          return '<span class="v-chip v-size--small red white--text" style="cursor:pointer;">'+prefix+startDay+divider+endDay+'</span>';
        } else {
          return '<span class="v-chip theme--light v-size--small" style="cursor:pointer;">'+prefix+startDay+divider+endDay+'</span>';
        }
      };
    },
    medPlanTotalPercentDiscount() {
      return Math.round(((this.medPlanTotalPriceBase - this.medPlanTotalPrice)/this.medPlanTotalPriceBase)*100);
    },
    directions:{
      get(){
        return this.medPlan.directions;
      },
      set(arr){
        if(!this.$tools.equals(this.medPlan.directions, arr)){
          this.medPlan.directions = arr;
          this.directionsLoading = true;
          this.directionsSaved = false;
          axios.put(Api.host+'/medPlan/medPlan/'+this.medPlanId+'/', {
            directions:this.medPlan.directions
          })
          .then(()=>{
            this.directionsLoading = false;
            this.directionsSaved = true;
          });
        }
      }
    }
  },  
  watch: {
    options: {
      deep: true,
      handler () {
        if (this.medPlanServices.length>0)
          this.serviceSort(this.medPlanServices);
      },
    },
    medPlanId() {
      if (this.medPlanId!=null) {
        this.load();
        this.directionsLoading = false;
        this.directionsSaved = false;
      }
    },
    visitServicesSelected(){
      if (this.visitServicesSelected.length===0) {
        this.selectAllChekbox = false;
      }

      this.visitServicesWithoutVisit = [];
      this.visitServicesSelected.forEach(v => {
        if(this.withoutVisit(v))
          this.visitServicesWithoutVisit.push(v.id);
      });
    },
    selectAllChekbox() {
      if (this.selectAllChekbox === false){
        this.visitServicesSelected = [];
      }
    },
    medPlan: {
      deep: true,
      handler() {
        if(this.medPlanState.isSuccess&&this.medPlan) 
        this.userSpecUpdate();
      }
    }
    
  },
  methods: {
    load(save=false) {
      if(save===true){
        this.saveMedPlanServices();
      }
      this.medPlanServices  = [];
      this.visitServicesSelected = [];
      this.medPlanTotalPrice = 0;

      this.medPlanState.stateLoading();

      let MedPlanRequest = new Entity_MedPlan()
      .selects(Entity_MedPlan.id)
      .selects(Entity_MedPlan.name)
      .selects(Entity_MedPlan.begin)
      .selects(Entity_MedPlan.end)
      .selects(Entity_MedPlan.plannedDate)
      .selects(Entity_MedPlan.achievedDate)
      .selects(Entity_MedPlan.nextStepDate)
      .selects(Entity_MedPlan.comment)
      .selects(Entity_MedPlan.status)
      //user
      .selects([Entity_MedPlan.user, Entity_User.id].join('.'))
      .selects([Entity_MedPlan.user, Entity_User.firstName].join('.'))
      .selects([Entity_MedPlan.user, Entity_User.lastName].join('.'))
      .selects([Entity_MedPlan.user, Entity_User.secondName].join('.'))
      //userSpec
      .selects([Entity_MedPlan.userSpec, Entity_User.id].join('.'))
      .selects([Entity_MedPlan.userSpec, Entity_User.type].join('.'))
      .selects([Entity_MedPlan.userSpec, Entity_User.firstName].join('.'))
      .selects([Entity_MedPlan.userSpec, Entity_User.lastName].join('.'))
      .selects([Entity_MedPlan.userSpec, Entity_User.secondName].join('.'))
      .selects([Entity_MedPlan.userSpec, Entity_User.userProfile, Entity_UserProfile.birth].join('.'))
      .selects([Entity_MedPlan.userSpec, Entity_User.userProfile, Entity_UserProfile.gender].join('.'))

      //directions
      .selects([Entity_MedPlan.directions, Entity_Direction.name].join('.'))
      .selects([Entity_MedPlan.directions, Entity_Direction.id].join('.'))
      .selects([Entity_MedPlan.directions, Entity_Direction.directionCat,  Entity_DirectionCat.id].join('.'))
      .selects([Entity_MedPlan.directions, Entity_Direction.directionCat,  Entity_DirectionCat.name].join('.'))
     
      
      //virtual
      // .virtual(Entity_MedPlan.virtual_isOverDate)
      .virtual(Entity_MedPlan.virtual_summary)
    

      .filterAnd([Entity_MedPlan.entity,Entity_MedPlan.id].join('.')+'=?',this.medPlanId)
      .page(1).onPage(1);

      MedPlanRequest.request((data)=>{
        let medPlan = data.items[0];
        //visitService
        let VisitServiceRequest = new Entity_VisitService()
        .selects(Entity_VisitService.id)
        .selects(Entity_VisitService.type)
        .selects(Entity_VisitService.code)
        .selects(Entity_VisitService.comment)
        .selects(Entity_VisitService.count)
        .selects(Entity_VisitService.discount)        
        .selects(Entity_VisitService.duration)                     
        .selects(Entity_VisitService.isid)           
        .selects(Entity_VisitService.name)           
        .selects(Entity_VisitService.payStatus)          
        .selects(Entity_VisitService.price)         
        .selects('priceTotal')     
        .selects(Entity_VisitService.isShowForClient)   
        .selects(Entity_VisitService.startDay)  
        .selects(Entity_VisitService.endDay)  

        .virtual(Entity_VisitService.virtual_startDayDate)
        .virtual( Entity_VisitService.virtual_endDayDate)

        //Visit
        .selects([Entity_VisitService.visit, Entity_Visit.id].join('.'))
        .selects([Entity_VisitService.visit, Entity_Visit.begin].join('.'))
        .selects([Entity_VisitService.visit, Entity_Visit.status].join('.'))

        //service
        .selects([Entity_VisitService.service, Entity_Service.id].join('.'))
        // .selects([Entity_VisitService.service, Entity_Service.startDay].join('.'))
        // .selects([Entity_VisitService.service, Entity_Service.endDay].join('.'))
        .selects([Entity_VisitService.serviceCat, Entity_ServiceCat.id].join('.'))
        .selects([Entity_VisitService.serviceCat, Entity_ServiceCat.name].join('.'))
        .selects([Entity_VisitService.serviceCat, Entity_ServiceCat.isProgram].join('.'))

        .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_ServiceCat.id].join('.'))
        .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_ServiceCat.name].join('.'))
        .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_ServiceCat.isProgram].join('.'))

        .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_ServiceCat.id].join('.'))
        .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_ServiceCat.name].join('.'))
        .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_ServiceCat.isProgram].join('.'))

        .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.id].join('.'))
        .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.name].join('.'))
        .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.isProgram].join('.'))

        .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.id].join('.'))
        .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.name].join('.'))
        .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.isProgram].join('.'))

        .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.id].join('.'))
        .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.name].join('.'))
        .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.isProgram].join('.'))

        .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.id].join('.'))
        .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.name].join('.'))
        .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.isProgram].join('.'))

        .filterAnd([Entity_VisitService.entity, Entity_VisitService.medPlan, Entity_MedPlan.id].join('.')+'=?',this.medPlanId)
        .page(1).onPage(3000);
        
        VisitServiceRequest.request((data)=>{
          let services = data.items;

          this.medPlan = medPlan;

          this.medPlan.directions.forEach(v=>{
            v.id = parseInt(v.id);
          });
          
          services = this.serviceSort(services);

          let getSection = (index,sectionName,sectionId) =>{
            services[index].sectionName = sectionName;
            services[index].serviceCatId = sectionId;

            if(!this.serviceCatUniqueSections.includes(sectionId))
              this.serviceCatUniqueSections.push(sectionId);
          }
          services.forEach((item,index)=>{
              item.serviceDiscountType = this.moneyOrPercent[0];

              function checkIsProgram(obj,service) {
                if (obj.isProgram) {
                  getSection(index,obj.name,obj.id);                 
                } else {
                  if (obj.serviceCat) {
                    checkIsProgram(obj.serviceCat,service);
                  } else {
                    console.log('is not a program');
                  }
                }
              }
              if (item.serviceCat){
                getSection(index,item.serviceCat.name,item.serviceCat.id);
              } else {
                checkIsProgram(item.service.serviceCat,item);
              }
          });


          let uniqueGroupByPlannedDate = [];
          if (this.medPlan.plannedDate) {
            services.forEach(item=>{
              let title = this.formatDate(item.startDayDate, item.endDayDate, item.visit).replace(/<\/?[^>]+(>|$)/g, '');
              title = title.length !== 0 ? title : 'Без даты';
              item.groupByPlannedDate = title

              if(!uniqueGroupByPlannedDate.includes(title))
                uniqueGroupByPlannedDate.push(title);
               
            })


            services.forEach(item=>{
              let title = item.groupByPlannedDate;
              let index = uniqueGroupByPlannedDate.indexOf(title);

              item.groupByPlannedDate = index;
            })
         
          } 

   

          this.medPlanServices = services;

          this.medPlanPriceCalc();
 
          this.totalPriceRules = [v => v <= this.medPlanTotalPriceBase || `Сумма не должна превышать базовую стоимость ${this.$tools.price(this.medPlanTotalPriceBase)}`];

          this.medPlanState.stateSuccess();

          setTimeout(()=>{
            this.updateTableHeight();
          },100);


        }, (e)=>{
          console.error(e.response.data.message);
          this.medPlanState.stateError(e)
        })
      }, (e)=>{
        console.error(e.response.data.message);
        this.medPlanState.stateError(e)
      })
    },
    serviceSort(services){
      const sortByVisit = Object.keys(this.options).length === 0 ? -1 : this.options.sortBy.findIndex(v => v === 'visitBegin');
      if(sortByVisit !==-1) {
        let desc = this.options.sortDesc[sortByVisit];
        if (desc) {
          services.sort((a, b) => {
            if (this.withoutVisit(a)) return 1;
            if (this.withoutVisit(b)) return -1;
              return new Date(b.visit.begin) - new Date(a.visit.begin);
          });
        } else {
          services.sort((a, b) => {
              if (this.withoutVisit(a)) return 1; 
              if (this.withoutVisit(b)) return -1;
              return new Date(a.visit.begin) - new Date(b.visit.begin);
          });
        }  
      } else {
        if(services.filter(item=> item.startDayDate!==null).length>0){
          services.sort((a, b) => {
            const startComparison = new Date(a.startDayDate) - new Date(b.startDayDate);
            
              // Если startDayDate одинаковы
              if (startComparison === 0) {
                // Если один из endDayDate равен null, элементы с null идут первыми
                if (a.endDayDate === null && b.endDayDate !== null) {
                  return -1;
                }
                if (a.endDayDate !== null && b.endDayDate === null) {
                  return 1;
                }

                // Иначе сравниваем по endDayDate
                if (a.endDayDate && b.endDayDate) {
                  return new Date(a.endDayDate) - new Date(b.endDayDate);
                }
              }

            return startComparison;
          });
        }  else if(services.filter(item=> item.endDayDate!==null).length>0) {
          services.sort((a, b) => new Date(a.endDayDate) - new Date(b.endDayDate));
        }     
      }

      return services;
    },
    updateTableHeight() {
      let headerHeight = this.$refs.headerContent.offsetHeight;
      let barActionHeight = this.$refs.barAction.offsetHeight;
      this.tableHeight = window.innerHeight - headerHeight - barActionHeight - 80;
    },
    medPlanDelete(){
      this.medPlanDeleteState.stateLoading();
      Api.service.medPlan.medPlanDelete(this.medPlanId, () => {
        this.medPlanDeleteState.stateSuccess();
        this.close();
      }, (e) => {
        this.$store.commit('alertError',e.response.data.error);
        this.medPlanDeleteState.stateError();
      });
    },

    medPlanVisitServiceDelete(){
      this.medPlanVisitServiceDeleteState.stateLoading();
      // let arr = [];
      // this.visitServicesSelected.forEach((v)=>{
      //   arr.push(parseInt(v.id));
      // });
      Api.service.medPlan.medPlanVisitServiceDelete(this.medPlanId, this.visitServicesWithoutVisit, () => {
        this.medPlanVisitServiceDeleteState.stateSuccess();
        this.visitServicesSelected = [];
        this.load();
      }, (e) => {
        this.$store.commit('alertError',e.response.data.error);
        this.medPlanVisitServiceDeleteState.stateError();
      });
    },

    medPlanVisitServiceCreate(services){
      this.visitServiceAddState.stateLoading();
      let data = [];
      services.forEach((v)=>{
        data.push({serviceId:parseInt(v.id),serviceCatId:this.serviceCatId ?? null});
      });

      Api.service.medPlan.medPlanVisitServiceCreate(this.medPlanId, data, ()=> {
        this.serviceCatId = null;
        this.load();
        this.visitServiceAddState.stateSuccess();
      },()=> {
        this.visitServiceAddState.stateError();
      });
    },
    userSpecUpdate(){
      this.userSpecState.stateLoading();
      axios.put(Api.host+'/medPlan/medPlan/'+this.medPlanId+'/', {
        userSpec:this.medPlan.userSpec?.id ?? null
      })
      .then(()=>{
        this.userSpecState.stateSuccess();
      }).catch(() =>{
        this.userSpecState.stateError();
      });
    },

    statusUpdate(){
      this.statusState.stateLoading();
      axios.put(Api.host+'/medPlan/medPlan/'+this.medPlanId+'/', {
        status:this.medPlan.status
      })
      .then(()=>{
        this.statusState.stateSuccess();
      }).catch(() =>{
        this.statusState.stateError();
      });
    },

    close(){
      this.$emit('update:medPlanId', null);
      this.$emit('cancel');
      setTimeout(()=>{
        this.medPlan = null;
      }, 250);
    },
    selectAll(items) {
      let choosenItems = [];
      items.forEach(i=>{
        if(this.selectAllChekbox===true){
          i._selected = true;
        }
        else {
          i._selected = false;
        }
        choosenItems.push(i);
      })

      this.visitServicesSelected = choosenItems;
    },
    discountCalc(id){
      const index = this.medPlanServices.findIndex(item => item.id === id);
      const service = this.medPlanServices[index];
      const price = service.price;
      const serviceDiscountType = service.serviceDiscountType;

      if (serviceDiscountType === '₽') {
        if(service.discount>price) {
          service.discount = price;
        }
      } else if (serviceDiscountType === '%') {
        if(service.discountPercent>100) {
          service.discountPercent = 100;
        }
        this.medPlanServices[index].discount = ((price * service.discountPercent)/100).toFixed(0);
      }
      this.medPlanServices[index].priceTotal = price - service.discount;

      this.medPlanPriceCalc();
    },
    saveMedPlanServices() {
      let services = [];
      this.medPlanServices.forEach(service=>{
        services.push({
          'id':service.id,
          'discount':service.discount, 
          ...(this.medPlan.plannedDate && { 
            'startDay':service.startDay, 
            'endDay':service.endDay,
            'isShowForClient': service.isShowForClient
           }) 
        });
      })

      this.medPlanState.stateLoading();
      axios.put(Api.host+'/visitService/', services )
      .then(r =>{
        console.log(r);
        this.load();
        this.medPlanState.stateSuccess();
      })
      .catch((e) =>{
        this.$store.commit('alertError', e.response.data.message);
        this.medPlanState.stateError();
      });
    },
    refreshDiscount(id){
      const index = this.medPlanServices.findIndex(item => item.id === id);

      this.medPlanServices[index].discount = 0;
      this.medPlanServices[index].discountPercent = 0;
      this.medPlanServices[index].priceTotal = 0;
      this.medPlanPriceCalc();
    },
    medPlanPriceCalc() {
      this.medPlanTotalPrice = 0;
      this.medPlanTotalPriceBase = 0;
      this.medPlanServices.forEach(s=>{
        this.medPlanTotalPrice += +s.priceTotal;
        this.medPlanTotalPriceBase += +s.price;
      })
    },
    setDiscount(percent){
      this.medPlanServices.forEach((s)=>{
        if (this.withoutVisit(s)) {
          s.serviceDiscountType = '₽';
          s.discount = Math.round((s.price * percent )/100);
          this.discountCalc(s.id);
        }  
      })
    },
    changeMedPlanPrice() {
      this.restoreMedPlanPrice();
      if (this.medPlanTotalPricePercentDiscount>0&&this.medPlanTotalPricePercentDiscount<101){
        this.setDiscount(this.medPlanTotalPricePercentDiscount)

      } else if(this.medPlanTotalPriceNew<this.medPlanTotalPriceBase) {
        let difference = this.medPlanTotalPrice - this.medPlanTotalPriceNew;
        if (difference>0) {
            let percent = (difference / this.medPlanTotalPrice) * 100;

            this.setDiscount(percent);

            //распределяет остаток после округления равномерно по прайсу
            let diff = Math.abs(parseInt(this.medPlanTotalPriceNew-this.medPlanTotalPrice));
       
            if (diff>0&&diff<=100) {
              let servicesCount = this.medPlanServices.length;
              let i = 0;
              let sum = 0;

              while(sum !== diff) {

                if (this.withoutVisit(this.medPlanServices[i])) {           
                  this.medPlanServices[i].discount++;
                  sum++;
                }
    
        
                if(i === servicesCount-1) {
                  i = 0;
                }  

                i++;
              }
              
              //пересчёт скидки
              this.medPlanServices.forEach(s=>{
                this.discountCalc(s.id);
              });
    
            }
        }
      }
      this.medPlanTotalPriceNew = 0;
      this.medPlanTotalPricePercentDiscount = 0;
    },
    restoreMedPlanPrice() {  
      this.medPlanServices.forEach((s)=>{
          s.serviceDiscountType = '₽';
          s.discount = 0;
          this.discountCalc(s.id);
          this.medPlanPriceCalc();
      })
    },
    changeDateModalOpen(item) {
      if (this.medPlan.plannedDate) {
        item.startDayDate ? this.serviceStartDay = moment(item.startDayDate).format('DD.MM.YYYY') : this.serviceStartDay = null;
        item.endDayDate ? this.serviceEndDay = moment(item.endDayDate).format('DD.MM.YYYY') : this.serviceEndDay = null;
        this.serviceId = item.id;
        this.changeDateModal= true;
      }
      else {
        this.$store.commit('alertError', 'Задайте планируемую дату');
      }
    },
    changeDate(serviceId) {
      const index = this.medPlanServices.findIndex(item => item.id === serviceId);
  
      if (this.serviceStartDay) {
        this.medPlanServices[index].startDay = moment(this.serviceStartDay,'DD.MM.YYYY').diff(moment(this.medPlan.plannedDate), 'days');
        this.medPlanServices[index].startDayDate = moment(this.serviceStartDay,'DD.MM.YYYY');
      }  
      else {
        this.medPlanServices[index].startDay = null;
        this.medPlanServices[index].startDayDate = null;
      }

      if (this.serviceEndDay) {
        this.medPlanServices[index].endDay = moment(this.serviceEndDay,'DD.MM.YYYY').diff(moment(this.medPlan.plannedDate), 'days');
        this.medPlanServices[index].endDayDate = moment(this.serviceEndDay,'DD.MM.YYYY');
      } else {
        this.medPlanServices[index].endDay = null;
        this.medPlanServices[index].endDayDate = null;
      }  

    },
    isShowForClient() {
      this.visitServicesSelected.forEach(s=>{
        const index = this.medPlanServices.findIndex(item => item.id === s.id);
        this.medPlanServices[index].isShowForClient = !this.medPlanServices[index].isShowForClient;

        this.medPlanServices[index]._selected = false
      })

      this.visitServicesSelected = [];
    },
    visitsRequestBuilder(page, onPage, sortFields){
        let rb = new Entity_Visit()
          .selects(Entity_Visit.id)
          .selects(Entity_Visit.begin)
          .selects(Entity_Visit.comment)
          .selects(Entity_Visit.created)
          .selects(Entity_Visit.end)
          .selects(Entity_Visit.isSecond)
          .selects(Entity_Visit.leadUserComment)
          .selects(Entity_Visit.mark)
          .selects(Entity_Visit.status)

        
          //user
          .selects([Entity_Visit.user, Entity_User.id].join('.'))
          .selects([Entity_Visit.user, Entity_User.firstName].join('.'))
          .selects([Entity_Visit.user, Entity_User.lastName].join('.'))
          .selects([Entity_Visit.user, Entity_User.secondName].join('.'))
          .selects([Entity_Visit.user, Entity_User.phone].join('.'))
          .selects([Entity_Visit.user, Entity_User.userProfile, Entity_UserProfile.medCardNumber].join('.'))
    

          //userSpec
          .selects([Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.id].join('.'))
          .selects([Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.firstName].join('.'))
          .selects([Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.lastName].join('.'))
          .selects([Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.secondName].join('.'))
          .selects([Entity_Visit.userSpec, Entity_UserSpec.spec, Entity_Spec.name].join('.'))
          .selects([Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.imageUrl].join('.'))

          //leadUser
          .selects([Entity_Visit.leadUser, Entity_User.id].join('.'))
          .selects([Entity_Visit.leadUser, Entity_User.firstName].join('.'))
          .selects([Entity_Visit.leadUser, Entity_User.lastName].join('.'))
          .selects([Entity_Visit.leadUser, Entity_User.secondName].join('.'))
          .selects([Entity_Visit.leadUser, Entity_User.phone].join('.'))
          .selects([Entity_Visit.leadUser, Entity_User.userProfile, Entity_UserProfile.gender].join('.'))

          //comPlace
          .selects([Entity_Visit.comPlace, Entity_ComPlace.comRoom, Entity_ComRoom.sid].join('.'))
        
          //virtual
          .virtual(Entity_Visit.virtual_paySummary);
          
   
          // rb.filterAnd([Entity_Visit.entity, Entity_Visit.id].join('.')+' !=?', this.visit.id)
          rb.filterAnd([Entity_Visit.entity, Entity_User.entity, Entity_User.id].join('.')+' =?', this.medPlan.user.id)

          //only active statuses
          rb.filterAnd([Entity_Visit.entity, Entity_Visit.status].join('.')+' not in (?)', this.$inactiveStatuses)

          rb.page(page).onPage(onPage)

          

          sortFields.forEach(v=>{
            rb.order(v.field, v.isAsc)
          })

          return rb;
    },
    visitServiceAdd(visitId){
      Api.service.visit.visitServiceAdd(visitId, this.visitServicesWithoutVisit, ()=> {
        this.visitsModal = false;
        this.load();
      },(e)=> {
        console.error(e);
      });
    },
    visitCreate(serviceId=null) {
      if(serviceId!==null)
        window.open(`/panel/visit?userId=${this.medPlan.user.id}&visitServices=${serviceId}`, '_blank');
      else
        window.open(`/panel/visit?userId=${this.medPlan.user.id}&visitServices=${this.visitServicesWithoutVisit}`, '_blank');
    },
    checkServices(){
      //localStorage.clear()
      const visitServicesAddedFromMedplan = localStorage.getItem('visitServicesAddedFromMedplan');

      if (typeof localStorage.visitServicesAddedFromMedplan!=='undefined') {
        let services = JSON.parse(visitServicesAddedFromMedplan);
        if(services.length>0) {
          for (const ms of this.medPlanServices) {
            if(ms.visit===null&&services.includes(ms.id.toString())) {
              this.serviceUpdateWarning = true;
              localStorage.removeItem('visitServicesAddedFromMedplan');
              break;
            }
          }
        }  
      } 
    },
    removePlannedDate() {
      this.visitServicesSelected.forEach(s=>{
        const index = this.medPlanServices.findIndex(item => item.id === s.id);
        this.medPlanServices[index].startDay = null;
        this.medPlanServices[index].endDay = null;
        this.medPlanServices[index].startDayDate = null;
        this.medPlanServices[index].endDayDate = null;
        this.medPlanServices[index]._selected = false
      })

      this.visitServicesSelected = [];
    },
    withoutVisit(v) {
      if ((v.visit===null||this.$inactiveStatuses.includes(v.visit?.status))&&v.payStatus==='wait')
        return true
      else
        return false
    },
    formatGroupTitle(item) {
      if (this.groupByPlannedDate)
        return this.formatDate(item.startDayDate, item.endDayDate, item.visit).replace(/<\/?[^>]+(>|$)/g, '')
      else 
        return item.sectionName
    },
  }
}
</script>

<style type="scss" scoped>
.v-input {
  width: 100% !important;
}
.serviceName {
    width: 250px;
    display: flex;
    gap: 5px;
  }
</style> 