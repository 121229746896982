<template>
    <layout-view navTitle="Фильтры">
      <template v-slot:nav>
        <div class="px-4">
          <com-branch-autocomplete-multiple class="pt-1" :select.sync="comBranchsSelected" />
          <autocomplete-multiple v-if="mediaTemplateCategories.length>0" name="Категория" :items="mediaTemplateCategories" :select.sync="mediaTemplateCategorySelected" />
        </div>
      </template>
      <template v-slot:content="{height}">
        <v-tabs v-model="tabIndex">
          <v-tab v-for="(mediaDir, key) in mediaDirs" :key="key" >{{ mediaDir.name }}</v-tab>
          <v-tab-item v-for="(mediaDir, key) in mediaDirs" :key="key">
            <div v-if="mediaDir.des!=null" class="px-4 pt-2 pb-1 body-2">{{ mediaDir.des }}</div>
            <v-btn class="ml-2 my-2" depressed color="primary" @click="mediaTemplateCreateState.modalOpen({
              mediaDirId:mediaDir.id,
              name:'',
              des:'',
              file:null,
              isCanPrintVisit:false,
              sort:0,
              copyCount:1,
              comBranch:null,
              mediaTemplateCategoryId: null
            })">
              <span>Создать</span>
              <v-icon class="ml-2" small>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-tab-item>
        </v-tabs>

        <n-table-view
          ref="table"
          name="Список шаблонов"
          :genRequestBuilder="genRequestBuilder"
          :height="height-100"
          :headers="headers"
        >
      
          <template v-slot:item="{ item }">
            <tr @click="mediaTemplateUpdateState.modalOpen({
              name:item.name,
              des:item.des,
              file:null,
              fileName: item.fileName,
              isCanPrintVisit:item.isCanPrintVisit,
              sort:item.sort,
              copyCount:item.copyCount,
              comBranch:item.comBranch,
              mediaTemplateCategoryId: item.mediaTemplateCategory
            }, item.id)">
              <td class="text-no-wrap">{{item.id}}</td>
              <td>
                <tooltip v-if="item.comBranch!==null" right>              
                  <v-avatar :color="item.comBranch.color" class="mr-1" size="10" style="margin-top:-1px"/>
                  <template v-slot:content>
                    {{item.comBranch.name}}
                  </template>
                </tooltip>
                {{item.name}}
              </td>
              <td class="text-no-wrap">
                {{ item.mediaTemplateCategory ? item.mediaTemplateCategory.name :'-'}}
              </td>
              <td>{{item.des}}</td>
              <td class="text-no-wrap">
                <div v-if="misDesktopOnline&&typeof item.medRecordTemplate === 'undefined'">
                  <v-btn small depressed @click.stop="misDesktopOpenFile(item)"><v-icon class="mr-2" small>mdi mdi-open-in-new</v-icon>Открыть локально</v-btn>
                </div>
              </td>
              <td>{{item.sort}}</td>
              <td>{{item.copyCount}}</td>
              <td class="text-no-wrap">{{item.created}}</td>
            </tr>
          </template>
        </n-table-view>
        
          <v-form ref="mediaTemplateCreateForm">
            <dialog-view :open="mediaTemplateCreateState.isModalOpen" title="Создать шаблон" @cancel="mediaTemplateCreateState.modalClose()">
              <template v-slot:content v-if="mediaTemplateCreateState.modalData!=null">
                <v-text-field label="Название" class="mb-4" v-model="mediaTemplateCreateState.modalData.name" :rules="formFieldRules.required" outlined dense hide-details="auto"/>
                <v-text-field label="Описание" class="mb-4" v-model="mediaTemplateCreateState.modalData.des" outlined dense hide-details="auto"/>
                <v-text-field label="Сортировка" class="mb-4" v-model="mediaTemplateCreateState.modalData.sort" :rules="formFieldRules.required" outlined dense hide-details="auto"/>
                <v-text-field label="Копий" class="mb-4" v-model="mediaTemplateCreateState.modalData.copyCount" :rules="formFieldRules.required" outlined dense hide-details="auto"/>
                <com-branch-autocomplete-single :select.sync="mediaTemplateCreateState.modalData.comBranch"/>
                <autocomplete-single v-if="mediaTemplateCategories.length>0" name="Категория" :items="mediaTemplateCategories" :select.sync="mediaTemplateCreateState.modalData.mediaTemplateCategoryId">
                  <template v-slot:selectedInfo="{item}">
                    {{item.name}}
                  </template>
                </autocomplete-single>


                <div class="mb-4">Выберите файл документа Microsoft Word (.docx)</div>
                <v-file-input label="Файл" class="mb-4" prepend-icon="" v-model="mediaTemplateCreateState.modalData.file" accept=".docx" :rules="formFieldRules.required" outlined dense hide-details="auto" />
                <v-checkbox v-model="mediaTemplateCreateState.modalData.isCanPrintVisit" label="Разрешить для печати в визите" class="mb-4" color="primary" dense hide-details="auto" />
                <v-btn @click="mediaTemplateValuesState.modalOpen();mediaTemplateValuesAction()" depressed small>Доступные значения в файле</v-btn>
              </template>
              <template v-slot:actions>
                <v-btn text color="primary" @click="mediaTemplateCreate" :loading="mediaTemplateCreateState.isLoading">Создать</v-btn>
              </template>
            </dialog-view>
      </v-form>
      <v-form ref="mediaTemplateUpdateForm">
        <dialog-view :open="mediaTemplateUpdateState.isModalOpen" title="Изменить шаблон" @cancel="mediaTemplateUpdateState.modalClose()">
          <template v-slot:barActions>
            <v-btn icon @click="previewTemplate(mediaTemplateUpdateState.modalData1)">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn icon @click="mediaTemplateDeleteState.modalOpen(mediaTemplateUpdateState.modalData1)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn icon @click="mediaTemplateDownloadAction(mediaTemplateUpdateState.modalData)">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <template v-slot:content v-if="mediaTemplateUpdateState.modalData!=null">
            <v-text-field label="Название" class="mb-4" v-model="mediaTemplateUpdateState.modalData.name" :rules="formFieldRules.required" outlined dense hide-details="auto"/>
            <v-text-field label="Описание" class="mb-4" v-model="mediaTemplateUpdateState.modalData.des" outlined dense hide-details="auto"/>
            <v-text-field label="Сортировка" class="mb-4" v-model="mediaTemplateUpdateState.modalData.sort" :rules="formFieldRules.required" outlined dense hide-details="auto"/>
            <v-text-field label="Копий" class="mb-4" v-model="mediaTemplateUpdateState.modalData.copyCount" :rules="formFieldRules.required" outlined dense hide-details="auto"/>
            <com-branch-autocomplete-single :select.sync="mediaTemplateUpdateState.modalData.comBranch"/>

            <autocomplete-single v-if="mediaTemplateCategories.length>0" name="Категория" :items="mediaTemplateCategories" :select.sync="mediaTemplateUpdateState.modalData.mediaTemplateCategoryId">
              <template v-slot:selectedInfo="{item}">
                {{item.name}}
              </template>
            </autocomplete-single>
            <div class="mb-4">Выберите файл документа Microsoft Word (.docx)</div>
            <v-file-input label="Новый файл" class="mb-4" prepend-icon="" v-model="mediaTemplateUpdateState.modalData.file" accept=".docx" outlined dense hide-details="auto" />
            <v-checkbox v-model="mediaTemplateUpdateState.modalData.isCanPrintVisit" label="Разрешить для печати в визите" class="mb-4" color="primary" dense hide-details="auto" />
            <v-btn @click="mediaTemplateValuesState.modalOpen();mediaTemplateValuesAction()" depressed small>Доступные значения в файле</v-btn>
          </template>
          <template v-slot:actions>
            <v-btn text color="primary" @click="mediaTemplateUpdate" :loading="mediaTemplateUpdateState.isLoading">Изменить</v-btn>
          </template>
        </dialog-view>
      </v-form>
      <dialog-view :open="mediaTemplateDeleteState.isModalOpen" title="Удалить шаблон?" @cancel="mediaTemplateDeleteState.modalClose()">
        <template v-slot:actions>
          <v-btn text color="red" :loading="mediaTemplateDeleteState.isLoading" @click="mediaTemplateDeleteAction(mediaTemplateDeleteState.modalData)">Удалить</v-btn>
        </template>
      </dialog-view>
      <dialog-view :open="mediaTemplateValuesState.isModalOpen" title="Значения шаблона" big @cancel="mediaTemplateValuesState.modalClose()">
        <template v-slot:content>
          <v-data-table
              dense
              :headers="mediaTemplateValuesHeaders"
              :items="mediaTemplateValues"
              :server-items-length="mediaTemplateValues.length"
              :loading="mediaTemplateValuesState.isLoading"
              :hide-default-footer="true"
              :items-per-page="100"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <v-sheet width="700" color="transparent" class="text-wrap">
                    {{item.name}}
                  </v-sheet>
                </td>
                <td class="text-no-wrap">{{item.example}}</td>
                <td class="text-no-wrap">
                  <code style="user-select: all">{{item.tag}}</code>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </dialog-view>
      <v-dialog
          v-model="previewState.isModalOpen"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          scrollable
      >
        <v-card class="grey lighten-4">
          <v-toolbar dark :color="!$vuetify.theme.dark ? 'rgb(2 146 77)' : 'rgb(40 40 40)'" max-height="64" style="z-index: 1">
            <v-btn icon dark @click="previewState.modalClose()">
              <v-progress-circular v-if="previewState.isLoading" indeterminate size="20" width="3"/>
              <v-icon v-else>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title v-if="previewState.isSuccess">
              Предосмотр шаблона документа
            </v-toolbar-title>
          </v-toolbar>
          <div v-if="previewState.isSuccess" class="view">
            <div class="preview px-6 pt-6">
              <div v-if="previewState.isLoading">
                <v-progress-circular indeterminate size="20" width="3" color="primary"/>
              </div>
              <div v-if="previewState.isSuccess">
                <div v-for="(preview, index) in previews" :key="'previewPage'+index" class="pb-6">
                  <v-sheet
                      v-if="preview.indexOf('.png')!==-1"
                      :max-width="890"
                      rounded class="d-inline-block overflow-hidden" elevation="2"
                  >
                    <v-img :src="preview" class="full-width"/>
                  </v-sheet>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-dialog>

    </template>
  </layout-view>

</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";
import {FormFieldRules} from "@/plugins/formFieldRules";
import DialogView from "@/components/DialogView";
import axios from "axios";
import ComBranchAutocompleteSingle from "@/componentsV2/custom/ComBranchAutocompleteSingle.vue";
import ComBranchAutocompleteMultiple from "@/componentsV2/custom/ComBranchAutocompleteMultiple.vue";
import AutocompleteSingle from "@/componentsV2/base/AutocompleteSingle.vue";
import AutocompleteMultiple from "@/componentsV2/base/AutocompleteMultiple.vue";
import {Entity_MediaTemplate, Entity_ComBranch, Entity_MediaDir, Entity_MediaTemplateCategory} from "../../../EntityStoreCacheService";
import NTableView from "@/componentsV2/base/NTableView.vue";
import LayoutView from "@/components/LayoutView";
import Tooltip from "@/componentsV2/base/Tooltip.vue";



export default {
  components: {
    NTableView, 
    ComBranchAutocompleteSingle, 
    ComBranchAutocompleteMultiple, 
    DialogView, 
    AutocompleteSingle, 
    AutocompleteMultiple,
    LayoutView,
    Tooltip
  },
  data: () => ({
    mediaDirsState: new State(),
    mediaDirs: [],
    tabIndex:0,
    comBranchsSelected:[],

    headers: [
      { text: 'ID', value: 'id', class:'text-no-wrap', sortable: true},
      { text: 'Название', value: 'name', sortable: true},
      { text: 'Категория', value: 'mediaTemplateCategory.name', class:'text-no-wrap', sortable: true},
      { text: 'Описание', value: 'des', width:'300', sortable: false},
      { text: '', sortable: false},
      { text: 'Сортировка',value: 'sort', sortable: true},
      { text: 'Копий', sortable: false},
      { text: 'Дата создания', value: 'created', class:'text-no-wrap', sortable: true},
    ],

    mediaTemplateCreateState: new State(),
    formFieldRules: FormFieldRules,
    mediaTemplateUpdateState: new State(),
    mediaTemplateDownloadState: new State(),
    mediaTemplateDeleteState: new State(),
    mediaTemplateValuesState: new State(),
    mediaTemplateValuesHeaders: [
      { text: 'Название', value: 'name', sortable: false, class:'text-no-wrap'},
      { text: 'Пример', value: 'example', sortable: false, class:'text-no-wrap'},
      { text: 'Тэг', value: 'tag', sortable: false, class:'text-no-wrap'},
    ],
    mediaTemplateValues:[],

    previewState:new State(),
    previews:[],
    mediaTemplateCategories: [],
    mediaTemplateCategorySelected:[],
    misDesktopOnline:false,
  }),
  mounted() {
    this.loadMediaDirs();
    this.height = window.innerHeight - 160;
    this.$tools.misDesktopStatus((v)=>{
      this.misDesktopOnline = v;
    });
  },
  watch:{
    tabIndex(){
      this.loadMediaTemplateCategory();
      this.$refs.table.load();
    },
    comBranchsSelected() {
      this.$refs.table.load();
    },
    mediaTemplateCategorySelected() {
      this.$refs.table.load();
    },
  },
  methods:{
    loadMediaDirs(){
      this.mediaDirsState.stateLoading();

      let builder = new Entity_MediaDir()
      .selects(Entity_MediaDir.id)
      .selects(Entity_MediaDir.name)
      .selects(Entity_MediaDir.des)

      builder.page(1).onPage(3000);
      builder.order(Entity_MediaDir.sort, true)

      builder.request((data)=>{
        this.mediaDirs = data.items;
        this.loadMediaTemplateCategory();
        this.mediaDirsState.stateSuccess();
      }, ()=>{
        this.mediaDirsState.stateError();
      })
    },
    genRequestBuilder(page, onPage, sortFields){
      let rb  = new Entity_MediaTemplate()
      .selects(Entity_MediaTemplate.id)
      .selects(Entity_MediaTemplate.name)
      .selects(Entity_MediaTemplate.created)
      .selects(Entity_MediaTemplate.des)
      .selects(Entity_MediaTemplate.fileName)
      .selects(Entity_MediaTemplate.isCanPrintVisit)
      .selects(Entity_MediaTemplate.sort)
      .selects(Entity_MediaTemplate.copyCount)

      .selects([Entity_MediaTemplate.comBranch, Entity_ComBranch.id].join('.'))
      .selects([Entity_MediaTemplate.comBranch, Entity_ComBranch.name].join('.'))
      .selects([Entity_MediaTemplate.comBranch, Entity_ComBranch.color].join('.'))

      .selects([Entity_MediaTemplate.mediaDir, Entity_MediaDir.id].join('.'))

      .selects([Entity_MediaTemplate.mediaTemplateCategory, Entity_MediaTemplateCategory.id].join('.'))
      .selects([Entity_MediaTemplate.mediaTemplateCategory, Entity_MediaTemplateCategory.name].join('.'))

      rb.page(page).onPage(onPage)
    

      rb.filterAnd([Entity_MediaTemplate.entity, Entity_MediaTemplate.mediaDir, Entity_MediaDir.id].join('.')+' = ?',this.mediaDirs[this.tabIndex].id)

      //filter by ComBranch
      if(this.comBranchsSelected.length>0){
          rb.filterAnd([Entity_MediaTemplate.entity, Entity_MediaTemplate.comBranch, Entity_ComBranch.id].join('.')+' in (?)',this.queryArray(this.comBranchsSelected));
      }

      //by mediaTemplateCategory
      if (this.mediaTemplateCategorySelected.length>0) {
        rb.filterAnd([Entity_MediaTemplate.entity, Entity_MediaTemplate.mediaTemplateCategory, Entity_MediaTemplateCategory.id].join('.')+' in (?)',this.queryArray(this.mediaTemplateCategorySelected))
      } 
          
      sortFields.forEach(v=>{
          rb.order(v.field, v.isAsc)
      })

      return rb;
    },
    loadMediaTemplateCategory(){
      this.mediaTemplateCategorySelected = [];
      this.mediaTemplateCategories = [];
      let builder = new Entity_MediaTemplateCategory()
      .selects(Entity_MediaTemplateCategory.id)
      .selects(Entity_MediaTemplateCategory.name)
      .selects(Entity_MediaTemplateCategory.status)
      .selects(Entity_MediaTemplateCategory.subcategories)

      //subcategories
      .selects([Entity_MediaTemplateCategory.subcategories, Entity_MediaTemplateCategory.id].join('.'))
      .selects([Entity_MediaTemplateCategory.subcategories, Entity_MediaTemplateCategory.name].join('.'))
      .selects([Entity_MediaTemplateCategory.subcategories, Entity_MediaTemplateCategory.status].join('.'))

      builder.page(1).onPage(3000);

      //filter by MediaDir
      builder.filterAnd([Entity_MediaTemplateCategory.entity, Entity_MediaTemplateCategory.mediaDir, Entity_MediaDir.id].join('.')+' = ?',this.mediaDirs[this.tabIndex].id)

      builder.request((data)=>{
        if (data.count>0) {
          let mediaCategories = [];
          data.items.forEach(cat=>{     
              mediaCategories.push({id:cat.id,name:cat.name});
              if (cat.subcategories.length>0) {
                cat.subcategories.forEach(s=>{
                  const existingIndex = mediaCategories.findIndex(item => item.id === s.id);

                  if (existingIndex !== -1) {
                      mediaCategories.splice(existingIndex, 1);
                  }
                  
                  mediaCategories.push({id: s.id, name: `${cat.name}/${s.name}`});
                })          
              }
          });

          this.mediaTemplateCategories = mediaCategories;

          console.log(this.mediaTemplateCategories)
        }
      }, (e)=>{
        console.log(e);
        console.error(e.response.data.message);
      })
    },
    mediaTemplateCreate() {
      if (this.$refs.mediaTemplateCreateForm.validate()){
        this.mediaTemplateCreateState.stateLoading();
        //this.mediaTemplateCreateState.modalData.isCanPrintVisit = this.mediaTemplateCreateState.modalData.isCanPrintVisit ? 1 : 0;
        if(this.mediaTemplateCreateState.modalData.comBranch!==null)
          this.mediaTemplateCreateState.modalData.comBranchId=this.mediaTemplateCreateState.modalData.comBranch.id;
        if(this.mediaTemplateCreateState.modalData.mediaTemplateCategoryId!==null)
          this.mediaTemplateCreateState.modalData.mediaTemplateCategoryId=this.mediaTemplateCreateState.modalData.mediaTemplateCategoryId.id;

        Api.service.media.mediaTemplateCreate(this.mediaTemplateCreateState.modalData, ()=>{
          this.mediaTemplateCreateState.stateSuccess();
          this.mediaTemplateCreateState.modalClose();

          this.$refs.table.load();
        }, ()=>{
          this.mediaTemplateCreateState.stateError();
        });
      }
    },
    mediaTemplateUpdate() {
      if (this.$refs.mediaTemplateUpdateForm.validate()){
        this.mediaTemplateUpdateState.stateLoading();
        //this.mediaTemplateUpdateState.modalData.isCanPrintVisit = this.mediaTemplateUpdateState.modalData.isCanPrintVisit ? 1 : 0;
        if(this.mediaTemplateUpdateState.modalData.comBranch!==null)
          this.mediaTemplateUpdateState.modalData.comBranchId=this.mediaTemplateUpdateState.modalData.comBranch.id;
        if(this.mediaTemplateUpdateState.modalData.mediaTemplateCategoryId!==null)
          this.mediaTemplateUpdateState.modalData.mediaTemplateCategoryId=this.mediaTemplateUpdateState.modalData.mediaTemplateCategoryId.id;

        Api.service.media.mediaTemplateUpdate(this.mediaTemplateUpdateState.modalData1, this.mediaTemplateUpdateState.modalData,()=>{
          this.mediaTemplateUpdateState.stateSuccess();
          this.mediaTemplateUpdateState.modalClose();

          this.$refs.table.load();
        }, ()=>{
          this.mediaTemplateUpdateState.stateError();
        });
      }
    },
    mediaTemplateDeleteAction() {
      this.mediaTemplateDeleteState.stateLoading();
      Api.service.media.mediaTemplateDelete(this.mediaTemplateDeleteState.modalData,()=>{
        this.mediaTemplateDeleteState.stateSuccess();
        this.mediaTemplateDeleteState.modalClose();
        this.mediaTemplateUpdateState.modalClose();

        this.$refs.table.load();
      }, ()=>{
        this.mediaTemplateDeleteState.stateError();
      });
    },
    mediaTemplateDownloadAction(data){
      const a = document.createElement('a');
      a.href = '/media/mediaTemplate/'+data.fileName;
      a.download = data.name+'.docx';
      console.log(a.href)
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    mediaTemplateValuesAction(){
      this.mediaTemplateValuesState.stateLoading();
      Api.service.media.mediaTemplateValues((v)=>{
        this.mediaTemplateValues = v;
        this.mediaTemplateValuesState.stateSuccess();
      }, ()=>{
        this.mediaTemplateValuesState.stateError();
      });
    },
    previewTemplate(id){
      this.previewState.modalOpen();
      this.previewState.stateLoading();
      axios.get(Api.host+'/media/mediaTemplate/'+id+'/preview/')
          .then((r) => {
            this.previews = r.data;
            this.previewState.stateSuccess();
          })
          .catch(() =>{
            this.previewState.stateError();
          });
    },
    misDesktopOpenFile(v){
      axios
          .get(Api.host + '/media/mediaTemplate/' + v.id + '/')
          .then(r => {
            let data = r.data;
            this.$tools.misDesktopOpenFile(
                data.filePath,
                data.name + data.filePath.substring(data.filePath.lastIndexOf('.'), data.filePath.length),
                window.location.origin+'/api/media/mediaTemplate/upload/desktop/',
                {
                  id: data.id,
                },
                ()=>{
                  this.$store.commit('alertSuccess', 'Файл открывается локально');
                }, ()=>{
                  this.$store.commit('alertError', 'Не удалось открыть файл локально');
                }
            );
          });
    },
    queryArray(items,key='id') {
      let arr = [];
      items.forEach(s=>{
        arr.push(s[key]);
      });
      return arr;
    }
  }
}
</script>

<style scoped lang="scss">
.view {
  position: absolute;
  top: 64px;
  bottom: 0;
  left: 0;
  right:0;
  .preview{
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left:0;
    right:0;
    text-align: center;
  }
}
.white-space-normal {
  white-space: normal !important;
  min-width: unset !important;
}
// .v-data-table table tr th:nth-child(2), .v-data-table table tr td:nth-child(2) {
//     width: auto;
// }
</style>