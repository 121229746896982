<template>
  <v-sheet
      :class="'position-relative overflow-hidden'+(typeof cardClass==='undefined' ? '' : ' '+cardClass)"
      :minHeight="minHeight"
      :minWidth="minWidth"
      :width="width"
      :rounded="typeof rounded==='undefined' ? true : rounded"
      :elevation="typeof elevation==='undefined' ? 2 : elevation"
  >
    <v-progress-linear v-show="loading" indeterminate class="position-absolute"/>
    <v-toolbar v-if="typeof title!=='undefined'" class="bar" dense elevation="0" color="transparent" max-height="48px">
      <div class="d-flex align-center"> 
       <v-toolbar-title class="body-2 font-weight-medium">{{title}}</v-toolbar-title>
        <tooltip v-if="typeof tooltip !=='undefined'" right>
          <v-icon  class="ml-1" small>mdi-help-circle-outline</v-icon>           
          <template v-slot:content>
            {{tooltip}}
          </template>
        </tooltip>  
      </div>
    
      <v-toolbar-title v-if="!$tools.isEmpty(subtitle)" class="body-2 font-weight-medium muted1 mx-2">({{subtitle}})</v-toolbar-title>
      <v-spacer/>
      <slot name="barActions"/>
    </v-toolbar>
    <div v-if="!!this.$slots.content" :class="(typeof contentFullSize=='undefined' || contentFullSize===true ? 'px-4 pb-2' : '')+' body-2'">
      <slot name="content"/>
    </div>
    <v-card-actions v-if="!!this.$slots.actions" class="footer px-4 pb-4">
      <slot name="actions"/>
    </v-card-actions>
  </v-sheet>
</template>



<script>
import Tooltip from "@/componentsV2/base/Tooltip.vue";

export default {
  components: {
    Tooltip 
  },
  props: [
    'loading',
    'title',
    'subtitle',
    'contentFullSize',
    'minHeight',
    'minWidth',
    'width',
    'cardClass',
    'rounded',
    'elevation',
    'tooltip'
  ]
}
</script>
