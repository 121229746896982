<template>
  <card-view :loading="loadState.isLoading">
    <template v-slot:content>
      <v-row v-if="user!=null && user.userProfile!=null" class="ma-0">
        <v-col cols="4">
          <v-row>
            <v-col cols="12" class="text-center">
              <v-badge icon="mdi-upload" color="grey" offset-x="28" offset-y="28" bordered>
                <v-btn @click="userAvatarUploadOpen=true" width="128" height="128" color="grey lighten-3" fab elevation="0">
                    <v-avatar size="128">
                      <v-icon v-if="user.imageUrl==null" size="64" color="grey">mdi-account</v-icon>
                      <img v-else :src="user.imageUrl">
                    </v-avatar>
                </v-btn>
              </v-badge>
              <uploader
                  :dialog.sync="userAvatarUploadOpen"
                  :multiple="false"
                  :url="'/api/user/'+$router.currentRoute.params.id+'/image/'"
                  @uploadComplete="(v)=>v.length>0 ? user.imageUrl=v[0] : null"
              />
            </v-col>
            <v-col cols="12" class="subtitle-2 pb-0">Тэги</v-col>
            <v-col cols="12">
              <chip v-for="(v, k) in user.userProfile.userProfileTags" :key="k" class="mr-2">{{v.name}}</chip>
            </v-col>
            <v-col cols="12" class="subtitle-2 pb-0">Быстрые действия</v-col>
            <v-col cols="12">
              <v-btn :disabled="loadState.isLoading" block depressed @click="visitCreate"><v-icon class="pr-2">mdi-pencil-outline</v-icon>Создать визит<v-spacer /></v-btn>
            </v-col>
            <v-col cols="12" class="subtitle-2">Связь</v-col>
            <v-col cols="12">
              <call-phone v-if="!$tools.isEmpty(user.phone)" :disabled="loadState.isLoading" :phone="user.phone" big title="Позвонить"  class="mb-2"/>
              <call-phone v-if="!$tools.isEmpty(user.userProfile.phone1)" :disabled="loadState.isLoading" :phone="user.userProfile.phone1" big title="Позвонить (доп.)"  class="mb-2"/>
              <v-btn :disabled="loadState.isLoading" class="mb-2" block depressed><v-icon class="pr-2">mdi-chat-outline</v-icon>Чат лк.<v-spacer /></v-btn>
              <v-btn v-if="user.userProfile.email!=null && user.userProfile.email!=''" :disabled="loadState.isLoading" class="mb-2" block depressed @click="openUrl('mailto:'+user.userProfile.email)"><v-icon class="pr-2">mdi-email-outline</v-icon>E-mail<v-spacer /></v-btn>
              <v-btn v-if="user.userProfile.whatsapp!=null && user.userProfile.whatsapp!='' || isWFromPhone" :disabled="loadState.isLoading" class="mb-2" block depressed @click="openUrl('https://wa.me/'+encodeURIComponent(isWFromPhone ? user.phone : user.userProfile.whatsapp), true)"><v-icon class="pr-2">mdi-whatsapp</v-icon>whatsapp<v-spacer /></v-btn>
              <v-btn v-if="user.userProfile.telegram!=null && user.userProfile.telegram!='' || isTFromPhone" :disabled="loadState.isLoading" class="mb-2" block depressed @click="openUrl('https://t.me/'+encodeURIComponent(isTFromPhone ? user.phone : user.userProfile.telegram), true)"><v-icon class="pr-2">mdi-telegram</v-icon>telegram<v-spacer /></v-btn>
              <v-btn v-if="user.userProfile.instagram!=null && user.userProfile.instagram!=''" :disabled="loadState.isLoading" class="mb-2" block depressed @click="openUrl('https://instagram.com/'+encodeURIComponent(user.userProfile.instagram)+'/', true)"><v-icon class="pr-2">mdi-instagram</v-icon>instagram<v-spacer /></v-btn>
              <v-btn v-if="user.userProfile.vk!=null && user.userProfile.vk!=''" :disabled="loadState.isLoading" class="mb-2" block depressed @click="openUrl('https://vk.com/'+encodeURIComponent(user.userProfile.vk), true)"><v-icon class="pr-2">mdi-vk</v-icon>vk<v-spacer /></v-btn>
            </v-col>
            <!-- УДАЛИТЬ! -->
            <v-col cols="12">Polibase id: {{user.userProfile.oid}}</v-col>
            <!-- УДАЛИТЬ! -->

            <!-- <v-col cols="12" class="subtitle-2">Дополнительная информация</v-col>
            <user-profile-tags-select :userProfileId="user.userProfile.id" :defaultItems="user.userProfile.userProfileTags" /> -->
          </v-row>
        </v-col>
        <v-col>
          <UserProfileCardForm :user.sync="user"/>
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn depressed color="error" @click="deleteState.modalOpen()" class="mr-2"><v-icon class="pr-2">mdi-trash-can-outline</v-icon>Удалить</v-btn>
          <v-dialog v-model="deleteState.isModalOpen" max-width="250">
            <v-card>
              <v-card-title>Удалить?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="deleteState.modalClose()">Отмена</v-btn>
                <v-btn color="primary" text @click="deleteAction" :loading="deleteState.isLoading">Удалить</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn depressed color="primary" @click="updateState.modalOpen()"><v-icon class="pr-2">mdi-content-save</v-icon>Сохранить</v-btn>
          <v-dialog v-model="updateState.isModalOpen" max-width="250">
            <v-card>
              <v-card-title>Сохранить?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="updateState.modalClose()">Отмена</v-btn>
                <v-btn color="primary" text @click="save" :loading="updateState.isLoading">Сохранить</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </template>
  </card-view>
</template>

<script>
import moment from "moment";
import State from "@/plugins/state";
import PromoSource from "@/model/PromoSource";
import UserProfileCardForm from "@/views/User/UserProfileCardForm";
import CardView from "@/components/CardView";
import CallPhone from "@/components/CallPhone";
import axios from "axios";
import Api from "@/Api";
import Uploader from "@/components/Uploader.vue";
import Chip from "../../componentsV2/base/Chip.vue";

export default {
  components: {Chip, Uploader,  CallPhone, CardView, UserProfileCardForm},
  data: () => ({
    user: null,
    loadState: new State(),
    updateState: new State(),
    deleteState: new State(),
    panelOpen: 0,
    promoSources: [],

    isWFromPhone: false,
    isTFromPhone: false,

    userAvatarUploadOpen: null,
  }),
  mounted() {
    this.load();
    this.panelOpen = 0;
  },
  computed: {
    birth: {
      get: function() {
        return moment(this.user.userProfile.birth, 'YYYY-MM-DD').format('DD.MM.YYYY');
      },
      set: function(birth) {
        let d = moment(birth, 'DD.MM.YYYY')
        if (birth.length===10 && d.isValid())
          this.user.userProfile.birth = d.format('YYYY-MM-DD');
      }
    },
    pasDate: {
      get: function() {
        return moment(this.user.userProfile.pasDate, 'YYYY-MM-DD').format('DD.MM.YYYY');
      },
      set: function(pasDate) {
        let d = moment(pasDate, 'DD.MM.YYYY')
        if (pasDate.length===10 && d.isValid())
          this.user.userProfile.pasDate = d.format('YYYY-MM-DD');
      }
    }
  },
  methods:{
    openUrl: function(url, isNewTab){
      if (isNewTab)
        window.open(url);
      else
        window.location.href = url;
    },
    load: async function(){
      this.loadState.stateLoading();

      if (PromoSource.all().length===0)
        await PromoSource.api().get('/api/user/promoSource/');
      this.promoSources = PromoSource.all();

      axios.get(Api.host+'/user/'+this.$route.params.id+'/')
        .then(r =>{
          this.user = r.data;
          if (this.user!=null) {
            this.isWFromPhone = this.user.phone===this.user.userProfile.whatsapp;
            this.isTFromPhone = this.user.phone===this.user.userProfile.telegram;
          }
          this.loadState.stateSuccess();
        })
        .catch((e) =>{
          this.loadState.stateError();
          console.log(e)
        });
    },
    save: function(){
      this.updateState.stateLoading();
      let data = this.user;

      delete data.$id;
      delete data.userProfile.$id;
      delete data.userProfile.id;
      data.userProfile.whatsapp = this.isWFromPhone ? data.phone : data.userProfile.whatsapp;
      data.userProfile.telegram = this.isTFromPhone ? data.phone : data.userProfile.telegram;

      axios.put(Api.host+'/user/'+this.$route.params.id+'/?type=client', data)
        .then(r =>{
          this.user = r.data;
          if(parseInt(this.$route.params.id)===this.$store.state.user.id)
            this.$store.state.user = this.user;
          if (this.user!=null) {
            this.isWFromPhone = this.user.phone===this.user.userProfile.whatsapp;
            this.isTFromPhone = this.user.phone===this.user.userProfile.telegram;
          }
          this.updateState.stateSuccess();
          this.updateState.modalClose();
          this.$store.commit('alertSuccess', 'Успешно сохранено');

          this.$router.push({path:'../../../user/'+this.user.type+'/'+this.user.id+'/'});
        })
        .catch((e) =>{
          this.updateState.stateError();
          this.$store.commit('alertError', e.response.data.message);
          this.updateState.modalClose();
          console.log(e)
        });
    },
    deleteAction(){
      this.deleteState.stateLoading();
      axios.delete(Api.host+'/user/'+this.$route.params.id+'/')
        .then(() =>{
          this.deleteState.stateSuccess();
          this.deleteState.modalClose();
          this.$store.commit('alertSuccess', 'Успешно удалено');
          this.$router.push({path:'../../'});
        })
        .catch((e) =>{
          this.deleteState.stateError();
          this.deleteState.modalClose();
          this.$store.commit('alertError', e.response.data.message);
          console.log(e)
        });
    },
    visitCreate() {
      window.open('/panel/visit?userId='+this.user.id, '_blank');
    }
  },
  filters: {
    dateYears: function(date){
      if (date==null) return '';
      if (date.length!==10) return '';
      return '('+moment(date, 'DD.MM.YYYY').fromNow(true)+')';
    },
  },
  destroyed() {
  }
}
</script>