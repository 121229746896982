<template>
  <div>
  <dialog-view :open="mergeUsersModal" title="Объединить пользователей" @cancel="mergeUsersModal=false;this.$emit('update:mergeUsersModal',false);">
        <template v-slot:content>
          <v-stepper v-model="mergeStep">
            <v-stepper-header>
              <v-stepper-step
                :complete="mergeStep > 1"
                step="1"
              >
              Шаг 1
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                :complete="mergeStep > 2"
                step="2"
              >
              Шаг 2
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3">
                Объединить
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                
              <div class="h5">
                Выберите основную карту клиента
              </div>

              <user-autocomplete-single name="Основная карта" :select.sync="toUser" showId class="my-5"/>
  
                <v-row class="my-5" v-if="toUser">
                  <v-col class="col-auto">
                    <div class="caption">Id:</div>
                    <div>{{ toUser.id }}</div>
                  </v-col>
                  <v-col class="col-auto">
                    <v-divider
                      vertical
                    ></v-divider>
                  </v-col>
                  <v-col class="col-auto">
                    <div class="caption">Дата рождения:</div>
                    <div>{{ $tools.date(toUser.userProfile.birth, 'DD.MM.YYYY') }}</div>
                  </v-col>
                </v-row>

                <v-btn
                  color="primary"
                  :disabled="!toUser"
                  @click="mergeStep = 2"
                >
                  Далее
                </v-btn>


              </v-stepper-content>

              <v-stepper-content step="2">
                <div class="h5">
                  Выберите дубликат карты
                </div>

                <user-autocomplete-single name="Карта донор" :select.sync="fromUser" class="my-5" showId :exclude="toUser ? [toUser.id] : false"/>
    
                <v-row class="my-5" v-if="fromUser">
                  <v-col class="col-auto">
                    <div class="caption">Id:</div>
                    <div>{{ fromUser.id }}</div>
                  </v-col>
                  <v-col class="col-auto">
                    <v-divider
                      vertical
                    ></v-divider>
                  </v-col>
                  <v-col class="col-auto">
                    <div class="caption">Дата рождения:</div>
                    <div>{{ $tools.date(fromUser.userProfile.birth, 'DD.MM.YYYY') }}</div>
                  </v-col>
                </v-row>
                  
                <v-btn
                  color="primary"
                  :disabled="!fromUser"
                  @click="mergeStep = 3"
                >
                  Далее
                </v-btn>

                <v-btn text @click="mergeStep = 1">
                  Назад
                </v-btn>
              </v-stepper-content>

              <v-stepper-content step="3">
                
                  <div class="mb-5" v-if="fromUser&&toUser">
                    <v-alert                   
                        outlined
                        type="warning"
                        prominent
                        border="left"
                    >
                      Вы уверены, что хотите объединить карты?
                    </v-alert>
                    <div class="text-center">
                      <v-chip>id:{{ fromUser.id}} {{fromUser.lastName }} {{fromUser.firstName}} {{fromUser.secondName}}</v-chip>
                      <div class="my-3">в</div>
                      <v-chip>id:{{toUser.id}} {{toUser.lastName }} {{toUser.firstName}} {{toUser.secondName}}</v-chip>
                    </div>
                  </div>
                  <div class="text-center my-5">
                    <v-btn
                      color="primary"
                      @click="mergeUsers()"
                      :loading="mergeState.isLoading"
                    >
                      Объединить
                    </v-btn>
                  </div>

                  <v-btn text @click="mergeStep = 2">
                    Назад
                  </v-btn>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </template>
      </dialog-view>

      <dialog-view
          title="Удалить пользователя?"
          :open="deleteUserModal"
          @cancel="deleteUserModal = false;this.fromUser = null"
      >
        <template v-slot:content>
          <v-alert
            outlined
            type="warning"
            prominent
            border="left"
            class="mb-0"
            v-if="fromUser"
          >
            <div class="ml-3">
              id:{{ fromUser.id }}<br>
              {{fromUser.lastName }} {{fromUser.firstName}} {{fromUser.secondName}}
            </div>
          </v-alert>
        </template>
        <template v-slot:actions>
          <v-btn text color="primary" @click="deleteUser(fromUser.id);deleteUserModal = false">ОК</v-btn>
        </template>
      </dialog-view>
  </div>  
</template>

<script>
import Api from "@/Api";
import State from "@/plugins/state";
import DialogView from "@/components/DialogView";
import UserAutocompleteSingle from "@/componentsV2/custom/UserAutocompleteSingle.vue";

export default {
  components: {
    DialogView,
    UserAutocompleteSingle
    //Log,
  },
  props: ['mergeUsersModal'],
  data: () => ({
    mergeState: new State(),
    deleteUserModal: false,
    mergeStep: 1,
    fromUser: null,
    toUser: null,

  }),
  mounted(){

  },
  methods: {
    mergeUsers() {
      let data = {
        'fromUserId':this.fromUser.id,
        'toUserId':this.toUser.id
      };
      this.mergeState.stateLoading();
      Api.service.user.merge(data, (data)=>{
        this.mergeUsersModal = false
        this.mergeStep = 1
        this.toUser = null
        this.deleteUserModal = true

        this.$store.commit('alertSuccess', data.message);
        this.mergeState.stateSuccess();
      }, (e)=>{
        this.$store.commit('alertError', e.response.data.message);
        this.mergeState.stateError();
      });
    },
    deleteUser(id) {
      Api.service.user.delete(id, ()=>{
        this.fromUser = null;
        this.$store.commit('alertSuccess', 'Успешно удалено');
        this.$emit('update');
      }, (e)=>{
        this.$store.commit('alertError', e.response.data.message);
      });
    },
  }
}
</script>