<template>
  <v-dialog
      v-model="loadState.isModalOpen"
      fullscreen
      persistent
      no-click-animation
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
  >
    <v-card class="grey lighten-4">
      <v-toolbar dark :color="!$vuetify.theme.dark ? 'rgb(2 146 77)' : 'rgb(40 40 40)'" max-height="64px">
        <btn-question :loading="loadState.isLoading" icon class="mr-2" title="Сохранить мед запись?">
          <v-icon title="Закрыть">mdi-close</v-icon>
          <template v-slot:footer="{isOpen}">
            <v-btn text @click="isOpen(false);$emit('update:medRecordId', null);">Выйти без сохранения</v-btn>
            <v-spacer/>
            <v-btn text @click="isOpen(false);saveAction(false);$emit('update:medRecordId', null);" color="primary">Сохранить</v-btn>
          </template>
        </btn-question>
        <!-- <v-btn icon @click="saveAction(false);$emit('update:medRecordId', null);" :loading="loadState.isLoading">
          <v-icon title="Закрыть">mdi-close</v-icon>
        </v-btn> -->
        <v-toolbar-title v-if="loadState.isSuccess">
          <div class="caption">Мед.Запись</div>
          <div class="body-1"><!--Ruslan-->{{ medRecord.name!=null && medRecord.name!='' ? medRecord.name : medRecord.medRecordTemplate.name }}<!--Ruslan----></div>
        </v-toolbar-title>
        <v-spacer/>
        <div class="d-inline-block mr-2">
          <log entity-name="MedRecord" :entity-id="medRecordId"/>
        </div>
        <v-divider class="mx-2" vertical/>
        <speech-assistant rules="divideByDot"/>
        <v-dialog v-model="deleteState.isModalOpen" width="250">
          <v-card>
            <v-card-title>Удалить?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="deleteState.modalClose()">Отмена</v-btn>
              <v-btn color="red" text @click="deleteAction" :loading="deleteState.isLoading">Удалить</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn icon @click="downloadAction" :loading="downloadState.isLoading">
          <v-icon title="Скачать">mdi-download</v-icon>
        </v-btn>
        <v-btn icon @click="saveAction(true)" :loading="previewState.isLoading">
          <v-icon title="Предпросмотр">mdi-eye</v-icon>
        </v-btn>
        <v-btn :class="{ btnSuccessIco: medRecord?.isPrinted }" icon @click="saveAction(false,medRecordId);" :loading="mediaTemplatePrintLoadState.isLoading">
          <v-icon title="Печать">mdi-printer</v-icon>
        </v-btn>
        <v-btn :class="{ btnSuccessIco: medRecord?.isSent }" icon @click="saveAction(false,false,true)" :loading="emailState.isLoading">
          <v-icon title="Отправить на Email">mdi-email-arrow-right</v-icon>
        </v-btn>
        <v-dialog v-model="emailState.isModalOpen" width="500">
          <v-card>
            <v-card-title>Отправить мед.запись на почту клиента?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="emailState.modalClose()">Отмена</v-btn>
              <v-btn color="primary" text @click="emailAction" :loading="emailState.isLoading">Отправить</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-divider class="mx-2" vertical />
        <v-btn icon @click="deleteState.modalOpen()" title="Удалить">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn icon @click="copyState.modalOpen()" :loading="copyState.isLoading" title="Создать копию">
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
        <v-dialog v-model="copyState.isModalOpen" width="500">
          <v-card>
            <v-card-title>Скопировать мед.запись?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="copyState.modalClose()">Отмена</v-btn>
              <v-btn color="primary" text @click="copyAction" :loading="copyState.isLoading">Скопировать</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-divider class="mx-2" vertical />
        <v-btn class="border-none" outlined @click="saveAction(false)" :loading="saveState.isLoading" title="Сохранить">
          Сохранить <v-icon class="ml-1">mdi-check</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="px-4 py-6 overflow-y-auto">
        <v-row v-if="loadState.isSuccess">
          <v-col cols="auto">
            <v-card width="300" class="pa-4">
              <div class="mb-2">Пациент</div>
              <div class="text-center" v-if="medRecord.user.imageUrl!==null">
                <v-avatar size="120" class="mb-2" color="grey lighten-3">
                  <img :src="medRecord.user.imageUrl" alt=""/>
                </v-avatar>
              </div>
              <div class="title font-weight-regular mb-2">{{medRecord.user.fullName}}</div>
              <v-row no-gutters>
                <v-col cols="6" class="body-2">Пол</v-col>
                <v-col cols="6" class="body-2">{{medRecord.user.userProfile.gender==='male' ? 'Муж.' : 'Жен.'}}</v-col>
                <v-col cols="6" class="body-2">Дата рождения</v-col>
                <v-col cols="6" class="body-2">{{medRecord.user.userProfile.birth|date}} ({{$tools.dateYears(medRecord.user.userProfile.birth,'YYYY-MM-DD')}})</v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea v-model="medRecord.name" label="Название" rows="1" outlined dense auto-grow hide-details="auto"/> 
                </v-col> 
                <v-col cols="12" class="body-2">
                  <label-view title="Дата создания">
                      <template v-slot:content>
                          <v-menu v-model="calendarIsOpen" :close-on-content-click="false" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  v-bind="attrs" v-on="on"
                                  :value="$tools.dateTimeFormat(medRecord.created, 'DD.MM.YYYY')"
                                   class="mt-2 mb-4" outlined dense hide-details="auto" readonly/>
                          </template>
                          <v-date-picker :first-day-of-week="1" v-model="medRecord.created" color="primary" show-current class="mb-4" no-title  @change="calendarIsOpen=false"/>
                          </v-menu>
                      </template>
                  </label-view>
                </v-col> 
                <v-col cols="12">
                  <v-textarea
                      v-model="medRecord.comment"
                      label="Комментарий специалиста"
                      outlined dense hide-details="auto"
                      auto-grow
                      rows="4"
                  />
                </v-col>
                <v-col><checkbox-field class="mb-4" name="Показать в личном кабинете" :value.sync="medRecord.isShowInService"/></v-col>
              </v-row>            
            </v-card>
          </v-col>
          <v-col>
            <v-card>
              <div class="mx-4 mt-4 mb-2">Визит</div>
              <v-expansion-panels accordion multiple v-model="medRecordTemplateFieldGroupSelected" readonly>
                <v-expansion-panel v-for="(medRecordTemplateFieldGroup, index) in medRecord.medRecordTemplate.medRecordTemplateFieldGroups" :key="'medRecordTemplateFieldGroup'+medRecordTemplateFieldGroup.id">
                  <v-expansion-panel-header class="px-3">
                    <div class="title font-weight-regular">
                      {{medRecordTemplateFieldGroup.name}}
                      <v-switch v-model="medRecordTemplateFieldGroupActived[index]" color="green" class="d-inline-block ml-2 mt-0" dense hide-details inset />
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="no-padding">
                    <div
                       v-for="medRecordTemplateField in medRecordTemplateFieldGroup.medRecordTemplateFields"
                       :key="'medRecordTemplateField_'+medRecordTemplateField.id"
                       class="medRecordValueView"
                    >
                      <med-record-update-field :medRecordTemplateField.sync="medRecordTemplateField" :birth="medRecord.user.userProfile.birth|date" :gender="medRecord.user.userProfile.gender" :userId="userId"/>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-dialog
        v-model="previewState.isModalOpen"
        fullscreen
        hide-overlay
        scrollable
        transition="dialog-bottom-transition"
    >
      <v-card class="grey lighten-4">
        <v-toolbar dark :color="!$vuetify.theme.dark ? 'rgb(2 146 77)' : 'rgb(40 40 40)'" max-height="64px">
          <v-btn icon dark @click="previewState.modalClose()">
            <v-progress-circular v-if="loadState.isLoading" indeterminate size="20" width="3"/>
            <v-icon v-else>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-if="loadState.isSuccess">
            <div class="caption">Предосмотр Мед.Запись</div>
            <div class="body-1">{{ medRecord.name!=null && medRecord.name!='' ? medRecord.name : medRecord.medRecordTemplate.name }}</div>
          </v-toolbar-title>
        </v-toolbar>
        <div>
          <iframe
              :src="previewUrl"
              frameBorder="0"
              :style="'width:'+previewWidth+'px;height:'+previewHeight+'px'"
          ></iframe>
        </div>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";
import Log from "@/views/Log/Log";
import moment from "moment";
import MedRecordUpdateField from "@/views/MedRecord/MedRecordUpdateField";
import axios from "axios";
import printJS from "print-js";
import SpeechAssistant from "@/components/SpeechAssistant.vue";
import LabelView from "@/components/LabelView.vue";
import BtnQuestion from "@/components/btnQuestion.vue";
import CheckboxField from "@/componentsV2/base/CheckboxField.vue";

export default {
  components: {
    SpeechAssistant, 
    MedRecordUpdateField, 
    LabelView, 
    Log, 
    BtnQuestion,
    CheckboxField
  },
  props: ['medRecordId', 'userId'],
  data: () => ({
    loadState: new State(),
    medRecord:null,
    medRecordTemplateFieldGroupSelected:[],
    medRecordTemplateFieldGroupActived:[],

    calendarIsOpen: false,

    saveState: new State(),
    deleteState: new State(),
    downloadState: new State(),
    previewState: new State(),
    previewWidth:0,
    previewHeight:0,
    previewUrl:'',
    emailState: new State(),
    copyState: new State(),

    mediaTemplatePrintLoadState: new State(),
    mediaTemplatePrintState: new State(),

    // saveTimer:null
  }),
  watch:{
    medRecordId(){
      if (this.medRecordId==null){
        this.$store.state.speechAssistantMainEnabled = true;
        clearInterval(this.saveTimer);
        this.loadState.modalClose();
      } else{
        this.$store.state.speechAssistantMainEnabled = false;
        this.load();
        this.loadState.modalOpen();

        // this.saveTimer = null;
        // clearInterval(this.saveTimer);
     
        // this.saveTimer = setInterval(() => {
        //   this.saveAction(false);
        // }, 15000);

      }
    },
    // beforeDestroy() {
    //   clearInterval(this.saveTimer);
    // },
    medRecordTemplateFieldGroupActived(){
      this.medRecordTemplateFieldGroupSelected = [];
      this.medRecordTemplateFieldGroupActived.forEach((v, index)=>{
        if (v) this.medRecordTemplateFieldGroupSelected.push(index);
      });
    },
    'medRecord.created'() {
      if (this.loadState.isSuccess) {
        console.log(this.medRecord.created);
        let currentTime = this.$tools.dateTimeFormat(moment(), 'HH:mm:00');
        this.medRecord.created = this.$tools.dateTimeFormat(this.medRecord.created+' '+currentTime, 'YYYY-MM-DD HH:mm:ss');
      }
    }
  },
  filters:{
    date: function(date){
      return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY');
    },
    // dateYears: function(date){
    //   return moment(date, 'YYYY-MM-DD HH:mm:ss').fromNow(true);
    // },
  },
  methods:{
    load(){
      this.loadState.stateLoading();
      Api.service.medRecord.medRecord(this.medRecordId, (data)=>{
        this.loadState.stateSuccess();
        this.medRecord = data;
        this.medRecordTemplateFieldGroupSelected = [];
        this.medRecordTemplateFieldGroupActived = [];

        this.medRecord.medRecordTemplate.medRecordTemplateFieldGroups.forEach((v, index)=>{
          let isOpen = false;
          v.medRecordTemplateFields.forEach((v1)=>{

            //isOpen = v1.value.length>0 ? true : isOpen;

            let medRecordValue = this.medRecord.medRecordValues.filter((v2)=> v2.medRecordTemplateField.id===v1.id);
      
            v1.medRecordValueValue = medRecordValue.length>0 ? medRecordValue[0].value: '';

            isOpen = v1.medRecordValueValue.length>0 ? true : isOpen;
    
          });
          this.medRecordTemplateFieldGroupSelected.push(index);
          this.medRecordTemplateFieldGroupActived[index] = isOpen;
        });
      });
    },

    fieldValueUpdateSave(v1){
      v1._fieldValueUpdateState.stateLoading();
      var formData = new FormData();
      let found = false;
      v1.medRecordTemplateFieldValues.forEach((v2)=>{
        if (v2._valueUpdate!==v2.value){
          formData.append('medRecordTemplateFieldValue_'+v2.id, v2._valueUpdate);
          found = true;
        }
        v2.medRecordTemplateFieldValueOptions.forEach((v3)=>{
          if (v3._valueUpdate!==v3.value){
            formData.append('medRecordTemplateFieldValueOption_'+v3.id, v3._valueUpdate);
            found = true;
          }
        });
      });
      if (found){
        Api.service.medRecord.medRecordTemplateFastUpdate(this.medRecord.medRecordTemplate.id, formData,()=>{
          v1._fieldValueUpdateState.stateSuccess();
          this.load();
        });
      } else {
        v1._fieldValueUpdateState.stateSuccess();
        v1._fieldValueUpdateState.modalClose();
      }

    },

    saveAction(isPreview = false,printID=false,sendToEmail=false){
      this.saveState.stateLoading();
      var formData = new FormData();
      if (this.medRecord.comment!==null)
        formData.append(
            'comment',
            this.medRecord.comment
        );
      if (this.medRecord.name!==null)    
      formData.append(
          'name',
          this.medRecord.name
      );

      formData.append(
          'created',
          this.medRecord.created
      );

      formData.append(
          'isShowInService',
          this.medRecord.isShowInService
      );

      this.medRecord.medRecordTemplate.medRecordTemplateFieldGroups.forEach((v, index)=>{
        v.medRecordTemplateFields.forEach((v1)=>{
          formData.append(
              'medRecordTemplateField_'+v1.id,
              this.medRecordTemplateFieldGroupActived[index] ? v1.medRecordValueValue : ''
          );
        });
      });

      //fix < >
      function escapeCharacters(string) {
        var pattern = /{{(.*?)}}/g;
        var matches = string.match(pattern);

        if (matches) {
          for (var i = 0; i < matches.length; i++) {
            var match = matches[i];
            var escaped = match.replace(/</g, '&lt;').replace(/>/g, '&gt;');
            string = string.replace(match, escaped);
          }
        }

        return string;
      }

      let entries = [...formData];

      entries.forEach(function(pair) {
        var key = pair[0];
        var value = pair[1];
        var substring = "{{381:";//exclude blood test

        if (value&&!value.includes(substring)) {
          formData.set(key, escapeCharacters(value));
        }
      });


      Api.service.medRecord.medRecordUpdate(this.medRecordId, formData,()=>{
        this.saveState.stateSuccess();
        // this.$emit('update:medRecordId', null);
        //this.$parent.load();
        if(isPreview){
          this.previewState.stateLoading();
          this.previewWidth = window.innerWidth;
          this.previewHeight = window.innerHeight-64;
          this.previewUrl = null;
          Api.service.medRecord.medRecordDownload(this.medRecordId, (data)=>{
            for(let index in data){
              this.previewUrl = 'https://view.officeapps.live.com/op/embed.aspx?src='+data[index];
              this.previewState.modalOpen();
            }
            this.previewState.stateSuccess();
          });

          /*
          this.previewState.stateLoading();
          Api.service.medRecord.medRecordPreview(this.medRecord.id, (data)=>{
            this.previewState.stateSuccess();
            printJS({ printable: data, type: 'image', header: ''});
          });

           */
        }
        if(printID) {
          this.mediaTemplatesPrint(printID);
        }
        if(sendToEmail){
          this.emailState.modalOpen();
        }
      }, ()=>{
        this.saveState.stateError();
      });
    },

    deleteAction(){
      this.deleteState.stateLoading();
      Api.service.medRecord.medRecordDelete(this.medRecordId, ()=>{
        this.deleteState.stateSuccess();
        this.$emit('update:medRecordId', null);
        // this.$parent.load();
      });
    },
    downloadAction(){
      this.saveAction();
      let _this = this
      let saveEventTimer = setInterval(function() {
        if (_this.saveState.isSuccess) {
          clearInterval(saveEventTimer);
          download()
        }
      }, 1);
     
      function download() {
        _this.downloadState.stateLoading();
        Api.service.medRecord.medRecordDownload(_this.medRecordId, (data)=>{
          for(let index in data){
            const a = document.createElement('a');
            a.href = data[index];
            a.download = _this.medRecord.medRecordTemplate.name+' ('+_this.medRecord.user.fullName+')'+'.docx';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
          _this.downloadState.stateSuccess();
        });
      }  

    },
    emailAction(){
      this.emailState.stateLoading();
      Api.service.medRecord.medRecordEmailSend(this.medRecord.id, ()=>{
        this.emailState.stateSuccess();
        this.emailState.modalClose();
        this.medRecord.isSent = true;
        this.$store.commit('alertSuccess', 'Успешно отправлено');
      });
    },
    copyAction(){
      this.copyState.stateLoading();
      Api.service.medRecord.medRecordCopy(this.medRecord.id, (data)=>{
        this.copyState.stateSuccess();
        this.copyState.modalClose();
        this.loadState.modalClose();
        this.$store.commit('alertSuccess', 'Успешно скопировано');
        this.$emit('medRecordCopied', data.id);
      });
    },
    mediaTemplatesPrint(id){
      this.saveAction(false);
      this.mediaTemplatePrintLoadState.modalClose();
      this.mediaTemplatePrintState.stateLoading();
      console.log(id)
      axios.get(Api.host+`/medRecord/medRecord/${id}/preview/`)
        .then(r=>{
          let images = [];
          r.data.forEach(v=>{
            images.push(v);
          });
          printJS({ printable: images, type: 'image', header: ''});
          this.medRecord.isPrinted = true;
          this.mediaTemplatePrintState.stateSuccess();
        }).catch(e=>{
          console.log(e);
          this.mediaTemplatePrintState.stateError(e.response.data.errors);
        });
    },
  }
}
</script>

<style lang="scss">
  .border-none {
    border: none;
  }
  .no-padding {
    &>* {
      padding: 0;
    }
  }
</style>