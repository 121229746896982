<template>
  <div>
    <slot v-bind:values="values">
      <span v-if="values.length===0">{{emptyText}}</span>
      <span v-for="(v,k) in values" :key="k">
        <span v-if="isRawValue===true">
          {{v.value}}
        </span>
        <span v-else>
          <span v-if="typeof v.url==='undefined'">{{v.valueName}}</span>
          <a v-else :href="v.url" target="_blank" class="text-decoration-none">
            <v-icon small class="mr-2">mdi-open-in-new</v-icon>{{v.valueName}}
          </a>
        </span>
        <span v-if="k<values.length-1">,</span>
      </span>
    </slot>
  </div>
</template>
<script>
export default {
  props:[
    'name',
    'operationGroup',
    'operation',
    'urlMask',
    'isRawValue',
    'emptyText',
  ],
  data: () => ({
    values:[],
  }),
  mounted(){
    this.values = this.valueFind(this.name, this.operationGroup, this.operation);
  },
  watch:{
    operation(){
      this.values = this.valueFind(this.name, this.operationGroup, this.operation);
    }
  },
  methods:{
    valueFind(name, operationGroup, operation){
      let arr = [];
      if(typeof operation!=='undefined'){
        operation.analyticValueCredits.forEach(v=>{
          if(v.analyticTypeCredit.name===name)
            arr.push({value:v.value, valueName:v.valueName});
        })
        operation.analyticValueDebets.forEach(v=>{
          if(v.analyticTypeDebet.name===name)
            arr.push({value:v.value, valueName:v.valueName});
        })
        operation.operationGroup.analyticValueGroups.forEach(v=>{
          if(v.analyticType.name===name)
            arr.push({value:v.value, valueName:v.valueName});
        })
      } else {
        operationGroup.operations.forEach(operation=> {
          operation.analyticValueCredits.forEach(v => {
            if (v.analyticTypeCredit.name === name)
              arr.push({value: v.value, valueName: v.valueName});
          })
          operation.analyticValueDebets.forEach(v => {
            if (v.analyticTypeDebet.name === name)
              arr.push({value: v.value, valueName: v.valueName});
          })
        });
        operationGroup.analyticValueGroups.forEach(v=>{
          if(v.analyticType.name===name)
            arr.push({value:v.value, valueName:v.valueName});
        })
      }

      let arr1 = [];
      arr.forEach(v=>{
        if(arr1.filter(v1=>v1.value===v.value).length===0)
          arr1.push(v);
      })

      if(typeof this.urlMask!=='undefined')
        arr.forEach(v=>{
          v.url = this.urlMask.replace('#id#', v.value)
        })

      return arr1;
    }
  }
}
</script>