<template>
  <div>
    <portal to="tabs">
      <v-tabs v-model="tabSelected" background-color="transparent">
        <v-tab>Визиты</v-tab>
        <v-tab>Мед.планы</v-tab>
        <v-tab>Мед.записи</v-tab>
      </v-tabs>
    </portal>
    <desktop-visits v-if="tabSelected===0" :userSpecDesktop="userSpecSelected" @userSpecSelected="(v)=>{userSpecSelected=v}"/>
    <med-plans v-if="tabSelected===1" :userSpecDesktop="userSpecSelected"/>
    <med-records v-if="tabSelected===2" :userSpecDesktop="userSpecSelected"/>
  </div>
</template>

<script>
import DesktopVisits from "@/views/Desktop/DesktopVisits.vue";
import MedPlans from "@/views/MedPlan/MedPlans.vue";
import MedRecords from "@/views/MedRecord/MedRecords.vue";

export default {
  components: {DesktopVisits, MedPlans, MedRecords},
  data:()=>({
    tabSelected:null,
    userSpecSelected: null,
  })
}
</script>