<template>
  <div :class="typeof full==='undefined' ? 'd-inline-block' : 'mt-4'">

    <v-btn
        v-if="typeof full==='undefined'"
        @click.stop="playToggle()"
        :disabled="callGroup.callRecordFile===null"
        icon small class="mr-2"
        style="background-color: rgba(0,0,0,0.05)"
    >
      <v-progress-circular
          v-if="callGroup.callRecordFile!==null && progress>0"
          :value="progress"
          rotate="-90"
          width="2"
          size="28"
          class="position-absolute"
      />
      <v-icon small>{{playing ? 'mdi-stop' : 'mdi-play'}}</v-icon>
    </v-btn>

    <v-sheet :class="typeof full==='undefined' ? 'd-none' : ''">
      <alert v-if="callGroup.callRecordFile===null">Запись еще недоступна или удалена</alert>
      <sheet-group v-else class="pb-4">
        <div class="caption">Запись звонка</div>
        <audio :src="audio" controls ref="audio" class="mt-2">
          Прослушивание не поддерживается вашим браузером.
          <a :href="audio" target="_blank">Скачать</a>.
        </audio>
        <v-row no-gutters class="mt-2">
          <v-col v-for="v in [0.5,1,1.5,2]" :key="v" class="px-1">
            <v-btn block small depressed @click="speedUpdate(v)" :class="v===speed ? 'v-btn--active' :''">{{v}}x</v-btn>
          </v-col>
        </v-row>
      </sheet-group>
    </v-sheet>
  </div>
</template>
  
<script>
import Alert from "@/componentsV2/base/Alert.vue";
import SheetGroup from "../../componentsV2/base/SheetGroup.vue";

export default {
  components: {SheetGroup, Alert},
  props: ['callGroup','full'],
  data: () => ({
    speed:1,
    progress:0,
    progressTimer:null,
    audio:null,
    playing: false,
    localCallRecordFile: null,
  }),
  watch: {
    callGroup(){
      this.loadAudio(typeof this.full!=='undefined')
    },
    playing(v){
      if (v) {
        this.progressTimer = setInterval(() => {
          if (typeof this.$refs.audio !== 'undefined')
            this.progress = 100*(this.$refs.audio.currentTime/this.$refs.audio.duration);
        }, 1000)
      } else {
        if (this.progressTimer !== null) clearInterval(this.progressTimer);
      }
    }
  },
  mounted(){
    this.loadAudio(typeof this.full!=='undefined')
  },
  beforeDestroy() {
    if(this.progressTimer!==null)
      clearInterval(this.progressTimer);
    this.resetAudio();
  },
  methods: {
    resetAudio(){
      this.progress = 0;
      if(typeof this.$refs.audio!=='undefined')
        this.$refs.audio.currentTime = 0;
      if (typeof localStorage.callRecordSpeed !== 'undefined') {
        this.speed = parseFloat(localStorage.callRecordSpeed);
        this.$nextTick(() => {
          if (typeof this.$refs.audio !== 'undefined')
            this.$refs.audio.playbackRate = this.speed;
        })
      }
    },
    updatePlaying(){
      this.playing = typeof this.$refs.audio==='undefined' ? false : !this.$refs.audio.paused;
    },
    loadAudio(loadFile=false){
      if(this.callGroup.callRecordFile===null)
        return null;

      if(typeof this.$refs.audio!=='undefined'){
        this.$refs.audio.onplaying = this.updatePlaying
        this.$refs.audio.onpause = this.updatePlaying
        this.$refs.audio.onended = ()=>{
          this.resetAudio();
          this.updatePlaying();
        }
      }

      if(loadFile) {
        if (this.localCallRecordFile !== this.callGroup.callRecordFile) {
          this.localCallRecordFile = this.callGroup.callRecordFile;
          this.audio = '/callRecord/' + this.localCallRecordFile;
          this.resetAudio();
        }
      }
    },
    playToggle(){
      this.loadAudio(true);
      this.resetAudio();
      if(this.playing) {
        this.$refs.audio.pause();
      } else {
        if(this.$refs.audio.readyState===0){
          this.$refs.audio.oncanplay = ()=>{
            this.$refs.audio.play();
            this.$refs.audio.oncanplay = null;
            this.updatePlaying();
          }
        } else {
          this.$refs.audio.play();
        }
      }
    },

    speedUpdate(v){
      localStorage.callRecordSpeed = v;
      this.speed = v;
      this.$refs.audio.playbackRate = v;
    }
  }
}
</script>