<template>
  <div>
    <div class="position-fixed">
      <v-sheet class="px-2 py-1 my-n1" color="grey darken-1" dark rounded>
        {{name}}
      </v-sheet>
    </div>
    <div class="pt-5"></div>
  </div>
</template>

<script>
export default {
  props:['name']
}
</script>