<template>
  <dialog-view
      :open="localOperationGroup!==null"
      @cancel="localOperationGroup=null"
      width="1280"
      :title="localOperationGroup===null ? '' : 'Хоз. операция #'+localOperationGroup.id"
  >
    <template v-slot:header>
      <div v-if="localOperationGroup!==null" class="mx-1 mb-n3">
        <v-row no-gutters>
          <v-col cols="auto" class="pr-4">
            <field-view
                label="Дата"
                type="dateTime"
                :value="localOperationGroup.created"
                :url="'/api/finance/operationGroup/'+localOperationGroup.id+'/'" field="created"
            />
          </v-col>
          <v-col cols="auto" class="pr-4">
            <label-view title="Тип платежа">
              <field-renders :item="localOperationGroup" :header="{type:'operationGroupOperationsByPayType'}"/>
            </label-view>
          </v-col>
          <v-col cols="auto" class="pr-4">
            <label-view title="Способ расчета">
              <field-renders :item="localOperationGroup" :header="{type:'operationGroupOperationsBySourceType'}"/>
            </label-view>
          </v-col>
          <v-col cols="auto" class="pr-4">
            <label-view title="Касса">
              <operation-analytic-finder name="Касса" :operationGroup="localOperationGroup" emptyText="Не указано" />
            </label-view>
          </v-col>
          <v-col class="pr-4">
            <label-view title="Тип хоз.операции">
              {{localOperationGroup.operationGroupType.name}}
            </label-view>
          </v-col>
          <v-col cols="auto" class="pr-4">
            <label-view title="Скидка">
              <field-renders :item="localOperationGroup" :header="{type:'operationGroupDiscount'}"/>
            </label-view>
          </v-col>
          <v-col cols="auto" class="pr-4">
            <label-view title="Итого">
              <field-renders :item="localOperationGroup" :header="{type:'operationGroupTotal'}"/>
            </label-view>
          </v-col>
          <v-col cols="12" class="mb-3">
            <field-view
                label="Комментарий" :value="localOperationGroup.comment"
                :url="'/api/finance/operationGroup/'+localOperationGroup.id+'/'" field="comment" type="text"
                class="mb-0"
            />
          </v-col>
          <v-col cols="12" class="mb-3">
            <v-row no-gutters>
              <v-col cols="auto">
                <finance-operation-group-actions :operation-group="localOperationGroup" @fixed="$emit('fixed')"/>
              </v-col>
              <v-col cols="auto" class="mr-2">
                <operation-analytic-finder name="Создал" :operationGroup="localOperationGroup">
                  <template v-slot:default="{values}">
                    <v-btn
                        v-for="(v,k) in values" :key="k"
                        @click="$tools.openUrl('/panel/user/client/#id#/'.replace('#id#', v.value), true)"
                        color="grey lighten-3"
                        small depressed
                        class="mr-2"
                    >
                      <v-icon small class="mr-2">mdi-open-in-new</v-icon>
                      <v-sheet color="transparent" max-width="250" class="text-overflow">Создал: {{v.valueName}}</v-sheet>
                    </v-btn>
                  </template>
                </operation-analytic-finder>
              </v-col>
              <v-col cols="auto">
                <operation-analytic-finder name="Клиент" :operationGroup="localOperationGroup">
                  <template v-slot:default="{values}">
                    <v-btn
                        v-for="(v,k) in values" :key="k"
                        @click="$tools.openUrl('/panel/user/client/#id#/'.replace('#id#', v.value), true)"
                        color="grey lighten-3"
                        small depressed
                        class="mr-2"
                    >
                      <v-icon small class="mr-2">mdi-open-in-new</v-icon>
                      <v-sheet color="transparent" max-width="250" class="text-overflow">Клиент: {{v.valueName}}</v-sheet>
                    </v-btn>
                  </template>
                </operation-analytic-finder>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-tabs v-model="tabSelected" background-color="transparent">
          <v-tab>Услуги / Товары</v-tab>
          <v-tab>Фин. операции</v-tab>
          <v-tab>Хоз. операция</v-tab>
        </v-tabs>
      </div>
    </template>
    <template v-slot:content>
      <div v-if="localOperationGroup!==null">
        <div v-if="tabSelected===0">
          <progress-page v-if="visitsState.isLoading" :loading="visitsState.isLoading"/>
          <div v-if="visitsState.isSuccess">
            <div v-for="(v,k) in visitsState.modalData" :key="v.id">
              <v-row class="rowSmall">
                <v-col cols="6">
                  <span class="body-2 font-weight-medium">Визит #{{v.id}}</span>
                  <v-btn color="primary" text class="ml-4" small @click="visitId=v.id">
                    Подробнее
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-right"></v-col>
                <v-col cols="auto">
                  <label-view title="Статус">
                    <template v-slot:content>
                      <v-chip
                          :color="$tools.findById(Api.model.Visit.statuses, v.status).color"
                          small
                          dark class="status white--text" pill
                      >
                        <span class="name">{{$tools.findById(Api.model.Visit.statuses, v.status).name}}</span>
                      </v-chip>
                    </template>
                  </label-view>
                </v-col>
                <v-col>
                  <label-view title="Специалист">
                    <template v-slot:content>
                      <div>{{$tools.userFullName(v.userSpec.user)}}</div>
                      <div>{{v.userSpec.spec.name}}</div>
                    </template>
                  </label-view>
                </v-col>
                <v-col cols="3">
                  <label-view title="Кабинет / Место">
                    <template v-slot:content>
                      <div>{{v.comPlace.comRoom.sid}} {{v.comPlace.comRoom.name}}</div>
                      <div>{{v.comPlace.name}}</div>
                    </template>
                  </label-view>
                </v-col>
                <v-col cols="3">
                  <div>
                    <label-view title="Дата / Время">
                      <template v-slot:content>
                        <div>{{$tools.dateFull(v.begin)}}</div>
                        <div>{{$tools.time(v.begin)}} - {{$tools.time(v.end)}}</div>
                      </template>
                    </label-view>
                  </div>
                </v-col>
                <v-col cols="12" class="py-0">
                  <label-view title="Услуги">
                    <template v-slot:content>
                      <v-simple-table dense>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Название</th>
                            <th class="text-center">Кол-во шт</th>
                            <th class="text-right">Цена</th>
                            <th class="text-center">Скидка</th>
                            <th class="body-2 font-weight-medium text-right">Итого</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="visitService in v.visitServices" :key="visitService.id">
                            <td>{{ visitService.id }}</td>
                            <td class="py-2">
                              <div>{{ visitService.name }}</div>
                              <v-chip v-if="visitService._financeOperation" class="mt-1 mr-2" small color="primary">Эта операция</v-chip>
                              <v-chip class="mt-1 mr-2" small outlined>{{visitService.duration}} мин.</v-chip>
                              <v-chip v-if="visitService.mode==='oms'" class="mt-1 mr-2" small outlined>
                                ОМС<v-divider class="mx-2" vertical/>{{visitService.insur.name}}
                              </v-chip>
                              <v-chip v-if="visitService.mode==='dms'" class="mt-1 mr-2" small outlined>
                                ДМС<v-divider class="mx-2" vertical/>{{visitService.insur.name}}
                              </v-chip>
                              <v-chip v-if="visitService.mode==='contract'" class="mt-1 mr-2" small outlined>
                                Контракт<v-divider class="mx-2" vertical/>{{visitService.insur.name}}
                              </v-chip>
                              <v-chip v-if="visitService.medPlan!==null" class="mt-1 mr-2" small outlined>
                                План лечения<v-divider class="mx-2" vertical/>{{visitService.medPlan.name}}
                              </v-chip>
                              <div v-if="!$tools.isEmpty(visitService.comment)">
                                <v-icon small>mdi-comment-text-outline</v-icon>
                                <div class="caption mt-n5 ml-5">{{visitService.comment}}</div>
                              </div>
                            </td>
                            <td>
                              <div class="text-center">{{visitService.count}}</div>
                            </td>
                            <td class="text-right">
                              {{ $tools.price(visitService.price) }}
                            </td>
                            <td>
                              <div class="text-center">{{$tools.price(visitService.discount)}}</div>
                            </td>
                            <td class="text-right font-weight-medium">
                              {{ $tools.price((visitService.price*visitService.count)-visitService.discount)}}
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </template>
                  </label-view>
                </v-col>
              </v-row>
              <v-divider v-if="k<visitsState.modalData.length-1" class="my-4"/>
            </div>
          </div>
        </div>
        <div v-if="tabSelected===1" class="ma-n4">
          <v-expansion-panels accordion multiple>
            <v-expansion-panel v-for="(v,k) in localOperationGroup.operations" :key="k">
              <v-expansion-panel-header class="px-4 pa-2">
                <v-row no-gutters align="center">
                  <v-col>
                    <div class="body-2">#{{v.id}} {{v.operationType.name}}</div>
                  </v-col>
                  <v-col cols="auto">
                    <v-sheet color="transparent" width="100">
                      <div class="caption">Количество</div>
                      <div class="body-2">{{v.count}}</div>
                    </v-sheet>
                  </v-col>
                  <v-col cols="auto">
                    <v-sheet color="transparent" width="100">
                      <div class="caption">Скидка</div>
                      <div class="body-2">{{$tools.price(v.discount)}}</div>
                    </v-sheet>
                  </v-col>
                  <v-col cols="auto">
                    <v-sheet color="transparent" width="100">
                      <div class="caption">Сумма</div>
                      <div class="body-2">{{$tools.price(v.amount)}}</div>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <sheet-group>
                  <div class="caption mb-2">Аналитика</div>
                  <field-renders class="mb-4" :item="v" :header="{text:'Аналитика', type:'operationAnalytics_detail'}"/>
                </sheet-group>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div v-if="tabSelected===2">
          <v-row class="mb-4">
            <v-col cols="3">
              <sheet-group>
                <div class="caption mb-2">Хоз. операция</div>
                <div class="body-2 mb-4">#{{localOperationGroup.id}} от {{$tools.dateTime(localOperationGroup.created)}}</div>
              </sheet-group>
            </v-col>
            <v-col cols="3">
              <sheet-group>
                <div class="caption mb-2">Тип хоз. операции</div>
                <div class="body-2 mb-4">#{{localOperationGroup.operationGroupType.id}} {{localOperationGroup.operationGroupType.name}}</div>
              </sheet-group>
            </v-col>
            <v-col cols="6">
              <sheet-group>
                <div class="caption mb-2">Комментарий</div>
                <div class="body-2 mb-4">
                  <div v-if="!$tools.isEmpty(localOperationGroup.comment)">{{localOperationGroup.comment}}</div>
                  <div v-else>-</div>
                </div>
              </sheet-group>
            </v-col>
            <v-col cols="6">
              <sheet-group>
                <div class="caption mb-2">Возврат</div>
                <div class="body-2 mb-4">
                  <div v-if="localOperationGroup.reverseBy!=null">возвращено хоз.операцией #{{localOperationGroup.reverseBy}}</div>
                  <div v-else-if="localOperationGroup.reverse!=null">возвращает хоз.операцию #{{localOperationGroup.reverse}}</div>
                  <div v-else>-</div>
                </div>
              </sheet-group>
            </v-col>
            <v-col cols="6">
              <sheet-group>
                <div class="caption mb-2">Сторн</div>
                <div class="body-2 mb-4">
                  <div v-if="localOperationGroup.fixedBy!=null">сторнировано хоз.операцией #{{localOperationGroup.fixedBy.id}}</div>
                  <div v-else-if="localOperationGroup.fixed!=null">сторнирует хоз.операцию #{{localOperationGroup.fixed.id}}</div>
                  <div v-else>-</div>
                </div>
              </sheet-group>
            </v-col>
          </v-row>
          <sheet-group>
            <div class="caption mb-2">Вся аналитика</div>
            <field-renders class="mb-4" :item="localOperationGroup" :header="{type:'operationGroupAnalytics_detail'}"/>
          </sheet-group>
        </div>

        <visit :visitId.sync="visitId"/>
      </div>
    </template>
  </dialog-view>
</template>

<script>
import DialogView from "@/components/DialogView.vue";
import LabelView from "@/components/LabelView.vue";
import FieldView from "@/components/FieldView.vue";
import FieldRenders from "@/componentsV2/base/FieldRenders.vue";
import FinanceOperationGroupActions from "@/views/Finance/FinanceOperationGroupActions.vue";
import OperationAnalyticFinder from "../../componentsV2/custom/OperationAnalyticFinder.vue";
import SheetGroup from "../../componentsV2/base/SheetGroup.vue";
import {
  Entity_ComPlace, Entity_ComRoom, Entity_Insur, Entity_MedPlan, Entity_Spec, Entity_User, Entity_UserSpec,
  Entity_Visit, Entity_VisitService
} from "../../../EntityStoreCacheService";
import ProgressPage from "../../components/ProgressPage.vue";
import State from "../../plugins/state";
import Api from "../../Api";
import Visit from "@/views/Visit/Visit";

export default {
  components: {
    ProgressPage,
    SheetGroup,
    OperationAnalyticFinder,
    FinanceOperationGroupActions,
    FieldRenders,
    FieldView,
    LabelView,
    DialogView,
    Visit
  },
  props:['operationGroup'],
  data: () => ({
    localOperationGroup: null,
    tabSelected:0,
    visitsState: new State(),
    visitId:null,
  }),
  watch:{
    operationGroup(){
      this.localOperationGroup = this.operationGroup;
      if(this.localOperationGroup!==null){
        this.visitsLoad();
      }
    },
    localOperationGroup(){
      this.$emit('update:operationGroup', this.localOperationGroup)
    }
  },
  computed: {
    OperationAnalyticFinder() {
      return OperationAnalyticFinder
    },
    Api() {
      return Api
    }
  },
  methods:{
    visitsLoad(){
      let arr = OperationAnalyticFinder.methods.valueFind('Услуги / Товары', this.localOperationGroup);
      if(arr.length>0){
        let rb = new Entity_Visit()
            .selects(Entity_Visit.id)
            .selects(Entity_Visit.begin)
            .selects(Entity_Visit.end)
            .selects(Entity_Visit.status)

            .selects([Entity_Visit.userSpec, Entity_UserSpec.id].join('.'))
            .selects([Entity_Visit.userSpec, Entity_UserSpec.spec, Entity_Spec.name].join('.'))
            .selects([Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.firstName].join('.'))
            .selects([Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.lastName].join('.'))
            .selects([Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.secondName].join('.'))

            .selects([Entity_Visit.comPlace, Entity_ComPlace.id].join('.'))
            .selects([Entity_Visit.comPlace, Entity_ComPlace.name].join('.'))
            .selects([Entity_Visit.comPlace, Entity_ComPlace.comRoom, Entity_ComRoom.sid].join('.'))

            .selects([Entity_Visit.visitServices, Entity_VisitService.id].join('.'))
            .selects([Entity_Visit.visitServices, Entity_VisitService.name].join('.'))
            .selects([Entity_Visit.visitServices, Entity_VisitService.comment].join('.'))
            .selects([Entity_Visit.visitServices, Entity_VisitService.duration].join('.'))
            .selects([Entity_Visit.visitServices, Entity_VisitService.price].join('.'))
            .selects([Entity_Visit.visitServices, Entity_VisitService.count].join('.'))
            .selects([Entity_Visit.visitServices, Entity_VisitService.discount].join('.'))

            .selects([Entity_Visit.visitServices, Entity_VisitService.mode].join('.'))
            .selects([Entity_Visit.visitServices, Entity_VisitService.insur, Entity_Insur.id].join('.'))
            .selects([Entity_Visit.visitServices, Entity_VisitService.insur, Entity_Insur.name].join('.'))
            .selects([Entity_Visit.visitServices, Entity_VisitService.medPlan, Entity_MedPlan.id].join('.'))
            .selects([Entity_Visit.visitServices, Entity_VisitService.medPlan, Entity_MedPlan.name].join('.'))
        //.selects(Entity_OperationGroup.created)

        let q = '';
        arr.forEach(v=>{
          q += [Entity_Visit.entity, Entity_Visit.visitServices, Entity_VisitService.id].join('.')+'=? OR ';
          rb._filterParams.push(v.value)
        });

        rb.filterAnd(q.substring(0, q.length-4));

        rb.addStateCallback((v)=> this.visitsState.setRequestBuilderState(v))
        rb.addStateCallback((v)=>{
          if(v.success){
            this.visitsState.modalData = v.items;
            this.visitsState.modalData.forEach(v1=>{
              v1.visitServices.forEach(v2=>{
                v2._financeOperation = arr.filter(v3=>parseInt(v3.value)===v2.id).length>0;
              });
            });
          }
        })
        .request();
      }

    }
  }
}
</script>