<template>
  <div>
    <autocomplete-multiple :name="name" :search-text.sync="searchText" :items="items" :select.sync="localSelect" :loading="api.loading"  v-bind="typeof selectAllEnabled !== 'undefined' ? { selectAllEnabled } : {}">
      <template v-slot:itemInfo="{item}">
        <v-row no-gutters class="caption">
          <v-col cols="auto">{{item.userProfile.gender==='male'?'Мужчина':'Женщина'}}</v-col>
          <v-col v-if="!$tools.isEmpty(item.userProfile.birth)" cols="auto"><dot/></v-col>
          <v-col v-if="!$tools.isEmpty(item.userProfile.birth)" cols="auto">{{$tools.years(item.userProfile.birth)}}</v-col>
          <v-col cols="auto"><dot/></v-col>
          <v-col cols="auto">{{item.type==='admin' ? 'Администратор' : item.type==='spec' ? 'Специалист' : 'Клиент'}}</v-col>
        </v-row>
      </template>
    </autocomplete-multiple>
  </div>

</template>

<script>
import AutocompleteMultiple from "@/componentsV2/base/AutocompleteMultiple.vue";
import GraphqlApi from "@/plugins/graphqlApi";
import Dot from "@/componentsV2/base/Dot.vue";
export default {
  components: {AutocompleteMultiple,Dot},
  props:[
    'name',
    'select',
    '_type',
    'selectAllEnabled'
  ],
  data:()=>({
    api: new GraphqlApi(),
    searchText: null,
    data: {count:0, items:[]},
    items:[],
    localSelect: null
  }),
  watch: {
    select() {
      this.localSelect = this.select;
      if(typeof this.localSelect!=='undefined') {
        this.localSelect.forEach(v=>{
          let found = false;
          this.items.forEach(v1=>{
            if(v.id===v1.id)
              found = true;
          })
          if(!found){
            v.name = v.lastName+' '+v.firstName+' '+v.secondName
            this.items.push(v)
          }
        })
      }
    },
    localSelect() {
      this.$emit('update:select', this.localSelect)
      //if data are received after mount
      if(typeof this.localSelect!=='undefined' && this.localSelect.length>0 && this.items.length===0) {
        this.localSelect.forEach(v=>{
          v.name = v.lastName+' '+v.firstName+' '+v.secondName
          this.items.push(v)
        });
      }
    },
    searchText(){
      let arr1 = this.searchText.split(' ');
      let whereQL = '';
      let whereQLParams = '';
      if(typeof this._type!=='undefined'){
        whereQL = 'user.type=?1 AND ';
        whereQLParams += this._type+',';
      }
      
      if (/^\d+$/.test(arr1)&&arr1.length>0) {//only id
          whereQL += 'user.id = ?'+(typeof this._type==='undefined' ? '1' : '2');
          whereQLParams += arr1[0];
      } else {
        if(arr1.length>0){
          whereQL += 'user.lastName LIKE ?'+(typeof this._type==='undefined' ? '1' : '2');
          whereQLParams += '%'+arr1[0]+'%';
        }
        if(arr1.length>1){
          whereQL += ' AND user.firstName LIKE ?'+(typeof this._type==='undefined' ? '2' : '3');
          whereQLParams += ',%'+arr1[1]+'%';
        }
        if(arr1.length>2){
          whereQL += ' AND user.secondName LIKE ?'+(typeof this._type==='undefined' ? '3' : '4');
          whereQLParams += ',%'+arr1[2]+'%';
        }
      }

      this.api.requestSingleModelMultipleDataV2(this.data, '/api/graphqlv2/', `
        query Query($whereQLParams: String) {
          users(
            whereQL: "`+whereQL+`"
            whereQLParams: $whereQLParams
            orderQL: "user.lastName"
          ) {
            count
            items {
              id
              firstName
              lastName
              secondName
              type
              userProfile{
                gender
                birth
              }
            }
          }
        }
    `,{whereQLParams:whereQLParams}, null, (v)=>{
        v.name = v.lastName+' '+v.firstName+' '+v.secondName;
        return v;
      });
    },
    'data.items'(){
      this.items = this.items.concat(this.data.items);

      //sort and remove dublicates
      this.items.sort(function(a, b) {
        var lastNameA = a.lastName.toUpperCase();
        var lastNameB = b.lastName.toUpperCase();
        if (lastNameA < lastNameB) {
          return -1;
        }
        if (lastNameA > lastNameB) {
          return 1;
        }
        return 0;
      });

      this.items = this.items.filter((obj, index, self) =>
        index === self.findIndex((o) =>
          o.id === obj.id && o.name === obj.name
        )
      );
    }
  },
  mounted() {
    this.localSelect = this.select;
    if(typeof this.localSelect!=='undefined' && this.localSelect.length>0) {
      let arr = [];
      this.localSelect.forEach(v=>{
        v.name = v.lastName+' '+v.firstName+' '+v.secondName
        arr.push(v)
      });
      this.data = {
        count: arr.length,
        items: arr
      }
    }
  }
}
</script>